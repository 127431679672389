import React, { Component } from "react";
import { azureCostDataServices, azureChartServices } from "../../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AzureCostReport from "./azureCostReport";
import ls from "local-storage";

class AzureTableMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isLoading: true,
      token: ls.get("idToken"),
      entities: !ls.get("orgAzure") ? ls.get("entity") : ls.get("orgAzure")
    };
    this.props.actions.azureData.findAzureCostTableData(
      this.state.token,
      this.state.entities
    );
    this.props.actions.azureChart.findAzureChartData(
      this.state.token,
      this.state.entities
    );
  }
  render() {
    return (
      <div>
        <AzureCostReport
          azureData={this.props.azureData}
          azureChart={this.props.azureChart}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    azureData: state.azureData.azureTableData,
    azureChart: state.azureChart.azureChartData
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      azureData: bindActionCreators(azureCostDataServices, dispatch),
      azureChart: bindActionCreators(azureChartServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AzureTableMap);
