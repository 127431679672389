import * as React from "react";
import { bindActionCreators } from "redux";
import { authUsersServices, userProfileServices } from "../../services";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../components/Card/CardBody.jsx";
import UsersTable from "./BootStrapTable";
import ls from "local-storage";
const styling = {
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "40px",
  marginBottom: "40px",
  textAlign: "center"
};
class UserManagementTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      isLoading: true,
      identity: ""
    };
    this.props.authUsersApi.findAuthUsers(ls.get("idToken"));
  }
  /*componentWillReceiveProps(nextProps) {
    console.log("nextState", nextProps);
    /*if (!nextProps.authUsers) {
      this.setState({ isLoading: true });
    } else {
      this.setState({ isLoading: false });
    if (nextProps.userProfile.userProfile) {
      console.log(
        "nextStatecustId",
        nextProps.userProfile.userProfile.identityProvider
      );
      this.setState({
        identity: nextProps.userProfile.userProfile.identityProvider
      });
    }
    //}
  }*/

  componentWillReceiveProps(nextProps) {
    console.log("nextStatenew", nextProps);
    if (nextProps.authUsers && nextProps.entities) {
      this.setState({ isLoading: false });
    }
    if (nextProps.userProfile.userProfile) {
      console.log(
        "nextStatecustId",
        nextProps.userProfile.userProfile.identityProvider
      );
      this.setState({
        identity: nextProps.userProfile.userProfile.identityProvider
      });
    }
  }

  render() {
    const { classes } = this.state;
    return (
      <GridContainer>
        <GridItem xs={0.5} sm={0.5} md={0.5} />
        <GridItem xs={11} sm={11} md={11}>
          <Card>
            <CardHeader title="User Access Management" />
            <CardBody>
              {this.state.isLoading === true && (
                <div style={styling}>
                  <CircularProgress
                    className={classes.progress}
                    color="secondary"
                  />
                </div>
              )}
              {this.state.isLoading === false && (
                <UsersTable
                  authUsers={this.props.authUsers}
                  identityProvider={this.state.identity}
                  entities={this.props.entities}
                />
              )}
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={0.5} sm={0.5} md={0.5} />
      </GridContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    id: state.id_token,
    authUsers: state.authUsers.authUsers,
    userProfile: state.userProfile,
    entities: state.entities.entityList
  };
}
function matchDispatchToProps(dispatch) {
  return {
    authUsersApi: bindActionCreators(authUsersServices, dispatch),
    userProfileService: bindActionCreators(userProfileServices, dispatch)
  };
}

export default connect(
  mapStateToProps,
  matchDispatchToProps
)(UserManagementTable);
