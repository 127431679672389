import { createAction } from "redux-actions";
import { ActionTypes } from "../constants";

export const listUnassignedRequest = createAction(
  ActionTypes.LIST_UNASSIGNED_ACCOUNTS_REQUEST
);
export const listUnassignedResponse = createAction(
  ActionTypes.LIST_UNASSIGNED_ACCOUNTS_RESPONSE
);
export const listUnassignedFailure = createAction(
  ActionTypes.LIST_UNASSIGNED_ACCOUNTS_FAILURE
);
export const getAssignedCloudAccountsRequest = createAction(
  ActionTypes.GET_ENTITY_CLOUD_ACCOUNTS_REQUEST
);
export const getAssignedCloudAccountsResponse = createAction(
  ActionTypes.GET_ENTITY_CLOUD_ACCOUNTS_RESPONSE
);
export const getAssignedCloudAccountsFailure = createAction(
  ActionTypes.GET_ENTITY_CLOUD_ACCOUNTS_FAILURE
);
export const updateUnassignedAccountsRequest = createAction(
  ActionTypes.UPDATE_UNASSIGNED_ACCOUNTS_REQUEST
);
export const updateUnassignedAccountsResponse = createAction(
  ActionTypes.UPDATE_UNASSIGNED_ACCOUNTS_RESPONSE
);
export const updateUnassignedAccountsFailure = createAction(
  ActionTypes.UPDATE_UNASSIGNED_ACCOUNTS_FAILURE
);
export const updateAssignedAccountsRequest = createAction(
  ActionTypes.UPDATE_ASSIGNED_ACCOUNTS_REQUEST
);
export const updateAssignedAccountsResponse = createAction(
  ActionTypes.UPDATE_ASSIGNED_ACCOUNTS_RESPONSE
);
export const updateAssignedAccountsFailure = createAction(
  ActionTypes.UPDATE_ASSIGNED_ACCOUNTS_FAILURE
);
export default {};
