import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import { GcpOnboardServices } from "../../../services";
//import { Button } from "reactstrap";
import { withRouter } from "react-router";
import { Steps, Button, message } from "antd";
import { bindActionCreators } from "redux";
import "antd/dist/antd.css";
import FirstPage from "./firstPage";
import SecondPage from "./secondPage";
import ThirdPage from "./thirdPage";
import FourthPage from "./fourthPage";
import { connect } from "react-redux";
import GcpProjectList from "./gcpProjectList";
import ls from "local-storage";
const { Step } = Steps;

const steps = [
  {
    title: "First",
    content: <FirstPage />
  },
  {
    title: "Second",
    content: <SecondPage />
  },
  {
    title: "Third",
    content: <ThirdPage />
  },
  {
    title: "Last",
    content: <FourthPage />
  }
];
class OnboardingGcpLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      current: 0,
      showProject: false,
      userid: ls.get("customerId"),
      token: ls.get("idToken")
    };
    this.navigate = this.navigate.bind(this);
  }
  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
    this.setState({ showProject: false });
  }
  navigate() {
    console.log("this", this);
    message.success("Processing complete!");
    //this.props.history.push("/gcp/settings/onboarding/projectList");
    this.props.actions.orgList.findGcpOnboardListOrgData(
      this.state.token,
      this.state.userid
    );
    this.setState({ showProject: true });
  }
  render() {
    const { classes, current } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
          <GridItem xs={11} sm={11} md={11}>
            <Card>
              {/*<CardHeader title="OnBoarding GCP" />
              <CardBody>
                <h4>Welcome to Google Cloud Platform. </h4>

                <p>
                  Please Click the "View Projects" to view list of all the
                  Projects and its information on Vodafone.com
                </p>
                <div>
                  <Button color="primary" onClick={() => this.navigate()}>
                    View Projects
                  </Button>
                </div>
    </CardBody>*/}
              <CardHeader title="OnBoarding GCP" />
              <CardBody>
                <Steps current={current}>
                  {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
                <div className="steps-action">
                  {current < steps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={() => this.next()}
                      //disabled={abc}
                    >
                      Next
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button type="primary" onClick={() => this.navigate()}>
                      Done
                    </Button>
                  )}
                  {current > 0 && (
                    <Button
                      style={{ marginLeft: 8 }}
                      onClick={() => this.prev()}
                    >
                      Previous
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={0.5} sm={0.5} md={0.5} />
        </GridContainer>
        {this.state.showProject && <GcpProjectList />}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    orgList: state.gcpOnboard.gcpOnboardOrgData
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      orgList: bindActionCreators(GcpOnboardServices, dispatch)
    }
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(OnboardingGcpLanding)
);

//export default withRouter(OnboardingGcpLanding);
