import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import GcpProjectTable from "./gcpnewProjectTable";
import { NewProjectServices } from "../../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  progress: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "40px",
    marginBottom: "40px"
  }
};
const styling = {
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "40px",
  marginBottom: "40px",
  textAlign: "center"
};
const pro = [
  {
    createTime: "2019-10-16T09:52:45.644Z",
    labels: {
      bu: "group-tdo",
      budget_owner: "barry_dewey-vodafone_com",
      business_service: "vgsl-gcp-evo-prd",
      confidentiality: "c3",
      cost_centre: "gb05004074",
      environment: "prd",
      local_market: "vgsl",
      managed_by: "gdcpubliccloudmgmt-vodafone_com",
      po_number_cht: "3003018355",
      po_number_gcp: "3003015767",
      programme: "evo",
      project_owner: "martin_wortmann-vodafone_com",
      project_suffix: "evoamt-01",
      sec_assessment: "9149",
      technical_owner: "simon_martyr-vodafone_com",
      vams_id: "co-q-0164005",
      wbs: "i_83002424_002"
    },
    lifecycleState: "ACTIVE",
    name: "vf-grp-evo-prd-evoamt-01",
    parent: {
      id: "382137713383",
      type: "folder"
    },
    projectId: "vf-grp-evo-prd-evoamt-01",
    projectNumber: "628199282466"
  },
  {
    createTime: "2019-10-16T08:55:00.568Z",
    labels: {
      bu: "local-market",
      budget_owner: "maikel_mais-vodafone_com",
      business_service: "vf-pt-gcp-ngbi-pprd",
      confidentiality: "c3",
      cost_centre: "gb05004075",
      environment: "pprd",
      local_market: "vf-pt",
      managed_by: "gdcpubliccloudmgmt-vodafone_com",
      po_number_cht: "3003018355",
      po_number_gcp: "3003015767",
      programme: "ngbi",
      project_owner: "vincenzo_maffuci-vodafone_com",
      project_suffix: "gen-01",
      sec_assessment: "8127",
      technical_owner: "mihaela_savastre1-vodafone_com",
      vams_id: "co-q-0164001",
      wbs: "i_83153746_001"
    },
    lifecycleState: "ACTIVE",
    name: "vf-pt-ngbi-pprd-gen-01",
    parent: {
      id: "163145029621",
      type: "folder"
    },
    projectId: "vf-pt-ngbi-pprd-gen-01",
    projectNumber: "663498405603"
  },
  {
    createTime: "2019-10-16T08:45:23.482Z",
    labels: {
      bu: "local-market",
      budget_owner: "maikel_mais-vodafone_com",
      business_service: "vf-pt-gcp-ngbi-tst",
      confidentiality: "c2",
      cost_centre: "gb05004075",
      environment: "tst",
      local_market: "vf-pt",
      managed_by: "gdcpubliccloudmgmt-vodafone_com",
      po_number_cht: "3003018355",
      po_number_gcp: "3003015767",
      programme: "ngbi",
      project_owner: "vincenzo_maffuci-vodafone_com",
      project_suffix: "gen-01",
      sec_assessment: "8127",
      technical_owner: "mihaela_savastre1-vodafone_com",
      vams_id: "co-q-0164001",
      wbs: "i_83153746_001"
    },
    lifecycleState: "ACTIVE",
    name: "vf-pt-ngbi-tst-gen-01",
    parent: {
      id: "163145029621",
      type: "folder"
    },
    projectId: "vf-pt-ngbi-tst-gen-01",
    projectNumber: "284087557458"
  },
  {
    createTime: "2019-10-15T07:34:13.598Z",
    labels: {
      bu: "local-market",
      budget_owner: "maikel_mais-vodafone_com",
      business_service: "vf-pt-gcp-ngbi-tst",
      confidentiality: "c2",
      cost_centre: "gb05004075",
      environment: "tst",
      local_market: "vf-pt",
      managed_by: "gdcpubliccloudmgmt-vodafone_com",
      po_number_cht: "3003018355",
      po_number_gcp: "3003015767",
      programme: "ngbi",
      project_owner: "vincenzo_maffuci-vodafone_com",
      project_suffix: "gen-04",
      sec_assessment: "8127",
      technical_owner: "mihaela_savastre1-vodafone_com",
      vams_id: "co-q-0164001",
      wbs: "i_83153746_001"
    },
    lifecycleState: "ACTIVE",
    name: "vf-pt-ngbi-tst-gen-04",
    parent: {
      id: "163145029621",
      type: "folder"
    },
    projectId: "vf-pt-ngbi-tst-gen-04",
    projectNumber: "793677179995"
  }
];

class ProjectManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      isLoading: true,
      classes: props
    };
    this.props.actions.gcpNewProjects.findNewGcpProjects();
  }
  componentDidMount() {
    this.setState({ projects: pro });
    this.setState({ isLoading: false });
  }
  componentWillReceiveProps(nextProps) {
    console.log("projects-props", nextProps);
    if (!nextProps.gcpNewProjectsData) {
      this.setState({ projects: pro });
      this.setState({ isLoading: false });
    } else if (!nextProps.gcpNewProjectsData) {
      this.setState({ projects: nextProps.gcpNewProjectsData });
      this.setState({ isLoading: false });
    }
  }
  render() {
    console.log("jdshfjdhfjdhfdjhfjd", this.state.projects);
    const { projects, classes, isLoading } = this.state;
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
          <GridItem xs={11} sm={11} md={11}>
            <Card>
              <CardHeader title="New Project list" />
              <CardBody>
                {isLoading === true && (
                  <div style={styling}>
                    <CircularProgress
                      className={classes.progress}
                      color="secondary"
                    />
                  </div>
                )}
                {isLoading === false && <GcpProjectTable projects={projects} />}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
        </GridContainer>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    gcpNewProjectsData: state.newProject.newProjectsGcp
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gcpNewProjects: bindActionCreators(NewProjectServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectManagement);
