import { RSAA } from "redux-api-middleware";
import { azureCostManagementTable } from "../constants/api-endpoints";
import { ActionTypes } from "../constants";

export const findAzureCostTableData = (token, entities) => ({
  [RSAA]: {
    endpoint: `${azureCostManagementTable}`,
    method: "GET",
    headers: {
      Authorization: `${token}`,
      entities: `${JSON.stringify(entities.split())}`
    },
    types: [
      ActionTypes.AZURE_TABLE_FIND_REQUEST,
      ActionTypes.AZURE_TABLE_FIND_RESPONSE,
      ActionTypes.AZURE_TABLE_FIND_FAILURE
    ]
  }
});

/*export function findAzureCostTableData() {
  return function(dispatch) {
    dispatch({
      type: ActionTypes.AZURE_TABLE_FIND_REQUEST
    });

    fetch(`${azureCostManagementTable}`, {
      method: "GET"
    })
      .then(response => response.json())
      .then(data =>
        dispatch({
          type: ActionTypes.AZURE_TABLE_FIND_RESPONSE,
          payload: data
        })
      )
      .catch(error =>
        dispatch({
          type: ActionTypes.AZURE_TABLE_FIND_FAILURE,
          payload: error
        })
      );
  };
}
/*fetch(
  `https://multicloudadmincostsappservicedev.azurewebsites.net/api/Usage?code=HJSY8vOlwhfjYZKjxy5/C9t71eInobFtGLkHMvpw0kG9fbSXjQMBFA==&tenantId=137ff3cd-10ca-4e0f-9ae8-ecca51ac6cc0&clientId=4f8f0b23-132a-4572-84c5-2afc9779d48b&subscriptionId=85a0fc2c-a55e-48a7-8619-e9f4121547f8&table=true`,
  {
    method: "GET"
  }
)
  .then(response => response.json())
  .then(data =>
    this.setState({
      users: data,
      isLoading: false
    })
  )
  .catch(error => this.setState({ error }));*/
