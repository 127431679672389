import { createAction } from "redux-actions";
import { ActionTypes } from "../constants";

export const getNewGcpProjectsRequest = createAction(
  ActionTypes.GET_NEW_GCP_PROJECTS_REQUEST
);
export const getNewGcpProjectsResponse = createAction(
  ActionTypes.GET_NEW_GCP_PROJECTS_RESPONSE
);
export const getNewGcpProjectsFailure = createAction(
  ActionTypes.GET_NEW_GCP_PROJECTS_FAILURE
);
export default {};
