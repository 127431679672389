import { RSAA } from "redux-api-middleware";
import { authProfileEndpoint } from "../constants/api-endpoints";
import { ActionTypes } from "../constants";

export const findUserProfile = userToken => ({
  [RSAA]: {
    endpoint: `${authProfileEndpoint}`,
    method: "GET",
    headers: {
      Authorization: `${userToken}`
    },
    types: [
      ActionTypes.USER_PROFILE_FIND_REQUEST,
      ActionTypes.USER_PROFILE_FIND_RESPONSE,
      ActionTypes.USER_PROFILE_FIND_FAILURE
    ]
  }
});

/*export function findUserProfile(token) {
  return function(dispatch) {
    dispatch({
      type: ActionTypes.USER_PROFILE_FIND_REQUEST
    });

    fetch(`${authProfileEndpoint}`, {
      method: "GET",
      headers: {
        Authorization: `${token}`
      }
    })
      .then(response => response.json())
      .then(data =>
        dispatch({
          type: ActionTypes.USER_PROFILE_FIND_RESPONSE,
          payload: data
        })
      )
      .catch(error =>
        dispatch({
          type: ActionTypes.USER_PROFILE_FIND_FAILURE,
          payload: error
        })
      );
  };
}*/

export default { findUserProfile };
