import React, { Component } from "react";
import Chart from "react-apexcharts";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../components/Card/CardBody.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";

const styling = {
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "40px",
  marginBottom: "40px",
  textAlign: "center"
};
class GCPChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      isLoading: true,
      options: {
        chart: {
          stacked: true
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },

        title: {
          text: ""
        },
        xaxis: {
          categories: ["April", "March"]
        },
        yaxis: {
          labels: {
            formatter: function(val) {
              return val;
            }
          },
          title: {
            text: undefined
          }
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            }
          }
        },
        fill: {
          opacity: 1
        },

        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      },
      series: []
    };
  }
  componentWillReceiveProps(nextProps) {
    this.dataGrab(nextProps);
    if (nextProps.gcpChartData) {
      this.setState({ isLoading: false });
    }
  }
  dataGrab(aaa) {
    if (aaa.gcpChartData) {
      const filterteste = aaa.gcpChartData.map(item => ({
        name: item.name,
        data: item.data.map(a => a.Cost)
      }));
      this.setState({ series: filterteste });
      const bbb = aaa.gcpChartData[0].data.map(a => a.Month);
      this.setState({
        options: {
          ...this.state.options,
          xaxis: { categories: bbb }
        }
      });
      console.log("filterteste", filterteste);
      console.log("bbb", bbb);
    }

    /*if (aaa.gcpChartData) {
      this.setState({
        options: {
          ...this.state.options,
          xaxis: { categories: aaa.chartData[aaa.chartData.length - 1].Dates }
        }
      });
      const filterdata = aaa.chartData.slice(0, -1);
      const filterteste = filterdata.map(item => ({
        name: item.name,
        data: item.data.map(a => a.Cost)
      }));
      this.setState({ series: filterteste });
    }*/
  }
  render() {
    const { classes, isLoading } = this.state;
    return (
      <div id="chart">
        <Card>
          <CardHeader title="Cost Management Data Chart" />
          <CardBody>
            {isLoading === true && (
              <div style={styling}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              </div>
            )}
            {isLoading === false && (
              <Chart
                options={this.state.options}
                series={this.state.series}
                type="bar"
                height="350"
              />
            )}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default GCPChart;
