import React, { Component } from "react";
import { Table } from "reactstrap";

class GcpProjectTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: props.projects
    };
  }
  render() {
    console.log("jdshfjdhfjdhfdjhfjd", this.props);
    const { projects } = this.state;
    return (
      <Table size="sm" hover>
        <thead className="bg-light">
          <tr>
            <th>ProjectNumber</th>
            <th>ProjectId</th>
            <th>Project Name</th>
            <th>Budget Owner</th>
            <th>Project Owner</th>
            <th>CreationTime</th>
            <th>LifeCycleState</th>
          </tr>
        </thead>
        <tbody>
          {this.props.projects ? (
            this.props.projects.map(pro => (
              <tr key={pro.projectNumber}>
                <th scope="row">{pro.projectNumber}</th>
                <td>{pro.projectId}</td>
                <td>{pro.name}</td>
                <td>{pro.labels.budget_owner}</td>
                <td>{pro.labels.project_owner}</td>
                <td>{pro.createTime}</td>
                <td>{pro.lifecycleState}</td>
              </tr>
            ))
          ) : (
            <p className="text-center">No new projects found</p>
          )}
        </tbody>
      </Table>
    );
  }
}

export default GcpProjectTable;
