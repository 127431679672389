import { RSAA } from "redux-api-middleware";
import { awsOnboarding } from "../constants/api-endpoints";
import { ActionTypes } from "../constants";

export const findAwsOnBoard = (token, custId, accountId) => ({
  [RSAA]: {
    endpoint: `${awsOnboarding}?accountId=${accountId}`,
    method: "GET",
    headers: {
      Authorization: `${token}`,
      customer: `${custId}`
    },
    types: [
      ActionTypes.AWS_ONBOARDING_REQUEST,
      ActionTypes.AWS_ONBOARDING_RESPONSE,
      ActionTypes.AWS_ONBOARDING_FAILURE
    ]
  }
});

export default { findAwsOnBoard };
