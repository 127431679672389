import { createAction } from "redux-actions";
import { ActionTypes } from "../constants";

export const gcpOnboardListProjectsRequest = createAction(
  ActionTypes.GCP_ONBOARD_LIST_PROJECTS_REQUEST
);
export const gcpOnboardListProjectsResponse = createAction(
  ActionTypes.GCP_ONBOARD_LIST_PROJECTS_RESPONSE
);
export const gcpOnboardListProjectsFailure = createAction(
  ActionTypes.GCP_ONBOARD_LIST_PROJECTS_FAILURE
);
export const gcpOnboardListProRequest = createAction(
  ActionTypes.GCP_ONBOARD_LIST_PRO_REQUEST
);
export const gcpOnboardListProResponse = createAction(
  ActionTypes.GCP_ONBOARD_LIST_PRO_RESPONSE
);
export const gcpOnboardListProFailure = createAction(
  ActionTypes.GCP_ONBOARD_LIST_PRO_FAILURE
);
export const gcpOnboardListOrgRequest = createAction(
  ActionTypes.GCP_ONBOARD_LIST_ORG_REQUEST
);
export const gcpOnboardListOrgResponse = createAction(
  ActionTypes.GCP_ONBOARD_LIST_ORG_RESPONSE
);
export const gcpOnboardListOrgFailure = createAction(
  ActionTypes.GCP_ONBOARD_LIST_ORG_FAILURE
);
export const gcpOnboardAssignEntityRequest = createAction(
  ActionTypes.GCP_ONBOARD_UPDATE_ENTITY_REQUEST
);
export const gcpOnboardAssignEntityResponse = createAction(
  ActionTypes.GCP_ONBOARD_UPDATE_ENTITY_RESPONSE
);
export const gcpOnboardAssignEntityFailure = createAction(
  ActionTypes.GCP_ONBOARD_UPDATE_ENTITY_FAILURE
);
export default {};
