import { RSAA } from "redux-api-middleware";
import { GCPChartEndpoint, GCPTableEndpoint } from "../constants/api-endpoints";
import { ActionTypes } from "../constants";

var date = new Date();
var firstDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  1
).toLocaleDateString("en-GB");
var lastDay = new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  0
).toLocaleDateString("en-GB");
export const findGcpChartData = (token, custId, entity) => ({
  [RSAA]: {
    endpoint: `${GCPChartEndpoint}&dateFrom=${firstDay}&dateTo=${lastDay}`,
    method: "GET",
    headers: {
      Authorization: `${token}`,
      customer: `${custId}`,
      entities: entity
    },
    types: [
      ActionTypes.GCP_CHART_REQUEST,
      ActionTypes.GCP_CHART_RESPONSE,
      ActionTypes.GCP_CHART_FAILURE
    ]
  }
});
export const findGcpTableData = (token, custId, entity) => ({
  [RSAA]: {
    endpoint: `${GCPTableEndpoint}&dateFrom=${firstDay}&dateTo=${lastDay}`,
    method: "GET",
    headers: {
      Authorization: `${token}`,
      customer: `${custId}`,
      entities: entity
    },
    types: [
      ActionTypes.GCP_TABLE_REQUEST,
      ActionTypes.GCP_TABLE_RESPONSE,
      ActionTypes.GCP_TABLE_FAILURE
    ]
  }
});
