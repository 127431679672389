import { RSAA } from "redux-api-middleware";
import { BudgetEndpoint } from "../constants/api-endpoints";
import { ActionTypes } from "../constants";

export const findAwsBudget = (token, custId) => ({
  [RSAA]: {
    endpoint: `${BudgetEndpoint}/get-budgets`,
    method: "GET",
    headers: {
      Authorization: `${token}`,
      customer: `${custId}`
    },
    types: [
      ActionTypes.BUDGET_GET_REQUEST,
      ActionTypes.BUDGET_GET_RESPONSE,
      ActionTypes.BUDGET_GET_FAILURE
    ]
  }
});
export const findAwsAddBudget = (url, token, custId) => ({
  [RSAA]: {
    endpoint: `${BudgetEndpoint}/add-budget`,
    method: "PUT",
    headers: {
      Authorization: `${token}`,
      customer: `${custId}`
    },
    body: `${JSON.stringify(url)}`,
    types: [
      ActionTypes.BUDGET_ADD_REQUEST,
      ActionTypes.BUDGET_ADD_RESPONSE,
      ActionTypes.BUDGET_ADD_FAILURE
    ]
  }
});
export const findAwsAddBudgetGCPSample = (url, token, custId) => ({
  [RSAA]: {
    endpoint: `${BudgetEndpoint}/add-budget`,
    method: "PUT",
    headers: {
      Authorization: `${token}`,
      customer: `${custId}`
    },
    body: url,
    types: [
      ActionTypes.BUDGET_ADD_REQUEST,
      ActionTypes.BUDGET_ADD_RESPONSE,
      ActionTypes.BUDGET_ADD_FAILURE
    ]
  }
});
export const findAwsDeleteBudget = (url, token, custId) => ({
  [RSAA]: {
    endpoint: `${BudgetEndpoint}/delete_budget${url}`,
    method: "GET",
    headers: {
      Authorization: `${token}`,
      customer: `${custId}`
    },
    types: [
      ActionTypes.BUDGET_DELETE_REQUEST,
      ActionTypes.BUDGET_DELETE_RESPONSE,
      ActionTypes.BUDGET_DELETE_FAILURE
    ]
  }
});
export const findAwsUpdateBudget = (url, token, custId) => ({
  [RSAA]: {
    endpoint: `${BudgetEndpoint}/update-budget`,
    method: "PUT",
    headers: {
      Authorization: `${token}`,
      customer: `${custId}`
    },
    body: `${JSON.stringify(url)}`,
    types: [
      ActionTypes.BUDGET_UPDATE_REQUEST,
      ActionTypes.BUDGET_UPDATE_RESPONSE,
      ActionTypes.BUDGET_UPDATE_FAILURE
    ]
  }
});
export const findAwsfilterBudget = (status, token, custId) => ({
  [RSAA]: {
    endpoint: `${BudgetEndpoint}/get-budgets-by-status?status=${status}`,
    method: "GET",
    headers: {
      Authorization: `${token}`,
      customer: `${custId}`
    },
    types: [
      ActionTypes.BUDGET_FILTER_REQUEST,
      ActionTypes.BUDGET_FILTER_RESPONSE,
      ActionTypes.BUDGET_FILTER_FAILURE
    ]
  }
});
