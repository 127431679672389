import React, { Component } from "react";
import {
  costDataServices,
  awsChartServices,
  entityServices
} from "../../services";
import { CostTableActions } from "../../actions";
//import CostChart from "./CostChart.jsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CostDraft from "./CostDraft";
import ls from "local-storage";

class TableMap extends Component {
  constructor(props) {
    console.log("propsdddd", props);
    super(props);
    this.state = {
      users: [],
      isLoading: true,
      userid: ls.get("customerId"),
      costToken: ls.get("idToken"),
      entity: !ls.get("org")
        ? `[${JSON.stringify(ls.get("InitEntityId"))}]`
        : `[${JSON.stringify(ls.get("org"))}]`
    };
    this.props.actions.costData.findCostTableData(
      this.state.costToken,
      this.state.userid,
      this.state.entity
    );
    this.props.actions.awsChart.findAwsChartData(
      this.state.costToken,
      this.state.userid,
      this.state.entity
    );
  }

  render() {
    return (
      <CostDraft
        userData={this.props.costData}
        awsChart={this.props.awsChart}
        entities={this.props.entities}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    entities: state.entities.entityList,
    costData: state.costData.costTableData,
    awsChart: state.awsChart.awsChartData
  }; // state
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      entities: bindActionCreators(entityServices, dispatch),
      CostTableActions: bindActionCreators(CostTableActions, dispatch),
      costData: bindActionCreators(costDataServices, dispatch),
      awsChart: bindActionCreators(awsChartServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableMap);
