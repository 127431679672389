import React, { Component } from "react";
import { Button, Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { AwsOnboardServices } from "../../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Icon from "@material-ui/core/Icon";
import ls from "local-storage";
const buttonStyle = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: "16px"
  },
  color: "#dc3545"
};

const paddingButton = {
  padding: "0"
};

class SecondPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountId: [{ value: null }],
      userid: ls.get("customerId"),
      token: ls.get("idToken")
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  createUI() {
    return this.state.accountId.map((el, i) => (
      <div key={i}>
        <input
          type="text"
          value={el.value || ""}
          onChange={this.handleChange.bind(this, i)}
        />
        <input
          type="button"
          value="remove"
          onClick={this.removeClick.bind(this, i)}
        />
      </div>
    ));
  }
  handleChange(i, event) {
    let values = [...this.state.accountId];
    values[i].value = event.target.value;
    this.setState({ accountId: values });
  }

  addClick() {
    this.setState(prevState => ({
      accountId: [...prevState.accountId, { value: null }]
    }));
  }

  removeClick(i) {
    let values = [...this.state.accountId];
    values.splice(i, 1);
    this.setState({ accountId: values });
  }

  handleSubmit(event) {
    /*alert(
      "A name was submitted: " +
        this.state.accountId.map(a => a.value).join(", ")
    );*/
    var accountids = this.state.accountId.map(a => a.value).join(",");
    this.props.actions.awsOnboard.findAwsOnBoard(
      this.state.token,
      this.state.userid,
      accountids
    );
    event.preventDefault();
  }
  render() {
    return (
      <div>
        <p>
          This section will give you access to onboarding templates and permit
          your accounts to be onboarded.
        </p>
        <p>
          Input the account ids of the main accounts that you want to onboard.
          To onboard multiple accounts, press the{" "}
          <strong className="text-danger">"Add Account Id"</strong> button.
        </p>
        <ul>
          <li>
            To onboard an Organization, please enter the AWS Account ID for the
            master account.
          </li>
          <li>
            To onboard a single account, please enter the AWS Account ID of the
            account.
          </li>
        </ul>
        <p>
          Once you have entered all of your AWS Account IDs, press the{" "}
          <strong className="text-danger">"Save" </strong>
          button.
        </p>
        <form onSubmit={this.handleSubmit}>
          <div>
            <Row className="mb-2">
              <Col xs="6">
                <label>Enter Account Id</label>
                <Button
                  color="success"
                  onClick={() => this.addClick()}
                  size="sm"
                  className="w-20 float-right"
                >
                  Add Account Id
                </Button>
              </Col>
            </Row>
            {this.state.accountId &&
              this.state.accountId.map((tres, index) => {
                return (
                  <div className="form-group" key={index}>
                    <Row>
                      <Col xs="6">
                        <input
                          type="text"
                          className="form-control"
                          value={tres.value || ""}
                          onChange={e => this.handleChange(index, e)}
                        />
                      </Col>
                      <Col xs="6">
                        <Button
                          color="link"
                          style={paddingButton}
                          onClick={() => this.removeClick(index)}
                        >
                          <Icon style={buttonStyle}>clear</Icon>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            <Button color="primary" className="w-40" size="sm">
              Save
            </Button>{" "}
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    awsOnboard: state.awsOnboard.awsOnboardAccess
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      awsOnboard: bindActionCreators(AwsOnboardServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecondPage);
