// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
//import Person from "@material-ui/icons/Person";
//import Assessment from "@material-ui/icons/Assessment";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
//import BubbleChart from "@material-ui/icons/BubbleChart";
//import LocationOn from "@material-ui/icons/LocationOn";
//import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
//import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "./containers/Dashboard/Dashboard.jsx";
//import UserProfile from "./containers/UserProfile/UserProfile.jsx";
import TableList from "./containers/TableList/TableList.jsx";
//import Typography from "./containers/Typography/Typography.jsx";
//import Icons from "./containers/Icons/Icons.jsx";
//import Maps from "./containers/Maps/Maps.jsx";
//import NotificationsPage from "views/Notifications/Notifications.jsx";
import UpgradeToPro from "./containers/UpgradeToPro/UpgradeToPro.jsx";
//import CostDraft from "./containers/TableData/CostDraft.jsx";
//import UserManagementTable from "./containers/UserManagement/UserManagementTable.jsx";
// core components/views for RTL layout
//import RTLPage from "views/RTLPage/RTLPage.jsx";

const convergedRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/converged"
  },
  {
    path: "/securityCompilance",
    name: "Security & Compilance",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: TableList,
    layout: "/converged"
  }
  /* {
    path: "/admin",
    name: "Admin",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/converged"
  }*/
];

export default convergedRoutes;
