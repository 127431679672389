import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Table from "../../../components/Table/Table.jsx";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import AzureChart from "./azureChart";
import CircularProgress from "@material-ui/core/CircularProgress";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  progress: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "40px",
    marginBottom: "40px"
  }
};
const styling = {
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "40px",
  marginBottom: "40px",
  textAlign: "center"
};
/*const spacing = {
  width: "50%",
  marginLeft: "auto",
  marginRight: "auto"
};*/
const getLocaleNumber = currency =>
  currency.toLocaleString({
    minimumFractionDigits: 2
  });
const dateFunction = date => new Date(date).toLocaleDateString();

class AzureCostReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isLoading: true,
      errors: null,
      classes: props
    };
  }
  componentWillReceiveProps(nextProps) {
    console.log("njnjn", nextProps);
    if (nextProps.azureData) {
      this.setState({ users: nextProps.azureData });
      this.setState({ isLoading: false });
    }
  }
  render() {
    console.log("jdshfjdhfjdhfdjhfjd", this.props);
    const { users, classes, isLoading } = this.state;
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
          <GridItem xs={11} sm={11} md={11}>
            <AzureChart chartData={this.props.azureChart} />
          </GridItem>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
        </GridContainer>
        <GridContainer>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
          <GridItem xs={11} sm={11} md={11}>
            <Card>
              <CardHeader title="Cost Management Data Table" />
              <CardBody>
                {isLoading === true && (
                  <div style={styling}>
                    <CircularProgress
                      className={classes.progress}
                      color="secondary"
                    />
                  </div>
                )}
                {isLoading === false && (
                  <Table
                    tableHeaderColor="primary"
                    tableHead={["Resource", "Month", "Quantity", "Cost"]}
                    tableData={users.map(user => [
                      user.Resource,
                      `${dateFunction(user.Month)}`,
                      `${getLocaleNumber(user.Quantity)}`,
                      user.Currency === "GBP"
                        ? `£ ${getLocaleNumber(user.Cost)}`
                        : `$ ${getLocaleNumber(user.Cost)}`
                    ])}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(AzureCostReport);
