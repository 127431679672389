import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { GcpCostReportActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [GcpCostReportActions.gcpChartRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [GcpCostReportActions.gcpChartResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        gcpChartData: action.payload
      }),
    [GcpCostReportActions.gcpChartFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [GcpCostReportActions.gcpTableRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [GcpCostReportActions.gcpTableResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        gcpTableData: action.payload
      }),
    [GcpCostReportActions.gcpTableFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
