import React, { Component } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Table from "../../components/Table/Table.jsx";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../components/Card/CardBody.jsx";
import CanvasChart from "./CanvasChart.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { TreeSelect } from "antd";
import { capitalize } from "lodash";
import ls from "local-storage";
import { costDataServices, awsChartServices } from "../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  progress: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "40px",
    marginBottom: "40px"
  }
};
const styling = {
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "40px",
  marginBottom: "40px",
  textAlign: "center"
};

const getLocaleNumber = currency =>
  currency.toLocaleString({
    minimumFractionDigits: 2
  });

class CostDraft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isLoading: true,
      errors: null,
      classes: props,
      entities: [],
      userid: ls.get("customerId"),
      costToken: ls.get("idToken")
    };
  }
  onChange = value => {
    this.setState({ entities: value });

    this.props.actions.costData.findCostTableData(
      this.state.costToken,
      this.state.userid,
      JSON.stringify(value)
    );
    this.props.actions.awsChart.findAwsChartData(
      this.state.costToken,
      this.state.userid,
      JSON.stringify(value)
    );
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.userData && nextProps.entities) {
      this.setState({ users: nextProps.userData });
      this.setState({ isLoading: false });
    }
  }
  render() {
    const { users, classes, isLoading } = this.state;
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
          <GridItem xs={11} sm={11} md={11}>
            {isLoading === true && (
              <div style={styling}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              </div>
            )}
            {isLoading === false && (
              <div className="mt-5 mb-5">
                <form>
                  <div className="row mt-4">
                    <div className="col">
                      <div className="form-group">
                        <label className="mr-2"> Select Group Function / Local Market</label>
                        <TreeSelect
                          showSearch
                          style={{ width: 300 }}
                          value={this.state.entities.map(a => a)}
                          dropdownStyle={{ maxHeight: 150, overflow: "auto" }}
                          placeholder="Please select"
                          treeData={this.props.entities.map(a => ({
                            id: a.Id,
                            pId: a.Parent,
                            value: a.Id,
                            title: capitalize(a.Name)
                          }))}
                          treeDataSimpleMode={true}
                          allowClear
                          multiple
                          treeDefaultExpandAll
                          onChange={a => this.onChange(a, this.props.entities)}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
            <CanvasChart awsChart={this.props.awsChart} />
          </GridItem>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
        </GridContainer>
        <GridContainer>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
          <GridItem xs={11} sm={11} md={11}>
            <Card>
              <CardHeader title="Cost Management Data Table" />
              <CardBody>
                {isLoading === true && (
                  <div style={styling}>
                    <CircularProgress
                      className={classes.progress}
                      color="secondary"
                    />
                  </div>
                )}
                {isLoading === false && (
                  <Table
                    tableHeaderColor="primary"
                    tableHead={Object.keys(users[0])}
                    tableData={users.map(user => [
                      user.ACCOUNTS,
                      user.MONTH,
                      `$ ${getLocaleNumber(user.COST)}`
                    ])}
                  />
                )}
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
        </GridContainer>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    costData: state.costData.costTableData,
    awsChart: state.awsChart.awsChartData,
    entities: state.entities.entityList
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      costData: bindActionCreators(costDataServices, dispatch),
      awsChart: bindActionCreators(awsChartServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CostDraft);
