import "react-sortable-tree/style.css";
import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Icon from "@material-ui/core/Icon";
import SortableTree, {
  getTreeFromFlatData,
  addNodeUnderParent,
  removeNodeAtPath,
  getFlatDataFromTree
} from "react-sortable-tree";
import { entityServices, NewEntityServices } from "../../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ls from "local-storage";
import { capitalize } from "lodash";
var abc;
const getNodeKey = ({ treeIndex }) => treeIndex;
const paddingButton = {
  paddingRight: "0",
  paddingLeft: "0"
};
class EntityTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: getTreeFromFlatData({
        flatData: props.treeData.map(node => ({
          ...node,
          title: capitalize(node.name)
        })),
        getKey: node => node.id, // resolve a node's key
        getParentKey: node => node.parent, // resolve a node's parent's key
        rootKey: null // The value of the parent key when there is no parent (i.e., at root level)
      }),
      addAsFirstChild: false,
      addModal: false,
      editModal: false,
      entity: props.entities,
      parentEntity: "",
      newPath: [],
      parentName: "",
      addParentModal: false,
      userid: ls.get("customerId"),
      token: ls.get("idToken"),
      newAddedParentName: "",
      NewAddedAncestors: "",
      newAddedName: "",
      //OldName: "",
      newEditedId: "",
      newEditedParentName: "",
      NewEditedAncestors: [],
      editList: [],
      newData: props.entities,
      showButton: false,
      AssignContent: false
    };
    //this.toggleAddParentModal = this.toggleAddParentModal.bind(this);
    this.toggleAddModal = this.toggleAddModal.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.AssignItem = this.AssignItem.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.newDataChange = this.newDataChange.bind(this);
    //this.handleParentSubmit = this.handleParentSubmit.bind(this);
  }
  addChildNode(node, path) {
    this.setState(state => ({
      treeData: addNodeUnderParent({
        treeData: state.treeData,
        parentKey: path[path.length - 1],
        expandParent: true,
        getNodeKey,
        newNode: {
          id: node,
          name: node,
          parent: this.state.parentName,
          title: node
        },
        addAsFirstChild: state.addAsFirstChild
      }).treeData
    }));

    console.log("path", path);
    console.log("node", node);
  }
  AddTodo(key, event) {
    var change = {
      entity: this.state.entity
    };
    const value = event.target.value;
    change[key] = value;
    this.setState(change);

    console.log("entity", this.state.entity);
  }
  handleSubmit() {
    var newvalues = [{ name: "entity" }];
    var detailsMapping;
    var changedDetails = newvalues.map(
      c =>
        (detailsMapping = {
          [c.name]: this.state[c.name]
        })
    );
    this.detailsMapping = detailsMapping;
    console.log("changed Details", changedDetails);
    console.log("detailsMapping", detailsMapping);
    this.addChildNode(detailsMapping.entity, this.state.newPath);
    this.setState({ newAddedName: detailsMapping.entity });
    var newObj = {
      Parent: this.state.newAddedParentName,
      Ancestors: this.state.NewAddedAncestors,
      Name: detailsMapping.entity
    };
    const newEntitiesList = this.props.entities.concat(newObj);
    console.log("hello", newObj);
    console.log("newEntitiesList", newEntitiesList);
    this.props.actions.entities.addEntities(
      this.state.token,
      this.state.userid,
      newEntitiesList
    );
    this.toggleAddModal();
  }
  /* //Parent root entity not required so commented out
  handleParentSubmit() {
    var newvalues = [{ name: "parentEntity" }];
    var detailsMapping;
    var changedDetails = newvalues.map(
      c =>
        (detailsMapping = {
          [c.name]: this.state[c.name]
        })
    );
    this.detailsMapping = detailsMapping;
    console.log("changed Details", changedDetails);
    console.log("detailsMapping", detailsMapping);
    this.addParentNode(detailsMapping.parentEntity);
    this.toggleAddParentModal();
  }
  addParentNode(parentname) {
    this.setState(state => ({
      treeData: state.treeData.concat({
        id: parentname,
        name: parentname,
        parent: null,
        title: parentname
      })
    }));
  }
  toggleAddParentModal() {
    this.setState(prevState => ({
      addParentModal: !prevState.addParentModal
    }));
  }
   AddParentItem() {
    var newvalues = [{ name: "parentEntity" }];
    var changedDetails = newvalues.map(c => this.setState({ [c.name]: "" }));
    this.setState({ Addenabled: true });
    console.log("details initial", changedDetails);
    this.toggleAddParentModal();
  }
  AddParent(key, event) {
    var change = {
      parentEntity: this.state.parentEntity
    };
    const value = event.target.value;
    change[key] = value;
    this.setState(change);
    console.log("parentEntity", this.state.parentEntity);
  }
  */
  toggleAddModal() {
    this.setState(prevState => ({
      addModal: !prevState.addModal
    }));
  }

  toggleEditModal() {
    this.setState(prevState => ({
      editModal: !prevState.editModal
    }));
  }
  EditItem(node, path) {
    abc = "";
    this.setState({ showButton: false });
    console.log("kkkkl", node);
    this.toggleEditModal();
    //console.log("llll", path);
    this.setState({ newPath: path });
    this.setState({ parentName: node.name });
    this.setState({ entity: node.name });
    //this.setState({ OldName: node.name });
    this.setState({
      newPath: path,
      parentName: node.name,
      entity: node.name
    });
    var obj = this.props.entities.find(ent => ent.Name === node.name);
    var a = this.props.entities.filter(pro => pro.Name !== node.name);
    this.setState({ editList: a });
    console.log("add node", obj);
    this.setState({
      newEditedId: obj.Id,
      newEditedParentName: obj.Parent,
      NewEditedAncestors: obj.Ancestors
    });
  }
  EditTodo(key, event) {
    var change = {
      entity: this.state.entity
    };
    const value = event.target.value;
    change[key] = value;
    this.setState(change);
  }

  handleEditSubmit() {
    var newvalues = [{ name: "entity" }];
    var detailsMapping;
    var changedDetails = newvalues.map(
      c =>
        (detailsMapping = {
          [c.name]: this.state[c.name]
        })
    );
    this.detailsMapping = detailsMapping;
    console.log("changed Details", changedDetails);
    console.log("detailsMapping", detailsMapping);
    //this.addChildNode(detailsMapping.entity, this.state.newPath);
    var newObj = {
      Id: this.state.newEditedId,
      Parent: this.state.newEditedParentName,
      Ancestors: this.state.NewEditedAncestors,
      //OldName: this.state.OldName,
      Name: detailsMapping.entity
    };
    const newEntitiesList = this.state.editList.concat(newObj);
    console.log("hello", newObj);
    console.log("newEntitiesList", newEntitiesList);
    this.props.actions.entities.editEntities(
      this.state.token,
      this.state.userid,
      newEntitiesList
    );
  }
  /*var newvalues = [{ name: "entity" }];
    var changedDetails = newvalues.map(c => this.setState({ [c.name]: "" }));
    this.setState({ Addenabled: true });
    console.log("details initial", changedDetails);
    
    var obj = this.props.entities.find(ent => ent.Name === node.name);
    console.log("add node", obj);
    this.setState({
      newAddedParentName: obj.Name,
      NewAddedAncestors: obj.Ancestors.concat(obj.Name)
    });*/
  //}
  AddItem(node, path) {
    abc = "";
    this.setState({ showButton: false });
    console.log("kkkkl", node);
    //console.log("llll", path);
    this.setState({ newPath: path });
    this.setState({ parentName: node.name });
    var newvalues = [{ name: "entity" }];
    var changedDetails = newvalues.map(c => this.setState({ [c.name]: "" }));
    this.setState({ Addenabled: true });
    console.log("details initial", changedDetails);
    this.toggleAddModal();
    var obj = this.props.entities.find(ent => ent.Name === node.name);
    console.log("add node", obj);
    this.setState({
      newAddedParentName: obj.Id,
      NewAddedAncestors: obj.Ancestors.concat(obj.Id)
    });
  }
  deleteItem(node, path) {
    abc = "";
    this.setState({ showButton: false });
    this.setState(state => ({
      treeData: removeNodeAtPath({
        treeData: state.treeData,
        path,
        getNodeKey
      })
    }));
    console.log("node", node);
    var obj = this.props.entities.find(ent => ent.Name === node.name);
    var a = this.props.entities.filter(pro => pro.Name !== node.name);
    console.log("onj", obj);
    console.log("filtered entities", a);
    this.props.actions.entities.deleteEntities(
      this.state.token,
      this.state.userid,
      a
    );
  }
  changedData(treeData) {
    this.setState({ treeData: treeData });
    const flatData = getFlatDataFromTree({
      treeData: this.state.treeData,
      getNodeKey: ({ node }) => node.id, // This ensures your "id" properties are exported in the path
      ignoreCollapsed: false // Makes sure you traverse every node in the tree, not just the visible ones
    }).map(({ node, path }) => ({
      id: node.id,
      name: node.name,

      // The last entry in the path is this node's key
      // The second to last entry (accessed here) is the parent node's key
      parent: path.length > 1 ? path[path.length - 2] : null
    }));
    console.log("new flatData", flatData);
    var newData;
    if (this.props.entities) {
      newData = flatData.map(a => ({
        Id: this.props.entities.find(ent => ent.Name === a.name).Id,
        Name: a.name,
        Parent: a.parent ? a.parent : "",
        Ancestors:
          a.parent !== null
            ? this.props.entities
                .find(ent => ent.Id === a.parent)
                .Ancestors.concat(a.parent)
            : []
      }));
    }
    console.log("new entites", newData);
    this.setState({ newData: newData });
  }
  eee({ nextParent }) {
    abc = "started";
    console.log("started");
    console.log("next prent", nextParent);
    if (nextParent !== null) {
      return true;
    }
  }
  newDataChange() {
    this.props.actions.entities.changeEntities(
      this.state.token,
      this.state.userid,
      this.state.newData
    );
  }
  AssignItem(status, nodeId) {
    this.setState({ AssignContent: status });
    this.props.hello(status, nodeId);
    this.props.actions.newEntities.findAssignedCloudAccountEntities(
      this.state.token,
      this.state.userid,
      nodeId
    );
  }
  render() {
    console.log("tree", this.state.treeData);
    if (abc) {
      this.state.showButton = true;
    }
    console.log("button", this.state.showButton);
    console.log("data", this.state.newData);
    return (
      <div>
        <Card>
          <CardHeader title="Group Function/Local Market" />
          <CardBody>
            <div style={{ height: 650 }}>
              <SortableTree
                treeData={this.state.treeData}
                canDrop={canDrop => this.eee(canDrop)}
                onChange={treeData => this.changedData(treeData)}
                //getNodeKey={({ node }) => node.id}
                generateNodeProps={({ node, path }) => ({
                  buttons: [
                    <Button
                      style={paddingButton}
                      color="action"
                      key={node}
                      onClick={() => this.AddItem(node, path)}
                    >
                      <Icon>add_circle</Icon>
                    </Button>,
                    <Button
                      style={paddingButton}
                      color="action"
                      key={node}
                      onClick={() => this.EditItem(node, path)}
                    >
                      <Icon>edit_circle</Icon>
                    </Button>,
                    <Button
                      style={paddingButton}
                      color="action"
                      key={node}
                      onClick={() => this.deleteItem(node, path)}
                    >
                      <Icon>delete</Icon>
                    </Button>,
                    <Button
                      style={paddingButton}
                      color="action"
                      key={node}
                      onClick={() => this.AssignItem(true, node.id)}
                    >
                      <Icon>account_box</Icon>
                    </Button>
                  ]
                })}
              />
            </div>
            {/*<button onClick={() => this.AddParentItem()}>
                  Add new parent enity
                  </button>*/}
            {this.state.showButton && (
              <Button
                color="primary"
                size="sm"
                onClick={() => this.newDataChange()}
              >
                Save changes
              </Button>
            )}
          </CardBody>
        </Card>
        <Modal isOpen={this.state.editModal} className={this.props.className}>
          <ModalHeader toggle={this.toggleEditModal}>Edit Entity</ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleEditSubmit}>
              <div className="form-group">
                <label>Entity Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.entity}
                  onChange={e => this.EditTodo("entity", e)}
                />
              </div>

              <div className="row mt-4">
                <div className="col" />
                <div className="col">
                  <Button
                    color="secondary"
                    onClick={this.toggleEditModal}
                    className="w-100"
                  >
                    Cancel
                  </Button>{" "}
                </div>
                <div className="col">
                  <Button color="primary" className="w-100">
                    Save
                  </Button>
                </div>
                <div className="col" />
              </div>
            </form>
          </ModalBody>
          <ModalFooter />
        </Modal>
        <Modal isOpen={this.state.addModal} className={this.props.className}>
          <ModalHeader toggle={this.toggleAddModal}>Add Entity</ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label>Entity Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.entity}
                  onChange={e => this.AddTodo("entity", e)}
                />
              </div>

              <div className="row mt-4">
                <div className="col" />
                <div className="col">
                  <Button
                    color="secondary"
                    onClick={this.toggleAddModal}
                    className="w-100"
                  >
                    Cancel
                  </Button>{" "}
                </div>
                <div className="col">
                  <Button color="primary" className="w-100">
                    Save
                  </Button>
                </div>
                <div className="col" />
              </div>
            </form>
          </ModalBody>
          <ModalFooter />
        </Modal>
        {/*<Modal
          isOpen={this.state.addParentModal}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggleAddParentModal}>
            Add Entity
          </ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleParentSubmit}>
              <div className="form-group">
                <label>Entity Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.parentEntity}
                  onChange={e => this.AddParent("parentEntity", e)}
                />
              </div>

              <div className="row mt-4">
                <div className="col" />
                <div className="col">
                  <Button
                    color="secondary"
                    onClick={this.toggleAddParentModal}
                    className="w-100"
                  >
                    Cancel
                  </Button>{" "}
                </div>
                <div className="col">
                  <Button color="primary" className="w-100">
                    Save
                  </Button>
                </div>
                <div className="col" />
              </div>
            </form>
          </ModalBody>
          <ModalFooter />
        </Modal>*/}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    entities: state.entities.entityList,
    newEntities: state.newEntity.newEntityAssignedList
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      entities: bindActionCreators(entityServices, dispatch),
      newEntities: bindActionCreators(NewEntityServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityTree);
