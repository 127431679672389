import { createAction } from "redux-actions";
import { ActionTypes } from "../constants";

export const listEntityRequest = createAction(ActionTypes.LIST_ENTITY_REQUEST);
export const listEntityResponse = createAction(
  ActionTypes.LIST_ENTITY_RESPONSE
);
export const listEntityFailure = createAction(ActionTypes.LIST_ENTITY_FAILURE);
export const listEntityDeleteRequest = createAction(
  ActionTypes.LIST_ENTITY_DELETE_REQUEST
);
export const listEntityDeleteResponse = createAction(
  ActionTypes.LIST_ENTITY_DELETE_RESPONSE
);
export const listEntityDeleteFailure = createAction(
  ActionTypes.LIST_ENTITY_DELETE_FAILURE
);
export const listEntityAddRequest = createAction(
  ActionTypes.LIST_ENTITY_ADD_REQUEST
);
export const listEntityAddResponse = createAction(
  ActionTypes.LIST_ENTITY_ADD_RESPONSE
);
export const listEntityAddFailure = createAction(
  ActionTypes.LIST_ENTITY_ADD_FAILURE
);
export const listEntityEditRequest = createAction(
  ActionTypes.LIST_ENTITY_EDIT_REQUEST
);
export const listEntityEditResponse = createAction(
  ActionTypes.LIST_ENTITY_EDIT_RESPONSE
);
export const listEntityEditFailure = createAction(
  ActionTypes.LIST_ENTITY_EDIT_FAILURE
);
export const listEntityChangeRequest = createAction(
  ActionTypes.LIST_ENTITY_CHANGE_REQUEST
);
export const listEntityChangeResponse = createAction(
  ActionTypes.LIST_ENTITY_CHANGE_RESPONSE
);
export const listEntityChangeFailure = createAction(
  ActionTypes.LIST_ENTITY_CHANGE_FAILURE
);
export default {};
