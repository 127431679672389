import { RSAA } from "redux-api-middleware";
import { costManagementTable } from "../constants/api-endpoints";
import { ActionTypes } from "../constants";
var date = new Date();
var firstDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  1
).toLocaleDateString("en-GB");
var lastDay = new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  0
).toLocaleDateString("en-GB");
export const findCostTableData = (token, custId, entity) => ({
  [RSAA]: {
    endpoint: `${costManagementTable}&dateFrom=${firstDay}&dateTo=${lastDay}`,
    method: "GET",
    headers: {
      Authorization: `${token}`,
      customer: `${custId}`,
      entities: entity
    },
    types: [
      ActionTypes.COST_TABLE_FIND_REQUEST,
      ActionTypes.COST_TABLE_FIND_RESPONSE,
      ActionTypes.COST_TABLE_FIND_FAILURE
    ]
  }
});
/*export function findCostTableData(token, custId) {
  return function(dispatch) {
    dispatch({
      type: ActionTypes.COST_TABLE_FIND_REQUEST
    });

    fetch(`${costManagementTable}`, {
      method: "GET",
      headers: {
        Authorization: `${token}`,
        customer: `${custId}`
      }
    })
      .then(response => response.json())
      .then(data =>
        dispatch({
          type: ActionTypes.COST_TABLE_FIND_RESPONSE,
          payload: data
        })
      )
      .catch(error =>
        dispatch({
          type: ActionTypes.COST_TABLE_FIND_FAILURE,
          payload: error
        })
      );
  };
}*/
