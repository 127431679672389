import { RSAA } from "redux-api-middleware";
import { entityEndpoint } from "../constants/api-endpoints";
import { ActionTypes } from "../constants";
//var token = "eyJraWQiOiJVVmtCWFZBTkNhUXorYTVvTDBcL2pXSmxUemVaU1FqQkNNU1JROGJuSHlxbz0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiZGFMMlBmTzk1cGZDUmo3aHRaa280QSIsInN1YiI6IjM4ZDVlMDBhLTcyNGMtNDIyOS05MTNjLTFlODVhYTRlM2UzOCIsImNvZ25pdG86Z3JvdXBzIjpbInN1cGVyLWFkbWluIl0sImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xXzlTcnNOZVdRayIsImNvZ25pdG86dXNlcm5hbWUiOiJzYXFsYWluLnNoYWJiaXJAdm9kYWZvbmUuY29tIiwiYXVkIjoiM3Fyb2Y4NzkxdmdjOXZlYTJvb2oyMnVhZGciLCJldmVudF9pZCI6ImUzMThkZDhhLThhZjktNGIxMC04OGU0LTg3MTdlNGZhNThhYiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNTY3NjkxNjgzLCJleHAiOjE1Njc2OTUyODMsImFjdGlvbnMiOiJbXSIsImlhdCI6MTU2NzY5MTY4MywiZW50aXR5IjoiYWNtZSIsImVtYWlsIjoic2FxbGFpbi5zaGFiYmlyQHZvZGFmb25lLmNvbSIsImN1c3RvbWVyIjoiNjcyZjUzNTgtYjdmZi0xOGQxLTFmOTctYzlmMmZmNjBhNmM4IiwiZ3JvdXAiOiJzdXBlci1hZG1pbiJ9.HJGTetQuaoNmi9hMCYEPwkThxcPDUQM-vLTVyWNBBfEzRzswyiWFJpHvRj2zLkOJEJAZ5OG3EzqkaFxwGvul9Z0irE5yTHvBIqqKCD1ldeas7ef5Wb1vdrN_aCQhgOwnfnyS6amfOv8_1oG9DjaK1nPx64s9CCZlzPpiieVYvKvj1jOuOtTY-Qj1ItajZaZHCBd7E4p14yRrIWAYfPf2lbZ2cm_RMLbc98xwx3zyKdD-j8qOttqw3-ayf1JYh6bskEbbGCOeK_wckpjWTIJxfcXwQMhbK_DeYhnL1BDmzWF5zFcasYC1w_HQam4me1tZKtbb_ETczOEm2yrbnA7R8A";
//var id = "672f5358-b7ff-18d1-1f97-c9f2ff60a6c8";
export const findEntities = (userToken, customer) => ({
  [RSAA]: {
    endpoint: `${entityEndpoint}`,
    method: "GET",
    headers: {
      Authorization: `${userToken}`,
      customer: `${customer}`
    },
    types: [
      ActionTypes.LIST_ENTITY_REQUEST,
      ActionTypes.LIST_ENTITY_RESPONSE,
      ActionTypes.LIST_ENTITY_FAILURE
    ]
  }
});
export const deleteEntities = (userToken, customer, body) => ({
  [RSAA]: {
    endpoint: `${entityEndpoint}`,
    method: "PUT",
    headers: {
      Authorization: `${userToken}`,
      customer: `${customer}`
    },
    body: `${JSON.stringify(body)}`,
    types: [
      ActionTypes.LIST_ENTITY_DELETE_REQUEST,
      ActionTypes.LIST_ENTITY_DELETE_RESPONSE,
      ActionTypes.LIST_ENTITY_DELETE_FAILURE
    ]
  }
});

export const addEntities = (userToken, customer, body) => ({
  [RSAA]: {
    endpoint: `${entityEndpoint}`,
    method: "PUT",
    headers: {
      Authorization: `${userToken}`,
      customer: `${customer}`
    },
    body: `${JSON.stringify(body)}`,
    types: [
      ActionTypes.LIST_ENTITY_ADD_REQUEST,
      ActionTypes.LIST_ENTITY_ADD_RESPONSE,
      ActionTypes.LIST_ENTITY_ADD_FAILURE
    ]
  }
});

export const editEntities = (userToken, customer, body) => ({
  [RSAA]: {
    endpoint: `${entityEndpoint}`,
    method: "PUT",
    headers: {
      Authorization: `${userToken}`,
      customer: `${customer}`
    },
    body: `${JSON.stringify(body)}`,
    types: [
      ActionTypes.LIST_ENTITY_EDIT_REQUEST,
      ActionTypes.LIST_ENTITY_EDIT_RESPONSE,
      ActionTypes.LIST_ENTITY_EDIT_FAILURE
    ]
  }
});
export const changeEntities = (userToken, customer, body) => ({
  [RSAA]: {
    endpoint: `${entityEndpoint}`,
    method: "PUT",
    headers: {
      Authorization: `${userToken}`,
      customer: `${customer}`
    },
    body: `${JSON.stringify(body)}`,
    types: [
      ActionTypes.LIST_ENTITY_CHANGE_REQUEST,
      ActionTypes.LIST_ENTITY_CHANGE_RESPONSE,
      ActionTypes.LIST_ENTITY_CHANGE_FAILURE
    ]
  }
});
export default { findEntities };
