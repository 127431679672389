import React from "react";
import { TreeSelect } from "antd";
import ls from "local-storage";
import { capitalize } from "lodash";
//Sample entity Api Data
const data = [
  {
    Parent: "eu",
    Ancestors: ["acme", "eu"],
    Name: "uk"
  },
  {
    Parent: "acme",
    Ancestors: ["acme"],
    Name: "eu"
  },
  {
    Parent: "eu",
    Ancestors: ["uk", "acme", "eu"],
    Name: "de"
  },
  {
    Parent: "uk",
    Ancestors: ["uk", "acme", "eu"],
    Name: "england"
  },
  {
    Parent: "acme",
    Ancestors: ["acme"],
    Name: "apac"
  },
  {
    Name: "acme",
    Ancestors: []
  }
];

//Third party app Data//
/*const simplemode = [
  {
    id: 1,
    pId: 0,
    value: "1",
    title: "test1",
    children: [
      {
        id: 2,
        pId: 1,
        value: "2",
        title: "test2"
      },
      {
        id: 3,
        pId: 1,
        value: "3",
        title: "Child Node2"
      }
    ]
  },
  {
    id: 4,
    pId: 1,
    value: "4",
    title: "test4",
    children: [
      {
        id: 5,
        pId: 4,
        value: "5",
        title: "test2"
      },
      {
        id: 6,
        pId: 4,
        value: "6",
        title: "Child Node2"
      }
    ]
  }
];
const treeData = [
  {
    title: "Node1",
    value: "0-0",
    key: "0-0",
    children: [
      {
        title: "Child Node1",
        value: "0-0-1",
        key: "0-0-1"
      },
      {
        title: "Child Node2",
        value: "0-0-2",
        key: "0-0-2"
      }
    ]
  },
  {
    title: "Node2",
    value: "0-1",
    key: "0-1"
  }
];
*/

const valueMap = {};
/*function loops(list, parent) {
  return (list || []).map(({ children, value }) => {
    const node = (valueMap[value] = {
      parent,
      value
    });
    node.children = loops(children, node);
    return node;
  });
}*/

function getPath(value) {
  const path = [];
  let current = valueMap[value];
  while (current) {
    path.unshift(current.value);
    current = current.parent;
  }
  return path;
}
class TreeDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: props.treeData,
      value: undefined
    };
    console.log("proopopo", props);
  }
  onChange = value => {
    console.log("Change", getPath(value));
    this.setState({ value });
    console.log("Change org", value);
    var entId = this.props.entities.find(ent => ent.Name === value).Id;
    console.log("abc", entId.split());
    localStorage.setItem("org", entId);
    localStorage.setItem("orgAzure", value);
  };

  onSelect = value => {
    console.log("Select:", getPath(value));
  };

  render() {
    console.log("treeData", this.state.treeData);
    return (
      <div className="mca-single">
        <TreeSelect
          style={{ width: 300 }}
          value={this.state.value}
          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
          treeData={this.state.treeData}
          treeDataSimpleMode={true}
          placeholder={capitalize(
            !ls.get("orgAzure") ? "organizations" : ls.get("orgAzure")
          )}
          treeDefaultExpandAll
          onChange={this.onChange}
          onSelect={this.onSelect}
        />
      </div>
    );
  }
}

export default TreeDropdown;
