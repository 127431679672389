import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { UserProfileActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [UserProfileActions.userProfileFindRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [UserProfileActions.userProfileFindResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        userProfile: action.payload
      }),
    [UserProfileActions.userProfileFindFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
