import { RSAA } from "redux-api-middleware";
import ls from "local-storage";
const env = process.env.REACT_APP_STAGE;
console.log("env", env);

var env_api;

if (env === "prod") {
  env_api = ".";
} else {
  env_api = `${env}.`;
}
export default function() {
  return function(next) {
    return function(action) {
      const callApi = action[RSAA];

      // Check if this action is a redux-api-middleware action.
      if (callApi) {
        // Inject the Authorization header from localStorage.
        console.log("call endpoint", callApi.endpoint);
        if (
          callApi.endpoint ===
          `https://api.${env_api}mcloudadmin.com/auth/profile`
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || ""
          });
        } else if (
          callApi.endpoint ===
          `https://api.${env_api}mcloudadmin.com/cm/report?tabledata=monthlycost`
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || "",
            customer: ls.get("customerId") || ""
          });
        } else if (
          callApi.endpoint ===
          `https://api.${env_api}mcloudadmin.com/cm/report?chartdata=monthlycost&month=4&year=2019`
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || "",
            customer: ls.get("customerId") || ""
          });
        } else if (
          callApi.endpoint ===
          `https://api.${env_api}mcloudadmin.com/auth/users`
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || ""
          });
        } else if (
          callApi.endpoint === `https://api.${env_api}mcloudadmin.com/auth/user`
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || ""
          });
        } else if (
          callApi.endpoint ===
          "https://multicloudadmincostsappservicedev.azurewebsites.net/api/Usage?code=HJSY8vOlwhfjYZKjxy5/C9t71eInobFtGLkHMvpw0kG9fbSXjQMBFA==&tenantId=137ff3cd-10ca-4e0f-9ae8-ecca51ac6cc0&clientId=4f8f0b23-132a-4572-84c5-2afc9779d48b&subscriptionId=85a0fc2c-a55e-48a7-8619-e9f4121547f8"
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || ""
          });
        } else if (
          callApi.endpoint ===
          "https://multicloudadmincostsappservicedev.azurewebsites.net/api/Usage?code=HJSY8vOlwhfjYZKjxy5/C9t71eInobFtGLkHMvpw0kG9fbSXjQMBFA==&tenantId=137ff3cd-10ca-4e0f-9ae8-ecca51ac6cc0&clientId=4f8f0b23-132a-4572-84c5-2afc9779d48b&subscriptionId=85a0fc2c-a55e-48a7-8619-e9f4121547f8&table=true"
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || ""
          });
        } else if (
          callApi.endpoint ===
          `https://api.${env_api}mcloudadmin.com/gcp-cm2/report2?tabledata=monthlycost`
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || "",
            customer: ls.get("customerId") || ""
          });
        } else if (
          callApi.endpoint ===
          `https://api.${env_api}mcloudadmin.com/gcp-cm2/report2?chartdata=monthlycost`
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || "",
            customer: ls.get("customerId") || ""
          });
        } else if (
          callApi.endpoint === `https://api.${env_api}mcloudadmin.com/entity`
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || "",
            customer: ls.get("customerId") || ""
          });
        } else if (
          callApi.endpoint ===
          `https://uik63w0c0j.execute-api.us-east-1.amazonaws.com/DEV`
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || "",
            customer: ls.get("customerId") || ""
          });
        } else if (
          callApi.endpoint ===
          `https://uik63w0c0j.execute-api.us-east-1.amazonaws.com/DEV`
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || "",
            customer: ls.get("customerId") || ""
          });
        } else if (
          callApi.endpoint ===
          "https://uik63w0c0j.execute-api.us-east-1.amazonaws.com/DEV"
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            Authorization: ls.get("idToken") || "",
            customer: ls.get("customerId") || ""
          });
        } else if (
          callApi.endpoint ===
          "https://us-central1-destination-project-transfer.cloudfunctions.net/projects-read1"
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {});
        } else if (
          callApi.endpoint ===
          "https://us-central1-vf-grp-pcs-dev-mca.cloudfunctions.net/Projects_New1"
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {});
        } else if (
          callApi.endpoint ===
          "https://us-central1-vf-grp-pcs-dev-mca.cloudfunctions.net/prj"
        ) {
          callApi.headers = Object.assign({}, callApi.headers, {
            "Content-Type": "application/json"
          });
        } else {
          callApi.headers = Object.assign({}, callApi.headers, {});
        }
      }
      // Pass the FSA to the next action.
      return next(action);
    };
  };
}
