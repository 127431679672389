import { RSAA } from "redux-api-middleware";
import { authUsersEndpoint, userEndpoint } from "../constants/api-endpoints";
import { ActionTypes } from "../constants";

export const findAuthUsers = userToken => ({
  [RSAA]: {
    endpoint: `${authUsersEndpoint}`,
    method: "GET",
    headers: {
      Authorization: `${userToken}`
    },
    types: [
      ActionTypes.AUTH_USERS_FIND_REQUEST,
      ActionTypes.AUTH_USERS_FIND_RESPONSE,
      ActionTypes.AUTH_USERS_FIND_FAILURE
    ]
  }
});
export const getDeletedUser = (userToken, email) => ({
  [RSAA]: {
    endpoint: `${userEndpoint}?email=${email}`,
    method: "DELETE",
    body: JSON.stringify({}),
    headers: {
      "Content-Type": "application/json",
      Authorization: `${userToken}`
    },
    types: [
      ActionTypes.USER_DELETE_REQUEST,
      ActionTypes.USER_DELETE_RESPONSE,
      ActionTypes.USER_DELETE_FAILURE
    ]
  }
});
export const getUpdatedUser = (userToken, url) => ({
  [RSAA]: {
    endpoint: `${userEndpoint}${url}`,
    method: "PUT",
    body: JSON.stringify({}),
    headers: {
      "Content-Type": "application/json",
      Authorization: `${userToken}`
    },
    types: [
      ActionTypes.USER_EDIT_REQUEST,
      ActionTypes.USER_EDIT_RESPONSE,
      ActionTypes.USER_EDIT_FAILURE
    ]
  }
});
export const getAddUser = (userToken, url) => ({
  [RSAA]: {
    endpoint: `${userEndpoint}${url}`,
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      "Content-Type": "application/json",
      Authorization: `${userToken}`
    },
    types: [
      ActionTypes.USER_ADD_REQUEST,
      ActionTypes.USER_ADD_RESPONSE,
      ActionTypes.USER_ADD_FAILURE
    ]
  }
});
/*export function findAuthUsers(token) {
  return function(dispatch) {
    dispatch({
      type: ActionTypes.AUTH_USERS_FIND_REQUEST
    });

    fetch(`${authUsersEndpoint}`, {
      method: "GET",
      headers: {
        Authorization: `${token}`
      }
    })
      .then(response => response.json())
      .then(data =>
        dispatch({
          type: ActionTypes.AUTH_USERS_FIND_RESPONSE,
          payload: data
        })
      )
      .catch(error =>
        dispatch({
          type: ActionTypes.AUTH_USERS_FIND_FAILURE,
          payload: error
        })
      );
  };
}*/
