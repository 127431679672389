import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Collapse from "@material-ui/core/Collapse";
// ListItemIcon from "@material-ui/core/ListItemIcon";
//import StarBorder from "@material-ui/icons/StarBorder";
// core components
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
//import InboxIcon from "@material-ui/icons/MoveToInbox";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import sidebarStyle from "../../assets/jss/material-dashboard-react/components/sidebarStyle.jsx";
//import Button from "../CustomButtons/Button.jsx";
//import Maximize from "@material-ui/icons/Maximize";
import MainTreeDropDown from "./mainTreeDropDown";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const logoStyle = {
  top: "5px",
  left: "86px",
  width: "80px",
  height: "50px",
  border: "0px",
  position: "absolute",
  verticalAlign: "middle"
};
const TreeStyle = {
  bottom: "13px !important",
  position: "absolute !important"
};
const DropDownStyle = {
  width: "90%",
  bottom: "13px",
  position: "absolute",
  //backgroundColor: "#00acc1",
  boxShadow:
    "0 12px 20px -10px rgba(0, 172, 193,.28), 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px rgba(0, 172, 193,.2)",
  //width: "240px",
  display: "inline-flex",
  marginLeft: "15px",
  borderRadius: "5px",
  padding: "8px"
};
/*const nested = {
  bottom: "80px",
  position: "absolute",
  width: "100%"
};*/
const Sidebar = ({ ...props }) => {
  //const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openAdmin, setOpenAdmin] = React.useState(false);
  const [dropdownOpen, setdropdownOpen] = React.useState(false);
  function handleClick() {
    setOpen(!open);
    setOpenAdmin(false);
  }
  function handleAdminClick() {
    setOpenAdmin(!openAdmin);
    setOpen(false);
  }
  function toggle() {
    setdropdownOpen(!dropdownOpen);
  }

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
  }
  const { classes, color, logo, image, logoText, routes } = props;

  const bold = {
    fontWeight: 500
  };
  const hello = {
    width: "240px",
    display: "inline-flex"
  };

  /*const adminStyle = {
    boxShadow:
      "0 12px 20px -10px rgba(0, 172, 193,.28), 0 4px 20px 0 rgba(0, 0, 0,.12), 0 7px 8px -5px rgba(0, 172, 193,.2)",
    backgroundColor: "#00acc1",
    width: "240px",
    display: "inline-flex"
  };*/
  let options = [
    { id: 0, subPath: "/costData", name: "Cost Report" },
    { id: 1, subPath: "/budget", name: "Budget" }
  ];
  let adminOptions = [
    { id: 0, subPath: "/userManagement", name: "User Manangement" },
    { id: 1, subPath: "/entities", name: "Group Function/Local Market" },
    { id: 2, subPath: "/onboarding", name: "OnBoarding" },
    { id: 3, subPath: "/projectManagement", name: "Project Management" }
  ];
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const paddingText = {
    padding: "0"
  };

  function handleMenuItemClick(event, i, url) {
    event.preventDefault();
    setSelectedIndex(i);
    props.history.push(url);
    setOpen(true);
  }

  const [selectedAdminIndex, setSelectedAdminIndex] = React.useState(0);

  function handleAdminMenuItemClick(event, i, url) {
    event.preventDefault();
    setSelectedAdminIndex(i);
    props.history.push(url);
    setOpenAdmin(true);
  }
  function setNestedOpen() {
    setOpenAdmin(false);
    setOpen(false);
  }
  var links = (
    <div>
      <List className={classes.list}>
        {routes.map((prop, key) => {
          var activePro = " ";
          var listItemClasses;
          if (prop.path === "/adminlllllll") {
            activePro = classes.activePro + " ";
            listItemClasses = classNames({
              [" " + classes[color]]: true
            });
          } else {
            listItemClasses = classNames({
              [" " + classes[color]]: activeRoute(prop.layout + prop.path)
            });
          }
          const whiteFontClasses = classNames({
            [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
          });
          return (
            <div key={key}>
              <NavLink
                to={prop.layout + prop.path}
                className={activePro + classes.item}
                activeClassName="active"
                key={key}
              >
                {prop.name === "Setup" && (
                  <ListItem
                    button
                    className={classes.itemLink + listItemClasses + activePro}
                    onClick={handleAdminClick}
                    style={hello}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive
                        }
                      )}
                      disableTypography={true}
                      style={paddingText}
                    />
                    {openAdmin ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                )}
                {prop.name === "Cost Management" ? (
                  <ListItem
                    button
                    className={classes.itemLink + listItemClasses + activePro}
                    onClick={handleClick}
                    style={hello}
                  >
                    {typeof prop.icon === "string" ? (
                      <Icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive
                          }
                        )}
                      >
                        {prop.icon}
                      </Icon>
                    ) : (
                      <prop.icon
                        className={classNames(
                          classes.itemIcon,
                          whiteFontClasses,
                          {
                            [classes.itemIconRTL]: props.rtlActive
                          }
                        )}
                      />
                    )}

                    <ListItemText
                      primary={props.rtlActive ? prop.rtlName : prop.name}
                      className={classNames(
                        classes.itemText,
                        whiteFontClasses,
                        {
                          [classes.itemTextRTL]: props.rtlActive
                        }
                      )}
                      disableTypography={true}
                      style={paddingText}
                    />
                    {open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                ) : (
                  prop.name !== "Setup" && (
                    <ListItem
                      button
                      className={classes.itemLink + listItemClasses}
                      onClick={() => setNestedOpen()}
                    >
                      {typeof prop.icon === "string" ? (
                        <Icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive
                            }
                          )}
                        >
                          {prop.icon}
                        </Icon>
                      ) : (
                        <prop.icon
                          className={classNames(
                            classes.itemIcon,
                            whiteFontClasses,
                            {
                              [classes.itemIconRTL]: props.rtlActive
                            }
                          )}
                        />
                      )}
                      <ListItemText
                        primary={props.rtlActive ? prop.rtlName : prop.name}
                        className={classNames(
                          classes.itemText,
                          whiteFontClasses,
                          {
                            [classes.itemTextRTL]: props.rtlActive
                          }
                        )}
                        disableTypography={true}
                      />
                    </ListItem>
                  )
                )}
              </NavLink>
              {prop.name === "Cost Management" ? (
                <Collapse in={open} timeout="auto" unmountOnExit>
                  {options.map((option, i) => (
                    <ListItemLink
                      href={prop.layout + prop.path + option.subPath}
                      key={option.id}
                      selected={option.id === selectedIndex}
                      onClick={event =>
                        handleMenuItemClick(
                          event,
                          option.id,
                          prop.layout + prop.path + option.subPath
                        )
                      }
                      className={
                        selectedIndex === i
                          ? "sidenavActive"
                          : "sidenavInactive"
                      }
                      activeClassName={selectedIndex === i ? "active" : ""}
                    >
                      <Icon style={{ color: "white" }}>chevron_right</Icon>
                      <ListItemText primary={option.name} className="nithya" />
                    </ListItemLink>
                  ))}
                </Collapse>
              ) : (
                ""
              )}
              {prop.name === "Setup" ? (
                <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                  {adminOptions.map((option, i) => (
                    <ListItemLink
                      href={prop.layout + prop.path + option.subPath}
                      key={option.id}
                      selected={option.id === selectedAdminIndex}
                      onClick={event =>
                        handleAdminMenuItemClick(
                          event,
                          option.id,
                          prop.layout + prop.path + option.subPath
                        )
                      }
                      className={
                        selectedAdminIndex === i
                          ? "sidenavActive"
                          : "sidenavInactive"
                      }
                      activeClassName={selectedAdminIndex === i ? "active" : ""}
                    >
                      <Icon style={{ color: "white" }}>chevron_right</Icon>
                      <ListItemText primary={option.name} className="nithya" />
                    </ListItemLink>
                  ))}
                </Collapse>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </List>
      {props.entities && <MainTreeDropDown style={TreeStyle} entities={props.entities} />}
      {/*<ButtonDropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        style={DropDownStyle}
      >
        <DropdownToggle caret color="info">
          Text
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Header</DropdownItem>
          <DropdownItem disabled>Action</DropdownItem>
          <DropdownItem>Another Action</DropdownItem>
          <DropdownItem divider />
          <DropdownItem>Another Action</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>*/}
    </div>
  );
  var brand = (
    <div className={classes.logo} style={{ padding: "7px 15px" }}>
      <a
        href="/"
        style={bold}
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
      >
        <div className={classes.logoImage}>
          <img
            src={logo}
            alt="logo"
            className={classes.img}
            style={logoStyle}
          />
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <AdminNavbarLinks />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>{links}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
