import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button, Table } from "reactstrap";
import { TreeSelect } from "antd";
import { connect } from "react-redux";
import { capitalize } from "lodash";
import ls from "local-storage";
import { NewEntityServices } from "../../../services";
import { bindActionCreators } from "redux";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Checkbox } from "antd";
import { filter } from "lodash";

const styling = {
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "40px",
  marginBottom: "40px",
  textAlign: "center"
};

class AssignedEntitiy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: undefined,
      entities: undefined,
      userid: ls.get("customerId"),
      token: ls.get("idToken"),
      classes: props,
      allChecked: false,
      checkList: [],
      list: props.newEntities
        ? props.newEntities.accounts.map(list => ({
            id: list.Name,
            name: list.Name,
            number: list.cloudAccountId,
            isChecked: false
          }))
        : [],
      Loading: true,
      AssignedId: props.AssignId
    };
    console.log("proopopo", props);
    this.toggleAssignModel = this.toggleAssignModel.bind(this);
    this.handleAddSubmit = this.handleAddSubmit.bind(this);
    this.handleUnassignSubmit = this.handleUnassignSubmit.bind(this);
  }
  onChange = value => {
    this.setState({ entities: value });
  };
  /*onSelect = value => {
    console.log("Select:", getPath(value));
  };*/
  componentDidMount() {
    this.props.actions.newEntities.findAssignedCloudAccountEntities(
      this.state.token,
      this.state.userid,
      this.state.AssignedId
    );
  }
  handleChange = e => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    console.log("checkec", itemName);
    this.setState(prevState => {
      let { list, allChecked, checkList } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        list = list.map(item => ({ ...item, isChecked: checked }));
        checkList = filter(list, "isChecked");
      } else {
        list = list.map(item =>
          item.name === itemName ? { ...item, isChecked: checked } : item
        );
        allChecked = list.every(item => item.isChecked);
        checkList = filter(list, "isChecked");
      }
      //console.log("list", checkList);
      return { list, allChecked, checkList };
    });
  };

  renderList = () => {
    return this.state.list.map(item => (
      <tr key={item.id}>
        <th scope="row">
          {" "}
          <input
            type="checkbox"
            name={item.name}
            value={item.name}
            checked={item.isChecked}
            onChange={this.handleChange}
          />
        </th>
        <td>{item.name}</td>
        <td>{item.number}</td>
      </tr>
    ));
  };
  handleAddSubmit() {
    var getAccounts = this.state.checkList.map(a =>
      this.props.newEntities.accounts.find(ent => ent.Name === a.name)
    );
    //console.log("getent", getAccounts);
    const urlobj = {
      entityIds: this.state.entities,
      cloudAccounts: getAccounts,
      unassign: false
    };
    this.props.actions.newEntities.FindUpdatedAssignedAccounts(
      this.state.token,
      this.state.userid,
      urlobj
    );
    //console.log("urlobj", urlobj);
  }
  handleUnassignSubmit() {
    var getAccounts = this.state.checkList.map(a =>
      this.props.newEntities.accounts.find(ent => ent.Name === a.name)
    );
    //console.log("getent", getAccounts);
    const urlobj = {
      entityIds: this.state.entities,
      cloudAccounts: getAccounts,
      unassign: true
    };
    this.props.actions.newEntities.FindUpdatedAssignedAccounts(
      this.state.token,
      this.state.userid,
      urlobj
    );
    //console.log("urlobj", urlobj);
  }
  componentWillReceiveProps(nextProps) {
    //console.log("nextProps", nextProps);
    this.setState({
      list:
        nextProps.newEntities &&
        nextProps.newEntities.accounts.map(list => ({
          id: list.Name,
          name: list.Name,
          number: list.cloudAccountId,
          isChecked: false
        }))
    });
    if (nextProps.updateAssigned) {
      this.props.actions.newEntities.findAssignedCloudAccountEntities(
        this.state.token,
        this.state.userid,
        this.state.AssignedId
      );
      this.setState({ checkList: [] });
    } else if (nextProps.newEntities) {
      this.setState({ entities: [] });
    }
    if (nextProps.newEntities && nextProps.newEntities.accounts) {
      this.setState({ Loading: false });
    }
  }
  toggleAssignModel() {
    this.props.helloOne(false);
  }
  render() {
    console.log("this.state.entities", this.state.entities);
    return (
      <div>
        {this.state.Loading ? (
          <div style={styling}>
            <CircularProgress
              className={this.state.classes.progress}
              color="secondary"
            />
          </div>
        ) : (
          <Form>
            {this.state.list && this.state.list.length > 0 ? (
              <div>
                <div className="antCheckboxEntity">
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th className="mw-10">
                          {" "}
                          <input
                            type="checkbox"
                            name="checkAll"
                            checked={this.state.allChecked}
                            onChange={this.handleChange}
                          />
                        </th>
                        <th>Project Id</th>
                        <th>Project Number</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderList()}</tbody>
                  </Table>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <div className="form-group">
                      <label className="mr-2"> Select Entity</label>
                      <TreeSelect
                        showSearch
                        style={{ width: 300 }}
                        value={
                          this.state.entities
                            ? this.state.entities.map(a => a)
                            : undefined
                        }
                        dropdownStyle={{ maxHeight: 150, overflow: "auto" }}
                        placeholder="Please select"
                        treeData={this.props.entities.map(a => ({
                          id: a.Id,
                          pId: a.Parent,
                          value: a.Id,
                          title: capitalize(a.Name)
                        }))}
                        treeDataSimpleMode={true}
                        allowClear
                        multiple
                        treeDefaultExpandAll
                        onChange={a => this.onChange(a, this.props.entities)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <Button
                      color="primary"
                      className="w-100"
                      onClick={this.handleAddSubmit}
                    >
                      Update
                    </Button>
                  </div>
                  <div className="col">
                    <Button
                      color="warning"
                      className="w-100"
                      onClick={this.handleUnassignSubmit}
                    >
                      UnAssign
                    </Button>
                  </div>
                  <div className="col">
                    <Button
                      color="secondary"
                      onClick={this.toggleAssignModel}
                      className="w-100"
                    >
                      Close
                    </Button>{" "}
                  </div>
                  <div className="col" />
                </div>
              </div>
            ) : (
              <div>No accounts found</div>
            )}
          </Form>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    entities: state.entities.entityList,
    newEntities: state.newEntity.newEntityAssignedList,
    updateAssigned: state.newEntity.updateAssigned
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      newEntities: bindActionCreators(NewEntityServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignedEntitiy);
