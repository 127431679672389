import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Switch, Route } from "react-router-dom";
import Admin from "../layouts/Admin.jsx";
import AdminAzure from "../layouts/AdminAzure.jsx";
import AdminGCP from "../layouts/AdminGCP";
import AdminOracle from "../layouts/AdminOracle";
import AdminConverged from "../layouts/AdminConverged";
import "../assets/css/material-dashboard-react.css";
import queryString from "query-string";
import { bindActionCreators } from "redux";
import { selectId } from "../actions/tokenId";
//import { UserProfileActions } from "../actions";
import { HashRouter } from "react-router-dom";
import { userProfileServices } from "../services";
import ls from "local-storage";
class App extends Component {
  constructor() {
    super();
    this.state = {
      idToken: "",
      profile: {}
    };
    const env = process.env.NODE_ENV;

    console.log("env", env);
    console.log("env var - process.env", process.env);
    console.log("env var - server.env", process.env.environment);
  }
  componentWillMount() {
    ls.get("idToken") &&
      this.setState({
        idToken: ls.get("idToken")
      });
    console.log("stateggg", this.state.idToken);
    var f = window.location.href.indexOf("id_token=");
    if (f > 0) {
      console.log("from first");
      this.getParameterByName();
    }
  }
  componentDidMount(id_token) {
    /* var f = window.location.href.indexOf("id_token=");
    if (f) {
      this.getParameterByName();
    } else {
      this.setState({ idToken: "testing" });
    }*/
    // this.getParameterByName(id_token);
    console.log("eeee", this.state.idToken);
    var f = window.location.href.indexOf("id_token=");
    if (f > 0) {
      console.log("from first one");
      this.props.selectId(this.state.idToken);
      this.props.apiActions.findUserProfile(this.state.idToken);
    } else if (!ls.get("idToken")) {
      console.log("from second");
      this.getParameterByName();
    } else {
      id_token = ls.get("idToken");
      console.log("using token from local storage");
      this.props.selectId(id_token);
      this.props.apiActions.findUserProfile(id_token);
      //this.props.history.push("/");
    }
    //this.props.selectId(id_token);

    console.log("tokenfrom mount", id_token);
  }
  componentDidUpdate() {
    if (this.props.userProfile.userProfile) {
      localStorage.setItem(
        "customerId",
        JSON.stringify(this.props.userProfile.userProfile.customerId)
      );
      localStorage.setItem(
        "entity",
        JSON.stringify(this.props.userProfile.userProfile.entity)
      );
    }
    console.log("id", ls.get("customerId"));
  }
  getParameterByName(url) {
    if (!url) url = window.location.href;
    var t = url.split("#");
    var y = queryString.parse(t[1]);
    console.log("idtoken", y.id_token);
    this.setState({ idToken: y.id_token });
    localStorage.setItem("idToken", JSON.stringify(y.id_token));
    localStorage.setItem("orgAzure", "");
    localStorage.setItem("org", "");
    this.props.history.push("/#/aws");
    return y.id_token;
  }

  /*componentWillUpdate(nextProps, nextState) {
    localStorage.setItem("idToken", JSON.stringify(nextState.idToken));
    console.log("vvvvvv", nextState.idToken);
  }*/

  render() {
    console.log("userProfile:", this.props.userProfile);
    console.log("stateggghhh", this.state.idToken);
    if (!this.state.idToken) {
      return <h1>Access Denied</h1>;
    } else {
      return (
        <div className="wrapper">
          <div className="main-content mx-auto page-content">
            <HashRouter basename="/">
              <Switch>
                <Route exact path="/" component={Admin} />
                <Route exact path="/dashboard" component={Admin} />
                <Route exact path="/aws" component={Admin} />
                <Route exact path="/azure" component={AdminAzure} />
                <Route exact path="/gcp" component={AdminGCP} />
                <Route
                  exact
                  path="/azure/assetManagement"
                  component={AdminAzure}
                />
                <Route exact path="/gcp/assetManagement" component={AdminGCP} />
                <Route exact path="/aws/assetManagement" component={Admin} />
                <Route exact path="/azure/dashboard" component={AdminAzure} />

                <Route
                  exact
                  path="/azure/costManagement"
                  component={AdminAzure}
                />

                <Route
                  exact
                  path="/azure/securityCompilance"
                  component={AdminAzure}
                />
                <Route
                  exact
                  path="/azure/serviceMonitoring"
                  component={AdminAzure}
                />

                <Route
                  exact
                  path="/azure/costOptimisation"
                  component={AdminAzure}
                />

                <Route exact path="/azure/admin" component={AdminAzure} />
                <Route exact path="/azure/setup" component={AdminAzure} />
                <Route exact path="/aws/costData" component={Admin} />
                <Route exact path="/aws/dashboard" component={Admin} />
                <Route exact path="/aws/costManagement" component={Admin} />
                <Route exact path="/aws/securityCompilance" component={Admin} />
                <Route exact path="/aws/serviceMonitoring" component={Admin} />
                <Route exact path="/aws/costOptimisation" component={Admin} />
                <Route exact path="/aws/admin" component={Admin} />
                <Route exact path="/aws/setup" component={Admin} />
                <Route
                  exact
                  path="/aws/setup/userManagement"
                  component={Admin}
                />
                <Route exact path="/aws/setup/entities" component={Admin} />
                <Route exact path="/aws/setup/onboarding" component={Admin} />
                <Route
                  exact
                  path="/aws/setup/projectManagement"
                  component={Admin}
                />
                <Route
                  exact
                  path="/aws/costManagement/costData"
                  component={Admin}
                />
                <Route
                  exact
                  path="/aws/costManagement/budget"
                  component={Admin}
                />
                <Route
                  exact
                  path="/aws/costManagement/cm_sub_3"
                  component={Admin}
                />
                <Route
                  exact
                  path="/azure/costManagement/costData"
                  component={AdminAzure}
                />
                <Route
                  exact
                  path="/azure/costManagement/budget"
                  component={AdminAzure}
                />
                <Route
                  exact
                  path="/azure/costManagement/cm_sub_3"
                  component={AdminAzure}
                />
                <Route
                  exact
                  path="/azure/setup/userManagement"
                  component={AdminAzure}
                />
                <Route
                  exact
                  path="/azure/setup/entities"
                  component={AdminAzure}
                />
                <Route
                  exact
                  path="/azure/setup/onboarding"
                  component={AdminAzure}
                />
                <Route exact path="/gcp/costData" component={AdminGCP} />
                <Route exact path="/gcp/dashboard" component={AdminGCP} />
                <Route exact path="/gcp/costManagement" component={AdminGCP} />
                <Route
                  exact
                  path="/gcp/costManagement/costData"
                  component={AdminGCP}
                />
                <Route
                  exact
                  path="/gcp/costManagement/budget"
                  component={AdminGCP}
                />
                <Route
                  exact
                  path="/gcp/costManagement/cm_sub_3"
                  component={AdminGCP}
                />
                <Route
                  exact
                  path="/gcp/securityCompilance"
                  component={AdminGCP}
                />
                <Route
                  exact
                  path="/gcp/serviceMonitoring"
                  component={AdminGCP}
                />
                <Route
                  exact
                  path="/gcp/costOptimisation"
                  component={AdminGCP}
                />
                <Route exact path="/gcp/admin" component={AdminGCP} />
                <Route exact path="/gcp/setup" component={AdminGCP} />
                <Route
                  exact
                  path="/gcp/setup/userManagement"
                  component={AdminGCP}
                />
                <Route exact path="/gcp/setup/entities" component={AdminGCP} />
                <Route
                  exact
                  path="/gcp/setup/onboarding"
                  component={AdminGCP}
                />
                <Route
                  exact
                  path="/gcp/setup/onboarding/projectList"
                  component={AdminGCP}
                />
                <Route exact path="/oracle" component={AdminOracle} />
                <Route
                  exact
                  path="/oracle/securityCompilance"
                  component={AdminOracle}
                />
                <Route exact path="/oracle/dashboard" component={AdminOracle} />
                <Route exact path="/converged" component={AdminConverged} />
                <Route
                  exact
                  path="/converged/securityCompilance"
                  component={AdminConverged}
                />
                <Route
                  exact
                  path="/converged/dashboard"
                  component={AdminConverged}
                />
              </Switch>
            </HashRouter>
          </div>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    id: state.id_token,
    userProfile: state.userProfile
  };
}
function matchDispatchToProps(dispatch) {
  return {
    selectId: bindActionCreators(selectId, dispatch),
    apiActions: bindActionCreators(userProfileServices, dispatch)
  };
}

// We don't want to return the plain UserList (component) anymore, we want to return the smart Container
//      > UserList is now aware of state and actions
export default withRouter(
  connect(
    mapStateToProps,
    matchDispatchToProps
  )(App)
);
