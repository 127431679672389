/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "../components/Navbars/Navbar.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import DashboardPage from "../containers/Dashboard/Dashboard.jsx";
import dashboardRoutesGCP from "../routesGCP";
import dashboardStyle from "../assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import Typography from "../containers/Typography/Typography.jsx";
import TableList from "../containers/TableList/TableList.jsx";
import NotificationsPage from "../containers/Notifications/Notifications.jsx";
import image from "../assets/img/sidebar-2.jpg";
import logo from "../assets/img/mca-logo.png";
import CostDraft from "../containers/TableData/CostDraft.jsx";
import GCPTableMap from "../containers/GCP/gcpTableData";
import UserManagementTable from "../containers/UserManagement/UserManagementTable.jsx";
import UserProfile from "../containers/UserProfile/UserProfile.jsx";
import Icons from "../containers/Icons/Icons.jsx";
import FlowChart from "../containers/Flowchart/FlowChart.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import EmptyComponent from "../containers/emptyComponent/empty";
import BudgetGcp from "../containers/GCP/Budgets/budgetGcp";
import OnBoardingGcp from "../containers/GCP/OnBoarding/onBoardingMain";
import GcpProjectList from "../containers/GCP/OnBoarding/gcpProjectList";
import BudgetAws from "../containers/AWS/Budgets/budgetAws";
import { entityServices } from "../services";
import { bindActionCreators } from "redux";
import ls from "local-storage";
import EntityMain from "../containers/AWS/Entities/MainEntity";

const divStyle = {
  marginTop: '50px',
};

const paths = [
  {
    pathname: '#/',
    component: GCPTableMap
},{
      pathname: '#/gcp',
      component: GCPTableMap
  },
  {
      pathname: "#/gcp/dashboard",
      component: EmptyComponent
    },
    {
      pathname: "#/gcp/costManagement",
      component: GCPTableMap
    },
    {
      pathname: "#/gcp/costManagement/costData",
      component: GCPTableMap
    },
    {
      pathname: "#/gcp/costManagement/budget",
      component: BudgetAws
    },
    {
        pathname: "#/gcp/assetManagement",
        component: EmptyComponent
      },
    {
      pathname: "#/gcp/securityCompilance",
      component: EmptyComponent
    },
    {
      pathname: "#/gcp/serviceMonitoring",
      component: EmptyComponent
    },
    {
      pathname: "#/gcp/costOptimisation",
      component: EmptyComponent
    },
    {
      pathname: "#/gcp/admin",
      component: EmptyComponent
    },    
    {
      pathname: "#/gcp/setup/userManagement",
      component: UserManagementTable
    },
    {
      pathname: "#/gcp/setup/entities",
      component: EntityMain
    },
    {
      pathname: "#/gcp/setup",
      component: EmptyComponent
    },
    {
      pathname: "#/gcp/setup/onboarding",
      component: OnBoardingGcp
    },
    {
      pathname: "#/gcp/setup/onboarding/projectList",
      component: GcpProjectList
    }


];
class AdminGCP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false,
      userid: ls.get("customerId"),
      token: ls.get("idToken"),
      isLoading: true 
    };
    this.props.actions.entities.findEntities(
      this.state.token,
      this.state.userid
    );
  }
  
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log("next", nextProps);
    if (nextProps.entities) {
      this.setState({ isLoading: false });
      var initId = nextProps.entities.find(ent => ent.Id === ls.get("entity"));
      console.log("inital entity id", initId);
      localStorage.setItem("InitEntityId", initId.Id);
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }
  render() {
    const { classes, ...rest } = this.props;
   
    return (
      <div className={classes.wrapper}>
         <Navbar
            routes={dashboardRoutesGCP}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          /> 
          {this.state.isLoading === false &&
        <Sidebar
          routes={dashboardRoutesGCP}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          entities={this.props.entities}
          {...rest}
        />
          }
        <div className={classes.mainPanel} ref="mainPanel">
         
            {paths.map((a, i) => 
                        location.hash === (a.pathname) &&  (<div key={i} style={divStyle}> <a.component /></div>)
                        )}
        
        </div>
        
      </div>
    );
  }
}

AdminGCP.propTypes = {
  classes: PropTypes.object.isRequired
};
function mapStateToProps(state) {
  return {
    entities: state.entities.entityList
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      entities: bindActionCreators(entityServices, dispatch)
    }
  };
}
export default withRouter(connect(mapStateToProps,
  mapDispatchToProps)(withStyles(dashboardStyle)(AdminGCP)));

