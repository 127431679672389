import { createStore, applyMiddleware, compose } from "redux";
import apiAuthInjector from "./apiAuthInjector";
import { apiMiddleware } from "redux-api-middleware";
import thunk from "redux-thunk";
import reducers from "./reducers/index";
import { createLogger } from "redux-logger";

/*export default function(initalState = {}) {
  var store = createStore(
    initalState,
    applyMiddleware(
      reducers(),
      //apiAuthInjector,
      apiMiddleware,
      thunk,
      createLogger()
    )
  );

  return store;
}*/
const initialState = {};
export default () => {
  // const store = createStore(rootReducer, applyMiddleware(apiMiddleware, thunkMiddleware));
  const createStoreWithMiddleware = compose(
    applyMiddleware(apiAuthInjector, apiMiddleware, thunk, createLogger())
  )(createStore);

  const store = createStoreWithMiddleware(
    reducers(),
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  return store;
};
