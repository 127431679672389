import { RSAA } from "redux-api-middleware";
import {
  ListUnassignedAccounts,
  GetEntityCloudAccounts,
  PutUnassignedAccounts,
  PutAssignedAccounts
} from "../constants/api-endpoints";
import { ActionTypes } from "../constants";
//var token =
//  "eyJraWQiOiJVVmtCWFZBTkNhUXorYTVvTDBcL2pXSmxUemVaU1FqQkNNU1JROGJuSHlxbz0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiRVFKeDFXbXl5NndSVDVNd0ZjMmh2ZyIsInN1YiI6IjM4ZDVlMDBhLTcyNGMtNDIyOS05MTNjLTFlODVhYTRlM2UzOCIsImNvZ25pdG86Z3JvdXBzIjpbInN1cGVyLWFkbWluIl0sImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC51cy1lYXN0LTEuYW1hem9uYXdzLmNvbVwvdXMtZWFzdC0xXzlTcnNOZVdRayIsImNvZ25pdG86dXNlcm5hbWUiOiJzYXFsYWluLnNoYWJiaXJAdm9kYWZvbmUuY29tIiwiYXVkIjoiM3Fyb2Y4NzkxdmdjOXZlYTJvb2oyMnVhZGciLCJldmVudF9pZCI6ImQzNmQ4ZDYxLTAxZTQtNGQzZS1iYzIzLTFlNjVkYmQ0ZDcxYSIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNTcwMDQ3ODc5LCJleHAiOjE1NzAwNTE0NzksImlhdCI6MTU3MDA0Nzg3OSwiZW50aXR5IjoiNjcyZjUzNTgtYjdmZi0xOGQxLTFmOTctYzlmMmZmNjBhNmM4IiwiZW1haWwiOiJzYXFsYWluLnNoYWJiaXJAdm9kYWZvbmUuY29tIiwiY3VzdG9tZXIiOiI2NzJmNTM1OC1iN2ZmLTE4ZDEtMWY5Ny1jOWYyZmY2MGE2YzgiLCJncm91cCI6InN1cGVyLWFkbWluIn0.M-SpKpQVRVTpQ-pqp15GI9Cbb4Ik9Z-XE_xKNvtIoUdsyGUSlmji2lLvejpMNYU9p5mFfht9Ni4NoU_eF2oHOUYMCzsvjfWj-3ZfPR60iUbSTmN-NztYrDLh6I7TNkwVFXktbeJlVlAqHZBE2pIH82PgPn2ixFXzosyD-bcVh_bZMmS-5XnvAW-NxU5BHRkVe1LVbz8-pVUAynuDfVEXp9rbmpaiG3NeyhlrW5NxbZBxN11TEWJxEYm-P882pjifGJhLH58_hWifFl8Fs5lzB-lQnBKzOlqEHNKNO0Rt3y4LqJOsIsXZexXhBFoBFHKD517QFa9sGnOu7shVca-asQ";
//var id = "672f5358-b7ff-18d1-1f97-c9f2ff60a6c8";
export const findListUnassignedEntities = (userToken, customer, csp) => ({
  [RSAA]: {
    endpoint: `${ListUnassignedAccounts}?csp=${csp}`,
    method: "GET",
    headers: {
      Authorization: `${userToken}`,
      customer: `${customer}`
    },
    types: [
      ActionTypes.LIST_UNASSIGNED_ACCOUNTS_REQUEST,
      ActionTypes.LIST_UNASSIGNED_ACCOUNTS_RESPONSE,
      ActionTypes.LIST_UNASSIGNED_ACCOUNTS_FAILURE
    ]
  }
});
export const findAssignedCloudAccountEntities = (
  userToken,
  customer,
  entitiyId
) => ({
  [RSAA]: {
    endpoint: `${GetEntityCloudAccounts}?entity-id=${entitiyId}`,
    method: "GET",
    headers: {
      Authorization: `${userToken}`,
      customer: `${customer}`
    },
    types: [
      ActionTypes.GET_ENTITY_CLOUD_ACCOUNTS_REQUEST,
      ActionTypes.GET_ENTITY_CLOUD_ACCOUNTS_RESPONSE,
      ActionTypes.GET_ENTITY_CLOUD_ACCOUNTS_FAILURE
    ]
  }
});
export const FindUpdatedUnassignedAccounts = (userToken, customer, body) => ({
  [RSAA]: {
    endpoint: `${PutUnassignedAccounts}`,
    method: "PUT",
    headers: {
      Authorization: `${userToken}`,
      customer: `${customer}`
    },
    body: `${JSON.stringify(body)}`,
    types: [
      ActionTypes.UPDATE_UNASSIGNED_ACCOUNTS_REQUEST,
      ActionTypes.UPDATE_UNASSIGNED_ACCOUNTS_RESPONSE,
      ActionTypes.UPDATE_UNASSIGNED_ACCOUNTS_FAILURE
    ]
  }
});
export const FindUpdatedAssignedAccounts = (userToken, customer, body) => ({
  [RSAA]: {
    endpoint: `${PutAssignedAccounts}`,
    method: "PUT",
    headers: {
      Authorization: `${userToken}`,
      customer: `${customer}`
    },
    body: `${JSON.stringify(body)}`,
    types: [
      ActionTypes.UPDATE_ASSIGNED_ACCOUNTS_REQUEST,
      ActionTypes.UPDATE_ASSIGNED_ACCOUNTS_RESPONSE,
      ActionTypes.UPDATE_ASSIGNED_ACCOUNTS_FAILURE
    ]
  }
});
export default { findListUnassignedEntities };
