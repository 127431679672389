import React, { Component } from "react";
import BudgetAwsLanding from "./awsBudgetLanding";
import { BudgetAwsServices, entityServices } from "../../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import ls from "local-storage";
const styling = {
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "40px",
  marginBottom: "40px",
  textAlign: "center"
};

class BudgetAws extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      classes: props,
      userid: ls.get("customerId"),
      token: ls.get("idToken")
    };
    this.props.actions.awsBudgetList.findAwsBudget(
      this.state.token,
      this.state.userid
    );
    this.props.actions.entities.findEntities(
      this.state.token,
      this.state.userid
    );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.awsBudgetList && nextProps.entities) {
      this.setState({ isLoading: false });
    }
  }
  render() {
    const { isLoading, classes } = this.state;
    return (
      <div>
        {isLoading === true && (
          <GridContainer>
            <GridItem xs={0.5} sm={0.5} md={0.5} />
            <GridItem xs={11} sm={11} md={11}>
              <Card>
                <CardHeader title="Budget" />
                <CardBody>
                  <div style={styling}>
                    <CircularProgress
                      className={classes.progress}
                      color="secondary"
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={0.5} sm={0.5} md={0.5} />
          </GridContainer>
        )}
        {isLoading === false && (
          <GridContainer>
            <GridItem xs={0.5} sm={0.5} md={0.5} />
            <GridItem xs={11} sm={11} md={11}>
              <Card>
                <CardHeader title="Budget" />
                <CardBody>
                  <BudgetAwsLanding
                    awsBudgetList={this.props.awsBudgetList}
                    entities={this.props.entities}
                  />
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={0.5} sm={0.5} md={0.5} />
          </GridContainer>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  console.log("state", state);
  return {
    awsBudgetList: state.awsBudget.awsBudgetData,
    entities: state.entities.entityList
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      awsBudgetList: bindActionCreators(BudgetAwsServices, dispatch),
      entities: bindActionCreators(entityServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetAws);
