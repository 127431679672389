import { createAction } from "redux-actions";
import { ActionTypes } from "../constants";

export const authUsersFindRequest = createAction(
  ActionTypes.AUTH_USERS_FIND_REQUEST
);
export const authUsersFindResponse = createAction(
  ActionTypes.AUTH_USERS_FIND_RESPONSE
);
export const authUsersFindFailure = createAction(
  ActionTypes.AUTH_USERS_FIND_FAILURE
);
export const userDeleteRequest = createAction(ActionTypes.USER_DELETE_REQUEST);
export const userDeleteResponse = createAction(
  ActionTypes.USER_DELETE_RESPONSE
);
export const userDeleteFailure = createAction(ActionTypes.USER_DELETE_FAILURE);
export const userEditRequest = createAction(ActionTypes.USER_EDIT_REQUEST);
export const userEditResponse = createAction(ActionTypes.USER_EDIT_RESPONSE);
export const userEditFailure = createAction(ActionTypes.USER_EDIT_FAILURE);
export const userAddRequest = createAction(ActionTypes.USER_ADD_REQUEST);
export const userAddResponse = createAction(ActionTypes.USER_ADD_RESPONSE);
export const userAddFailure = createAction(ActionTypes.USER_ADD_FAILURE);
export default {};
