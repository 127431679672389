import { RSAA } from "redux-api-middleware";
import {
  GCPOnboardlistProjects,
  gcpOnboardOrgList,
  gcpOnboardProList
} from "../constants/api-endpoints";
import { ActionTypes } from "../constants";

export const findGcpOnboardListProjectsData = () => ({
  [RSAA]: {
    endpoint: `${GCPOnboardlistProjects}?owner=saqlain.shabbir%40vodafone.com`,
    method: "GET",
    types: [
      ActionTypes.GCP_ONBOARD_LIST_PROJECTS_REQUEST,
      ActionTypes.GCP_ONBOARD_LIST_PROJECTS_RESPONSE,
      ActionTypes.GCP_ONBOARD_LIST_PROJECTS_FAILURE
    ]
  }
});
export const findGcpOnboardListProData = (userToken, customer) => ({
  [RSAA]: {
    endpoint: `${gcpOnboardProList}?org=vodafone.com`,
    method: "GET",
    /*headers: {
      Authorization: `${userToken}`,
      customer: `${customer}`
    },*/
    types: [
      ActionTypes.GCP_ONBOARD_LIST_PRO_REQUEST,
      ActionTypes.GCP_ONBOARD_LIST_PRO_RESPONSE,
      ActionTypes.GCP_ONBOARD_LIST_PRO_FAILURE
    ]
  }
});
export const findGcpOnboardListOrgData = (userToken, customer) => ({
  [RSAA]: {
    endpoint: `${gcpOnboardOrgList}`,
    method: "GET",
    headers: {
      Authorization: `${userToken}`,
      customer: `${customer}`
    },
    types: [
      ActionTypes.GCP_ONBOARD_LIST_ORG_REQUEST,
      ActionTypes.GCP_ONBOARD_LIST_ORG_RESPONSE,
      ActionTypes.GCP_ONBOARD_LIST_ORG_FAILURE
    ]
  }
});
export const findGcpUpdateEntitiy = url => ({
  [RSAA]: {
    endpoint: `${gcpOnboardProList}`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: `${JSON.stringify(url)}`,
    types: [
      ActionTypes.GCP_ONBOARD_UPDATE_ENTITY_REQUEST,
      ActionTypes.GCP_ONBOARD_UPDATE_ENTITY_RESPONSE,
      ActionTypes.GCP_ONBOARD_UPDATE_ENTITY_FAILURE
    ]
  }
});
