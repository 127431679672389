import { createAction } from "redux-actions";
import { ActionTypes } from "../constants";

export const BudgetGetRequest = createAction(ActionTypes.BUDGET_GET_REQUEST);
export const BudgetGetResponse = createAction(ActionTypes.BUDGET_GET_RESPONSE);
export const BudgetGetFailure = createAction(ActionTypes.BUDGET_GET_FAILURE);

export const BudgetAddRequest = createAction(ActionTypes.BUDGET_ADD_REQUEST);
export const BudgetAddResponse = createAction(ActionTypes.BUDGET_ADD_RESPONSE);
export const BudgetAddFailure = createAction(ActionTypes.BUDGET_ADD_FAILURE);

export const BudgetDeleteRequest = createAction(
  ActionTypes.BUDGET_DELETE_REQUEST
);
export const BudgetDeleteResponse = createAction(
  ActionTypes.BUDGET_DELETE_RESPONSE
);
export const BudgetDeleteFailure = createAction(
  ActionTypes.BUDGET_DELETE_FAILURE
);

export const BudgetUpdateRequest = createAction(
  ActionTypes.BUDGET_UPDATE_REQUEST
);
export const BudgetUpdateResponse = createAction(
  ActionTypes.BUDGET_UPDATE_RESPONSE
);
export const BudgetUpdateFailure = createAction(
  ActionTypes.BUDGET_UPDATE_FAILURE
);

export const BudgetFilterRequest = createAction(
  ActionTypes.BUDGET_FILTER_REQUEST
);
export const BudgetFilterResponse = createAction(
  ActionTypes.BUDGET_FILTER_RESPONSE
);
export const BudgetFilterFailure = createAction(
  ActionTypes.BUDGET_FILTER_FAILURE
);

export default {};
