import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { AwsChartActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [AwsChartActions.awsChartRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [AwsChartActions.awsChartResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        awsChartData: action.payload
      }),
    [AwsChartActions.awsChartFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
