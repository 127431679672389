import * as ActionTypes from "../constants/action-types";

export default (state = [], action) => {
  switch (action.type) {
    case ActionTypes.CREATE_NEW_CONTACT:
      return [...state, Object.assign({}, action.contact)];
    case ActionTypes.REMOVE_CONTACT:
      return state.filter((data, i) => i !== action.id);
    default:
      return state;
  }
};
