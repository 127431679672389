import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { NewEntityActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [NewEntityActions.listUnassignedRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [NewEntityActions.listUnassignedResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        newEntity: action.payload
      }),
    [NewEntityActions.listUnassignedFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [NewEntityActions.getAssignedCloudAccountsRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [NewEntityActions.getAssignedCloudAccountsResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        newEntityAssignedList: action.payload
      }),
    [NewEntityActions.getAssignedCloudAccountsFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [NewEntityActions.updateUnassignedAccountsRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [NewEntityActions.updateUnassignedAccountsResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        updateUnassigned: action.payload
      }),
    [NewEntityActions.updateUnassignedAccountsFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [NewEntityActions.updateAssignedAccountsRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [NewEntityActions.updateAssignedAccountsResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        updateAssigned: action.payload
      }),
    [NewEntityActions.updateAssignedAccountsFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
