import React, { Component } from "react";
import TreeDropdown from "./treeDropdown";
import { capitalize } from "lodash";

class MainTreeDropDown extends Component {
  render() {
    return (
      <div>
        <TreeDropdown
          entities={this.props.entities}
          treeData={this.props.entities.map(a => ({
            id: a.Id,
            pId: a.Parent,
            value: a.Name,
            title: capitalize(a.Name)
          }))}
        />
      </div>
    );
  }
}
export default MainTreeDropDown;
