/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "../components/Navbars/Navbar.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import DashboardPage from "../containers/Dashboard/Dashboard.jsx";
import convergedRoutes from "../routesConverged";
import dashboardStyle from "../assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import Typography from "../containers/Typography/Typography.jsx";
import TableList from "../containers/TableList/TableList.jsx";
import NotificationsPage from "../containers/Notifications/Notifications.jsx";
import image from "../assets/img/sidebar-2.jpg";
import logo from "../assets/img/mca-logo.png";
import CostDraft from "../containers/TableData/CostDraft.jsx";
import TableMap from "../containers/TableData/TableDataMapping";
import UserManagementTable from "../containers/UserManagement/UserManagementTable.jsx";
import UserProfile from "../containers/UserProfile/UserProfile.jsx";
import Icons from "../containers/Icons/Icons.jsx";
import FlowChart from "../containers/Flowchart/FlowChart.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import EmptyComponent from "../containers/emptyComponent/empty";

const divStyle = {
  marginTop: '50px',
};

const paths = [
    {
      pathname: '#/converged',
      component: EmptyComponent
  },
  {
      pathname: "#/converged/dashboard",
      component: EmptyComponent
    },
    {
      pathname: "#/converged/securityCompilance",
      component: EmptyComponent
    }


];
class AdminConverged extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false
    };
  }
  
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }
  render() {
    const { classes, ...rest } = this.props;
   
    return (
      <div className={classes.wrapper}>
         <Navbar
            routes={convergedRoutes}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          /> 
        <Sidebar
          routes={convergedRoutes}
          logo={logo}
          image={this.state.image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color={this.state.color}
          {...rest}
        />
        <div className={classes.mainPanel} ref="mainPanel">
         
            {paths.map((a, i) => 
                        location.hash === (a.pathname) &&  (<div key={i} style={divStyle}> <a.component /></div>)
                        )}
        
        </div>
        
      </div>
    );
  }
}

AdminConverged.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(connect()(withStyles(dashboardStyle)(AdminConverged)));
