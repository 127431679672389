const env = process.env.REACT_APP_STAGE;
console.log("env", env);

var env_api;

if (env === "prod") {
  env_api = ".";
} else {
  env_api = `${env}.`;
}
export const authProfileEndpoint = `https://api.${env_api}mcloudadmin.com/auth/profile`;
export const authUsersEndpoint = `https://api.${env_api}mcloudadmin.com/auth/users`;
export const costManagementTable = `https://api.${env_api}mcloudadmin.com/cm/report?tabledata=monthlycost`;
export const azureCostManagementTable = `https://api.${env_api}mcloudadmin.com/azure-cm/usage?table=true`;
export const azureChartEndpoint = `https://api.${env_api}mcloudadmin.com/azure-cm/usage`;
export const awsChartEndpoint = `https://api.${env_api}mcloudadmin.com/cm/report?chartdata=monthlycost&month=4&year=2019`;
export const userEndpoint = `https://api.${env_api}mcloudadmin.com/auth/user`;
export const GCPChartEndpoint = `https://api.${env_api}mcloudadmin.com/gcp-cm/report?chartdata=monthlycost`;
export const GCPTableEndpoint = `https://api.${env_api}mcloudadmin.com/gcp-cm/report?tabledata=monthlycost`;
export const entityEndpoint = `https://api.${env_api}mcloudadmin.com/entity`;
export const BudgetEndpoint = `https://api.dev.mcloudadmin.com/budgets`;
export const GCPOnboardlistProjects = `https://us-central1-destination-project-transfer.cloudfunctions.net/projects-read1`;
export const awsOnboarding = `https://api.${env_api}mcloudadmin.com/grant-access`;
//GCP Onboarding
export const gcpOnboardOrgList = `https://api.${env_api}mcloudadmin.com/gcp-onboarding/organizations`;
//export const gcpOnboardProList = `https://api.${env_api}mcloudadmin.com/gcp-onboarding/projects`;
export const gcpOnboardProList = `https://us-central1-vf-grp-pcs-dev-mca.cloudfunctions.net/prj`;
/* New entity End points */
export const ListUnassignedAccounts = `https://api.${env_api}mcloudadmin.com/cloud-account/list-unassigned-cloud-accounts`;
export const GetEntityCloudAccounts = `https://api.${env_api}mcloudadmin.com/cloud-account/get-entity-cloud-accounts`;
export const PutUnassignedAccounts = `https://api.${env_api}mcloudadmin.com/cloud-account/assign-cloud-account`;
export const PutAssignedAccounts = `https://api.${env_api}mcloudadmin.com/cloud-account/update-assigned-cloud-account`;

export const gcpProjectschedulerList = `https://us-central1-vf-grp-pcs-dev-mca.cloudfunctions.net/Projects_New1`;

export default {
  authProfileEndpoint,
  authUsersEndpoint,
  costManagementTable,
  azureCostManagementTable,
  azureChartEndpoint,
  awsChartEndpoint,
  userEndpoint,
  GCPChartEndpoint,
  GCPTableEndpoint,
  entityEndpoint,
  BudgetEndpoint,
  GCPOnboardlistProjects,
  awsOnboarding,
  ListUnassignedAccounts,
  GetEntityCloudAccounts,
  PutUnassignedAccounts,
  PutAssignedAccounts,
  gcpProjectschedulerList
};
