// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
//import Person from "@material-ui/icons/Person";
import Assessment from "@material-ui/icons/Assessment";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Settings from "@material-ui/icons/Settings";
//import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import assignment from "@material-ui/icons/Assignment";
//import assignment from "@material-ui/core/assignment";
// core components/views for Admin layout
import DashboardAzure from "./containers/Dashboard/DashboardAzure.js";
import CostManagementAzure from "./containers/UserProfile/CostManagementAzure.jsx";
//import UserProfile from "./views/UserProfile/UserProfile.jsx";
//import TableList from "./containers/TableList/TableList.jsx";
import Typography from "./containers/Typography/Typography.jsx";
import Icons from "./containers/Icons/Icons.jsx";
//import Maps from "./views/Maps/Maps.jsx";
//import NotificationsPage from "views/Notifications/Notifications.jsx";
import UpgradeToPro from "./containers/UpgradeToPro/UpgradeToPro.jsx";
// core components/views for RTL layout
//import RTLPage from "views/RTLPage/RTLPage.jsx";

const helloRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardAzure,
    layout: "/azure"
  },
  {
    path: "/costManagement",
    subPathOne: "/cm_sub_1",
    subPathTwo: "/cm_sub_2",
    subPathThree: "/cm_sub_3",
    name: "Cost Management",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Assessment,
    component: CostManagementAzure,
    subcomponent: Typography,
    layout: "/azure"
  },
  {
    path: "/assetManagement",
    name: "Asset Management",
    rtlName: "ملف تعريفي للمستخدم",
    icon: assignment,
    component: Typography,
    layout: "/azure"
  },
  {
    path: "/securityCompilance",
    name: "Security & Compilance",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/azure"
  },
  {
    path: "/serviceMonitoring",
    name: "Service Monitoring",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/azure"
  },
  {
    path: "/costOptimisation",
    name: "Cost Optimisation",
    rtlName: "خرائط",
    icon: LocationOn,
    component: UpgradeToPro,
    layout: "/azure"
  },
  {
    path: "/setup",
    name: "Setup",
    rtlName: "لوحة القيادة",
    icon: Settings,
    component: UpgradeToPro,
    layout: "/azure"
  }
  /*{
    path: "/admin",
    name: "Admin",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/azure"
  }
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin"
  },
  {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl"
  }*/
];

export default helloRoutes;
