import React, { Component } from "react";
import GridItem from "../../components/Grid/GridItem.jsx";
import GridContainer from "../../components/Grid/GridContainer.jsx";
import Table from "../../components/Table/Table.jsx";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../components/Card/CardBody.jsx";
class EmptyComponent extends Component {
  render() {
    return (
      <React.Fragment>
        <GridContainer>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
          <GridItem xs={11} sm={11} md={11}>
            <Card>
              <CardHeader title="Coming Soon!!" />
            </Card>
          </GridItem>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
        </GridContainer>
      </React.Fragment>
    );
  }
}

export default EmptyComponent;
