import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import Icon from "@material-ui/core/Icon";
const styling = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    padding: "0",
    marginTop: "10px",
    marginLeft: "15px"
  },
  icon: {
    margin: "16px"
  }
};
const buttonStyle = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: "16px"
  },
  color: "#dc3545"
};
const paddingButton = {
  padding: "0"
};
class BudgetGcpLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      addModal: false
    };
    this.toggleAddModal = this.toggleAddModal.bind(this);
  }
  toggleAddModal() {
    this.setState(prevState => ({
      addModal: !prevState.addModal
    }));
  }
  render() {
    const { classes } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
          <GridItem xs={11} sm={11} md={11}>
            <Card>
              <CardHeader title="Budget GCP" />
              <CardBody>
                <Button
                  color="primary"
                  className="mb-5"
                  onClick={this.toggleAddModal}
                >
                  Create Budget
                </Button>
                <Table size="sm" hover>
                  <thead className="bg-light">
                    <tr>
                      <th>#</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Username</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">1</th>
                      <td>Mark</td>
                      <td>Otto</td>
                      <td>@mdo</td>
                      <td>
                        <Button
                          style={paddingButton}
                          color="link"
                          onClick={this.toggleEditModal}
                        >
                          <Icon style={styling}>create</Icon>
                        </Button>
                        <Button
                          style={paddingButton}
                          color="link"
                          onClick={this.DeleteBudget}
                        >
                          <Icon style={buttonStyle}>clear</Icon>
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">2</th>
                      <td>Jacob</td>
                      <td>Thornton</td>
                      <td>@fat</td>
                      <td>
                        <Button
                          style={paddingButton}
                          color="link"
                          onClick={this.toggleEditModal}
                        >
                          <Icon style={styling}>create</Icon>
                        </Button>
                        <Button
                          style={paddingButton}
                          color="link"
                          onClick={this.DeleteBudget}
                        >
                          <Icon style={buttonStyle}>clear</Icon>
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">3</th>
                      <td>Larry</td>
                      <td>the Bird</td>
                      <td>@twitter</td>
                      <td>
                        <Button
                          style={paddingButton}
                          color="link"
                          onClick={this.toggleEditModal}
                        >
                          <Icon style={styling}>create</Icon>
                        </Button>
                        <Button
                          style={paddingButton}
                          color="link"
                          onClick={this.DeleteBudget}
                        >
                          <Icon style={buttonStyle}>clear</Icon>
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Modal
                  isOpen={this.state.addModal}
                  className={this.props.className}
                >
                  <ModalHeader toggle={this.toggleAddModal}>
                    Add Budget
                  </ModalHeader>
                  <ModalBody>
                    <form>
                      <div className="form-group">
                        <label>Name</label>
                        <input type="text" className="form-control" value="" />
                      </div>
                      <div className="form-group">
                        <label>Group</label>
                        <select className="form-control" value="">
                          <option value="">...select...</option>
                          <option value="admin">value1</option>
                          <option value="standard">value2</option>
                          <option value="super-admin">value3</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label>Entity</label>
                        <select className="form-control" value="">
                          <option value="">...select...</option>
                          <option value="acme">value 1</option>
                        </select>
                      </div>
                      <div className="row mt-4">
                        <div className="col" />
                        <div className="col">
                          <Button
                            color="secondary"
                            onClick={this.toggleAddModal}
                            className="w-100"
                          >
                            Cancel
                          </Button>{" "}
                        </div>
                        <div className="col">
                          <Button color="primary" className="w-100">
                            Save
                          </Button>
                        </div>
                        <div className="col" />
                      </div>
                    </form>
                  </ModalBody>
                  <ModalFooter />
                </Modal>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={0.5} sm={0.5} md={0.5} />
        </GridContainer>
      </div>
    );
  }
}

export default BudgetGcpLanding;
