import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { GcpOnboardActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [GcpOnboardActions.gcpOnboardListProjectsRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [GcpOnboardActions.gcpOnboardListProjectsResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        gcpOnboardData: action.payload
      }),
    [GcpOnboardActions.gcpOnboardListProjectsFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [GcpOnboardActions.gcpOnboardListProRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [GcpOnboardActions.gcpOnboardListProResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        gcpOnboardProData: action.payload
      }),
    [GcpOnboardActions.gcpOnboardListProFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [GcpOnboardActions.gcpOnboardListOrgRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [GcpOnboardActions.gcpOnboardListOrgResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        gcpOnboardOrgData: action.payload
      }),
    [GcpOnboardActions.gcpOnboardListOrgFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [GcpOnboardActions.gcpOnboardAssignEntityRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [GcpOnboardActions.gcpOnboardAssignEntityResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        gcpOnboardEntityData: action.payload
      }),
    [GcpOnboardActions.gcpOnboardAssignEntityFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
