import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
//import Dashboard from "../../views/Dashboard/Dashboard.jsx";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import Button from "../CustomButtons/Button.jsx";
import MenuIcon from "@material-ui/icons/Menu";
import headerStyle from "../../assets/jss/material-dashboard-react/components/headerStyle.jsx";

function Header({ ...props }) {
  const { classes, color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  });
  const toolstyle = {
    background: "#e60000",
    color: "#FFF",
    fontWeight: "bold !important",
    height: "30px !important"
  };
  const appbar = {
    paddingTop: "0px",
    paddingBottom: "0px",
    background: "#e60000"
  };
  const abc = {
    fontWeight: "bold",
    fontSize: "14px",
    width: "150px",
    padding: "0",
    position: "relative",
    top: "-10",
    paddingBottom: "6px"
  };
  const centralised = {
    textAlign: "center",
    height: "30px"
  };

  let options = [
    { id: 0, path: "/aws", name: "AWS" },
    { id: 1, path: "/azure", name: "AZURE" },
    { id: 2, path: "/gcp", name: "GCP" },
    { id: 3, path: "/oracle", name: "ORACLE" },
    { id: 4, path: "/converged", name: "CONVERGED" }
  ];
  return (
    <AppBar style={appbar} className={classes.appBar + appBarClasses}>
      <Toolbar style={toolstyle} className={classes.container}>
      <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
      <MenuIcon />
    </IconButton>
        <div className={classes.flex} style={centralised}>
          {/* Here we create navbar brand, based on route name */}
          {options.map((option, i) => (
            <Button
              key={i}
              style={abc}
              onClick={() => props.history.push(option.path)}
              color="transparent"
              className={
                props.location.pathname.indexOf(option.path) >= 0
                  ? "navActive"
                  : ""
              }
            >
              {option.name}
            </Button>
          ))}
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks />
        </Hidden>
        <Hidden mdUp implementation="css">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(headerStyle)(Header);
