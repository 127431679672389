import { combineReducers } from "redux";
import selectedIdReducer from "./jswToken";
import userProfileReducer from "./userProfile";
import contacts from "./ContactReducer";
import costTableReducer from "./costTableReducer";
import authUsersReducer from "./authUsersReducer";
import azureCostTableReducer from "./azureCostTableReducer";
import azureChartReducer from "./azureChartReducer";
import awsChartReducer from "./awsChartReducer";
import gcpCostReportReducer from "./gcpCostReportReducer";
import entityReducer from "./entityReducer";
import budgetAwsReducer from "./budgetAwsReducer";
import GcpOnboardReducer from "./GCPOnboardReducer";
import AwsOnboardReducer from "./awsOnboardReducer";
import NewEntityReducer from "./newEntitiyReducer";
import NewProjectReducer from "./newProjectSchedulerReducer";
export default function(reducers) {
  return combineReducers({
    idToken: selectedIdReducer,
    userProfile: userProfileReducer,
    contacts: contacts,
    costData: costTableReducer,
    authUsers: authUsersReducer,
    azureData: azureCostTableReducer,
    azureChart: azureChartReducer,
    awsChart: awsChartReducer,
    gcpCostReportData: gcpCostReportReducer,
    entities: entityReducer,
    awsBudget: budgetAwsReducer,
    gcpOnboard: GcpOnboardReducer,
    awsOnboard: AwsOnboardReducer,
    newEntity: NewEntityReducer,
    newProject: NewProjectReducer,
    ...reducers
  });
}
