import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { AzureChartActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [AzureChartActions.azureChartRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [AzureChartActions.azureChartResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        azureChartData: action.payload
      }),
    [AzureChartActions.azureChartFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
