const env = process.env.REACT_APP_STAGE;
const timestamp = 1568279283;

const data = [
  {
    region: "eu-west-1",
    label: "Ireland",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://eu-west-1-${env}-mca-onboarding.s3-eu-west-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://eu-west-1-${env}-mca-onboarding.s3-eu-west-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-west-1-${env}-mca-onboarding.s3-eu-west-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://eu-west-1-${env}-mca-onboarding.s3-eu-west-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://eu-west-1-${env}-mca-onboarding.s3-eu-west-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-west-1-${env}-mca-onboarding.s3-eu-west-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-west-1-${env}-mca-onboarding.s3-eu-west-1.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://eu-west-1-${env}-mca-onboarding.s3-eu-west-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://eu-west-1-${env}-mca-onboarding.s3-eu-west-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://eu-west-1-${env}-mca-onboarding.s3-eu-west-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "eu-west-2",
    label: "London",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://eu-west-2-${env}-mca-onboarding.s3-eu-west-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://eu-west-2-${env}-mca-onboarding.s3-eu-west-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-west-2-${env}-mca-onboarding.s3-eu-west-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://eu-west-2-${env}-mca-onboarding.s3-eu-west-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://eu-west-2-${env}-mca-onboarding.s3-eu-west-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-west-2-${env}-mca-onboarding.s3-eu-west-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-west-2-${env}-mca-onboarding.s3-eu-west-2.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://eu-west-2-${env}-mca-onboarding.s3-eu-west-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://eu-west-2-${env}-mca-onboarding.s3-eu-west-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://eu-west-2-${env}-mca-onboarding.s3-eu-west-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "eu-west-3",
    label: "Paris",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://eu-west-3-${env}-mca-onboarding.s3-eu-west-3.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://eu-west-3-${env}-mca-onboarding.s3-eu-west-3.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-west-3-${env}-mca-onboarding.s3-eu-west-3.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://eu-west-3-${env}-mca-onboarding.s3-eu-west-3.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://eu-west-3-${env}-mca-onboarding.s3-eu-west-3.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-west-3-${env}-mca-onboarding.s3-eu-west-3.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-west-3-${env}-mca-onboarding.s3-eu-west-3.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://eu-west-3-${env}-mca-onboarding.s3-eu-west-3.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://eu-west-3-${env}-mca-onboarding.s3-eu-west-3.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://eu-west-3-${env}-mca-onboarding.s3-eu-west-3.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "ap-south-1",
    label: "Mumbai",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://ap-south-1-${env}-mca-onboarding.s3-ap-south-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://ap-south-1-${env}-mca-onboarding.s3-ap-south-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-south-1-${env}-mca-onboarding.s3-ap-south-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://ap-south-1-${env}-mca-onboarding.s3-ap-south-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://ap-south-1-${env}-mca-onboarding.s3-ap-south-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-south-1-${env}-mca-onboarding.s3-ap-south-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-south-1-${env}-mca-onboarding.s3-ap-south-1.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://ap-south-1-${env}-mca-onboarding.s3-ap-south-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://ap-south-1-${env}-mca-onboarding.s3-ap-south-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://ap-south-1-${env}-mca-onboarding.s3-ap-south-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "ap-northeast-1",
    label: "Tokyo",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://ap-northeast-1-${env}-mca-onboarding.s3-ap-northeast-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://ap-northeast-1-${env}-mca-onboarding.s3-ap-northeast-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-northeast-1-${env}-mca-onboarding.s3-ap-northeast-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://ap-northeast-1-${env}-mca-onboarding.s3-ap-northeast-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://ap-northeast-1-${env}-mca-onboarding.s3-ap-northeast-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-northeast-1-${env}-mca-onboarding.s3-ap-northeast-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-northeast-1-${env}-mca-onboarding.s3-ap-northeast-1.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://ap-northeast-1-${env}-mca-onboarding.s3-ap-northeast-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://ap-northeast-1-${env}-mca-onboarding.s3-ap-northeast-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://ap-northeast-1-${env}-mca-onboarding.s3-ap-northeast-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "ap-northeast-2",
    label: "Seoul",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://ap-northeast-2-${env}-mca-onboarding.s3-ap-northeast-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://ap-northeast-2-${env}-mca-onboarding.s3-ap-northeast-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-northeast-2-${env}-mca-onboarding.s3-ap-northeast-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://ap-northeast-2-${env}-mca-onboarding.s3-ap-northeast-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://ap-northeast-2-${env}-mca-onboarding.s3-ap-northeast-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-northeast-2-${env}-mca-onboarding.s3-ap-northeast-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-northeast-2-${env}-mca-onboarding.s3-ap-northeast-2.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://ap-northeast-2-${env}-mca-onboarding.s3-ap-northeast-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://ap-northeast-2-${env}-mca-onboarding.s3-ap-northeast-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://ap-northeast-2-${env}-mca-onboarding.s3-ap-northeast-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "ap-northeast-3",
    label: "Osaka-Local",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://ap-northeast-3-${env}-mca-onboarding.s3-ap-northeast-3.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://ap-northeast-3-${env}-mca-onboarding.s3-ap-northeast-3.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-northeast-3-${env}-mca-onboarding.s3-ap-northeast-3.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://ap-northeast-3-${env}-mca-onboarding.s3-ap-northeast-3.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://ap-northeast-3-${env}-mca-onboarding.s3-ap-northeast-3.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-northeast-3-${env}-mca-onboarding.s3-ap-northeast-3.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-northeast-3-${env}-mca-onboarding.s3-ap-northeast-3.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://ap-northeast-3-${env}-mca-onboarding.s3-ap-northeast-3.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://ap-northeast-3-${env}-mca-onboarding.s3-ap-northeast-3.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://ap-northeast-3-${env}-mca-onboarding.s3-ap-northeast-3.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "sa-east-1",
    label: "Sao Paulo",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://sa-east-1-${env}-mca-onboarding.s3-sa-east-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://sa-east-1-${env}-mca-onboarding.s3-sa-east-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://sa-east-1-${env}-mca-onboarding.s3-sa-east-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://sa-east-1-${env}-mca-onboarding.s3-sa-east-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://sa-east-1-${env}-mca-onboarding.s3-sa-east-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://sa-east-1-${env}-mca-onboarding.s3-sa-east-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://sa-east-1-${env}-mca-onboarding.s3-sa-east-1.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://sa-east-1-${env}-mca-onboarding.s3-sa-east-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://sa-east-1-${env}-mca-onboarding.s3-sa-east-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://sa-east-1-${env}-mca-onboarding.s3-sa-east-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "ca-central-1",
    label: "Central",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://ca-central-1-${env}-mca-onboarding.s3-ca-central-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://ca-central-1-${env}-mca-onboarding.s3-ca-central-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ca-central-1-${env}-mca-onboarding.s3-ca-central-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://ca-central-1-${env}-mca-onboarding.s3-ca-central-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://ca-central-1-${env}-mca-onboarding.s3-ca-central-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ca-central-1-${env}-mca-onboarding.s3-ca-central-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ca-central-1-${env}-mca-onboarding.s3-ca-central-1.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://ca-central-1-${env}-mca-onboarding.s3-ca-central-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://ca-central-1-${env}-mca-onboarding.s3-ca-central-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://ca-central-1-${env}-mca-onboarding.s3-ca-central-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "ap-southeast—1",
    label: "Singapore",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://ap-southeast-1-${env}-mca-onboarding.s3-ap-southeast-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://ap-southeast-1-${env}-mca-onboarding.s3-ap-southeast-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-southeast-1-${env}-mca-onboarding.s3-ap-southeast-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://ap-southeast-1-${env}-mca-onboarding.s3-ap-southeast-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://ap-southeast-1-${env}-mca-onboarding.s3-ap-southeast-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-southeast-1-${env}-mca-onboarding.s3-ap-southeast-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-southeast-1-${env}-mca-onboarding.s3-ap-southeast-1.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://ap-southeast-1-${env}-mca-onboarding.s3-ap-southeast-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://ap-southeast-1-${env}-mca-onboarding.s3-ap-southeast-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://ap-southeast-1-${env}-mca-onboarding.s3-ap-southeast-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "ap-southeast—2",
    label: "Sydney",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://ap-southeast-2-${env}-mca-onboarding.s3-ap-southeast-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://ap-southeast-2-${env}-mca-onboarding.s3-ap-southeast-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-southeast-2-${env}-mca-onboarding.s3-ap-southeast-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://ap-southeast-2-${env}-mca-onboarding.s3-ap-southeast-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://ap-southeast-2-${env}-mca-onboarding.s3-ap-southeast-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-southeast-2-${env}-mca-onboarding.s3-ap-southeast-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://ap-southeast-2-${env}-mca-onboarding.s3-ap-southeast-2.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://ap-southeast-2-${env}-mca-onboarding.s3-ap-southeast-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://ap-southeast-2-${env}-mca-onboarding.s3-ap-southeast-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://ap-southeast-2-${env}-mca-onboarding.s3-ap-southeast-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "eu-central-1",
    label: "Frankfurt",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://eu-central-1-${env}-mca-onboarding.s3-eu-central-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://eu-central-1-${env}-mca-onboarding.s3-eu-central-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-central-1-${env}-mca-onboarding.s3-eu-central-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://eu-central-1-${env}-mca-onboarding.s3-eu-central-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://eu-central-1-${env}-mca-onboarding.s3-eu-central-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-central-1-${env}-mca-onboarding.s3-eu-central-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://eu-central-1-${env}-mca-onboarding.s3-eu-central-1.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://eu-central-1-${env}-mca-onboarding.s3-eu-central-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://eu-central-1-${env}-mca-onboarding.s3-eu-central-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://eu-central-1-${env}-mca-onboarding.s3-eu-central-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "us-east-1",
    label: "N. Virginia",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://us-east-1-${env}-mca-onboarding.s3-us-east-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://us-east-1-${env}-mca-onboarding.s3-us-east-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-east-1-${env}-mca-onboarding.s3-us-east-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://us-east-1-${env}-mca-onboarding.s3-us-east-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://us-east-1-${env}-mca-onboarding.s3-us-east-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-east-1-${env}-mca-onboarding.s3-us-east-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-east-1-${env}-mca-onboarding.s3-us-east-1.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://us-east-1-${env}-mca-onboarding.s3-us-east-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://us-east-1-${env}-mca-onboarding.s3-us-east-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://us-east-1-${env}-mca-onboarding.s3-us-east-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "us-east-2",
    label: "Ohio",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://us-east-2-${env}-mca-onboarding.s3-us-east-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://us-east-2-${env}-mca-onboarding.s3-us-east-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-east-2-${env}-mca-onboarding.s3-us-east-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://us-east--${env}-mca-onboarding.s3-us-east-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://us-east-2-${env}-mca-onboarding.s3-us-east-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-east-2-${env}-mca-onboarding.s3-us-east-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-east-2-${env}-mca-onboarding.s3-us-east-2.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://us-east-2-${env}-mca-onboarding.s3-us-east-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://us-east-2-${env}-mca-onboarding.s3-us-east-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://us-east-2-${env}-mca-onboarding.s3-us-east-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "us-west-1",
    label: "N. California",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://us-west-1-${env}-mca-onboarding.s3-us-west-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://us-west-1-${env}-mca-onboarding.s3-us-west-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-west-1-${env}-mca-onboarding.s3-us-west-1.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://us-east--${env}-mca-onboarding.s3-us-west-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://us-west-1-${env}-mca-onboarding.s3-us-west-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-west-1-${env}-mca-onboarding.s3-us-west-1.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-west-1-${env}-mca-onboarding.s3-us-west-1.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://us-west-1-${env}-mca-onboarding.s3-us-west-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://us-west-1-${env}-mca-onboarding.s3-us-west-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://us-west-1-${env}-mca-onboarding.s3-us-west-1.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  },
  {
    region: "us-west-2",
    label: "Oregon",
    Step1: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the \'mca-setup-tenants.yml\' file in all of your child accounts.<a href="https://us-west-2-${env}-mca-onboarding.s3-us-west-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml">https://us-west-2-${env}-mca-onboarding.s3-us-west-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account (which is also your logging account). <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-west-2-${env}-mca-onboarding.s3-us-west-2.amazonaws.com/${timestamp}/templates/enterprise/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step2: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-tenants.yml’ file in all of your child accounts, including your billing account (which is one of your child accounts).<a href="https://us-east--${env}-mca-onboarding.s3-us-west-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml">https://us-west-2-${env}-mca-onboarding.s3-us-west-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-tenants.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-west-2-${env}-mca-onboarding.s3-us-west-2.amazonaws.com/${timestamp}/templates/enterprise/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step3: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your account.<a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL=https://us-west-2-${env}-mca-onboarding.s3-us-west-2.amazonaws.com/${timestamp}/templates/single/same/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`,
    Step4: `<div><ul><li><strong>Step 1:</strong> Enter the required parameters and deploy the ‘mca-setup-logging.yml’ file in your logging/billing account.<a href="https://us-west-2-${env}-mca-onboarding.s3-us-west-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml">https://us-west-2-${env}-mca-onboarding.s3-us-west-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-logging.yml</a></li><li><strong>Step 2:</strong> Enter the required parameters and deploy the ‘mca-setup-master.yml’ file in your master account. <a href="https://console.aws.amazon.com/cloudformation/home#/stacks/new?stackName=mca-master&amp;templateURL= https://us-west-2-${env}-mca-onboarding.s3-us-west-2.amazonaws.com/${timestamp}/templates/single/separate/mca-setup-master.yml"target="_blank"><img alt="Launch Stack" src="https://cdn.rawgit.com/buildkite/cloudformation-launch-stack-button-svg/master/launch-stack.svg"></a></li></ul></div><br />`
  }
];
export default data;
