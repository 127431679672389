import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Col, Row } from "reactstrap";
import { GcpOnboardServices } from "../../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ls from "local-storage";
import CircularProgress from "@material-ui/core/CircularProgress";
const styling = {
  margin: "auto",
  textAlign: "center"
};
class FourthPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      classes: props,
      userid: ls.get("customerId"),
      token: ls.get("idToken")
    };
  }
  componentDidMount() {
    this.props.actions.orgList.findGcpOnboardListOrgData(
      this.state.token,
      this.state.userid
    );
  }
  componentWillReceiveProps(nextProps) {
    if (!nextProps.orgList) {
      this.props.actions.orgList.findGcpOnboardListOrgData(
        this.state.token,
        this.state.userid
      );
    }
  }
  render() {
    const { classes } = this.state;
    if (!this.props.orgList) {
      return (
        <div style={styling}>
          <CircularProgress className={classes.progress} color="secondary" />
        </div>
      );
    } else {
      return (
        <div>
          <p>You are in Customer On-Boarding final Page.</p>
          {/*} <p>
          To come On-Board, please fill the form and follow the instructions
          below.
    </p>*/}
          <ul>
            {this.props.orgList.map(a => (
              <li key={a.id}>
                <Row>
                  <Col xs={2}>
                    <strong>Id:</strong> {a.id}
                  </Col>
                  <Col xs={2}>
                    <strong>Name:</strong> {a.name}
                  </Col>
                  <Col xs={8} />
                </Row>
              </li>
            ))}
          </ul>
        </div>
      );
    }
  }
}
function mapStateToProps(state) {
  return {
    orgList: state.gcpOnboard.gcpOnboardOrgData
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      orgList: bindActionCreators(GcpOnboardServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FourthPage);
