import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import { Steps, Button, message } from "antd";
import "antd/dist/antd.css";
import FirstPage from "./firstPage";
import SecondPage from "./secondPage";
import ThirdPage from "./thirdPage";
import { connect } from "react-redux";
const { Step } = Steps;

const steps = [
  {
    title: "First",
    content: <FirstPage />
  },
  {
    title: "Second",
    content: <SecondPage />
  },
  {
    title: "Last",
    content: <ThirdPage />
  }
];

class OnboardingAwsLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      current: 0
    };
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  render() {
    var abc = false;
    if (this.state.current === 1 && !this.props.awsOnboard) {
      abc = true;
    }
    const { classes, current } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
          <GridItem xs={11} sm={11} md={11}>
            <Card>
              <CardHeader title="OnBoarding AWS" />
              <CardBody>
                <Steps current={current}>
                  {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                  ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
                <div className="steps-action">
                  {current < steps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={() => this.next()}
                      disabled={abc}
                    >
                      Next
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      type="primary"
                      onClick={() => message.success("Processing complete!")}
                    >
                      Done
                    </Button>
                  )}
                  {current > 0 && (
                    <Button
                      style={{ marginLeft: 8 }}
                      onClick={() => this.prev()}
                    >
                      Previous
                    </Button>
                  )}
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={0.5} sm={0.5} md={0.5} />
        </GridContainer>
      </div>
    );
  }
}
function mapStateToProps(state) {
  console.log("State landing", state.awsOnboard.awsOnboardAccess);
  return {
    awsOnboard: state.awsOnboard.awsOnboardAccess
  };
}
export default connect(mapStateToProps)(OnboardingAwsLanding);
