// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
//import Person from "@material-ui/icons/Person";
import Assessment from "@material-ui/icons/Assessment";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Settings from "@material-ui/icons/Settings";
//import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import assignment from "@material-ui/icons/Assignment";
//import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import DashboardPage from "./containers/Dashboard/Dashboard.jsx";
import UserProfile from "./containers/UserProfile/UserProfile.jsx";
import TableList from "./containers/TableList/TableList.jsx";
import Typography from "./containers/Typography/Typography.jsx";
import Icons from "./containers/Icons/Icons.jsx";
import Maps from "./containers/Maps/Maps.jsx";
//import NotificationsPage from "views/Notifications/Notifications.jsx";
import UpgradeToPro from "./containers/UpgradeToPro/UpgradeToPro.jsx";
//import CostDraft from "./containers/TableData/CostDraft.jsx";
//import UserManagementTable from "./containers/UserManagement/UserManagementTable.jsx";
// core components/views for RTL layout
//import RTLPage from "views/RTLPage/RTLPage.jsx";

const dashboardRoutesGCP = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/gcp"
  },
  {
    path: "/costManagement",
    subPathOne: "/cm_sub_1",
    subPathTwo: "/cm_sub_2",
    subPathThree: "/cm_sub_3",
    name: "Cost Management",
    rtlName: "ملف تعريفي للمستخدم",
    icon: Assessment,
    component: UserProfile,
    subComponent: TableList,
    layout: "/gcp"
  },
  {
    path: "/assetManagement",
    name: "Asset Management",
    rtlName: "ملف تعريفي للمستخدم",
    icon: assignment,
    component: Typography,
    layout: "/gcp"
  },
  {
    path: "/securityCompilance",
    name: "Security & Compilance",
    rtlName: "طباعة",
    icon: LibraryBooks,
    component: Typography,
    layout: "/gcp"
  },
  {
    path: "/serviceMonitoring",
    name: "Service Monitoring",
    rtlName: "الرموز",
    icon: BubbleChart,
    component: Icons,
    layout: "/gcp"
  },
  {
    path: "/costOptimisation",
    name: "Cost Optimisation",
    rtlName: "خرائط",
    icon: LocationOn,
    component: Maps,
    layout: "/gcp"
  },
  {
    path: "/setup",
    name: "Setup",
    rtlName: "لوحة القيادة",
    icon: Settings,
    component: UpgradeToPro,
    layout: "/gcp"
  }
  /*{
    path: "/admin",
    name: "Admin",
    rtlName: "التطور للاحترافية",
    icon: Unarchive,
    component: UpgradeToPro,
    layout: "/gcp"
  }
  {
    path: "/notifications",
    name: "Notifications",
    rtlName: "إخطارات",
    icon: Notifications,
    component: NotificationsPage,
    layout: "/admin"
  },
  {
    path: "/rtl-page",
    name: "RTL Support",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Language,
    component: RTLPage,
    layout: "/rtl"
  }*/
];

export default dashboardRoutesGCP;
