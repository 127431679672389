import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import { Button } from "reactstrap";
//import { authProvider } from "./authProvider";

/*import * as Msal from "msal";
export const msalConfig = {
  authority: "https://login.microsoftonline.com/common",
  clientId: "0d583ed8-169f-49a3-9277-076b641ee4c0",
  scopes: ["https://management.core.windows.net//user_impersonation"]
};
export const msalInstance = new Msal.UserAgentApplication({
  auth: msalConfig
});
var applicationConfig = {
  // These default values get updated by the HTML inputs
  client_id: "0d583ed8-169f-49a3-9277-076b641ee4c0",
  //scopes: ['https://graph.microsoft.com/directory.accessasuser.all'],
  scopes: ["https://management.core.windows.net//user_impersonation"],
  authority: "https://login.microsoftonline.com/common"
};
var id_token_global = null;
var access_token_global = null;
var graph_access_token_global = null;
var global_TenantId = null;
var onboardjson = [];
*/
class OnBoardingAzureSignin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props
      //token: ""
      //authenticated: false,
      //renewIframe: false,
      //hasError: false,
      //errorMessage: null
    };
  }
  /*componentDidMount() {
    this.hello();
  }*/
  /*async hello() {
    //const login = await authProvider.login();
    const idToken = await authProvider.getIdToken();
    console.log("hello idtoken", idToken.idToken.rawIdToken);
    console.log("hello accessToken", authProvider.getAccessToken());*/
  //const aaa = authProvider.login().then(authProvider.getIdToken());
  //this.setState({
  // token: aaa
  // });
  //console.log("eeeeeeee", authProvider.getIdToken());
  //}
  /*componentWillMount() {
    // action to perform on authentication
    msalInstance.handleRedirectCallback(
      () => {
        // on success
        this.setState({
          authenticated: true
        });
      }
      /*(authErr: AuthError, accountState: string) => {
        // on fail
        console.log(authErr);

        this.setState({
          hasError: true,
          errorMessage: authErr.errorMessage
        });
      }*/
  /* );

    // if we are inside renewal callback (hash contains access token), do nothing
    if (msalInstance.isCallback(window.location.hash)) {
      this.setState({
        renewIframe: true
      });
      return;
    }

    // not logged in, perform full page redirect
    if (!msalInstance.getAccount()) {
      msalInstance
        .loginPopup(msalConfig.scopes)
        .then(this.getToken(msalConfig.scopes));
      return;
    } else {
      // logged in, set authenticated state and init pnpjs library
      this.setState({
        authenticated: true
      });
      console.log("loggedin");
      const token = this.getToken(msalConfig.scopes);
      this.token = token;
      console.log("token", token);
    }
  }
  async getToken(resourse) {
    //const request = {};
    console.log("resourse", resourse);
    try {
      const response = msalInstance
        .acquireTokenSilent(resourse)
        .then(function(id_token) {
          var user = msalInstance.getUser();

          if (user) {
            console.log("id_token", id_token);
          }
        });
      console.log("response", response);
      return response;
    } catch (error) {
      /*if (this.requiresInteraction(error.errorCode)) {
        this.authContext.acquireTokenRedirect(request);
      } else {
        throw error;
      }*/
  /*console.log("error", error);
    }
  }
  sign_in() {
    var userAgentApplication = new Msal.UserAgentApplication(
      applicationConfig.client_id,
      applicationConfig.authority,
      function(errorDes, token, error, tokenType, instance) {
        // this callback is called after loginRedirect OR acquireTokenRedirect. It's not used with loginPopup,  acquireTokenPopup.
        if (error) {
          console.log(error + ": " + errorDes);
        } else console.log("Token type = " + tokenType);
      }
    );
    console.log("hello sign in");
    console.log(
      "userAgentApplication",
      userAgentApplication.loginPopup(applicationConfig)
    );
    /*userAgentApplication.loginPopup(applicationConfig.scopes).then(
      function(id_token) {
        var user = userAgentApplication.getUser();

        if (user) {
          console.log("signed in successfully");
          console.log(id_token);
          id_token_global = id_token;

          // get an access token
          userAgentApplication
            .acquireTokenSilent(applicationConfig.scopes)
            .then(
              function(access_token) {
                console.log("Success acquiring access token");
                console.log(access_token);
                access_token_global = access_token;
              },
              function(error) {
                // interaction required
                if (error.indexOf("interaction_required" !== -1)) {
                  userAgentApplication
                    .acquireTokenPopup(applicationConfig.scopes)
                    .then(
                      function(access_token) {
                        console.log("Success acquiring access token");
                        console.log(access_token);
                        access_token_global = access_token;
                      },
                      function(error) {
                        console.log("Failure acquiring token: " + error);
                        document.getElementById(
                          "sign_in_text"
                        ).innerText = error;
                      }
                    );
                }
              }
            );
        } else {
          console.log("signed in failure");
        }
      },
      function(error) {
        console.log("error: " + error);
      }
    );*/
  //}

  render() {
    const { classes } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
          <GridItem xs={11} sm={11} md={11}>
            <Card>
              <CardHeader title="OnBoarding Azure" />
              <CardBody>
                <p>
                  Jerky jowl pork chop tongue, kielbasa shank venison. Capicola
                  shank pig ribeye leberkas filet mignon brisket beef kevin
                  tenderloin porchetta. Capicola fatback venison shank kielbasa,
                  drumstick ribeye landjaeger beef kevin tail meatball pastrami
                  prosciutto pancetta. Tail kevin spare ribs ground round ham
                  ham hock brisket shoulder. Corned beef tri-tip leberkas flank
                  sausage ham hock filet mignon beef ribs pancetta turkey. Bacon
                  ipsum dolor sit amet landjaeger sausage brisket, jerky
                  drumstick fatback boudin.
                </p>
                <div className="text-center">
                  {/*<Button color="primary" onClick={() => this.getToken()}>*/}
                  <Button color="primary">OnBoarding</Button>
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={0.5} sm={0.5} md={0.5} />
        </GridContainer>
      </div>
    );
  }
}

export default OnBoardingAzureSignin;
