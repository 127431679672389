import { RSAA } from "redux-api-middleware";
import { gcpProjectschedulerList } from "../constants/api-endpoints";
import { ActionTypes } from "../constants";

export const findNewGcpProjects = () => ({
  [RSAA]: {
    endpoint: `${gcpProjectschedulerList}`,
    method: "GET",
    types: [
      ActionTypes.GET_NEW_GCP_PROJECTS_REQUEST,
      ActionTypes.GET_NEW_GCP_PROJECTS_RESPONSE,
      ActionTypes.GET_NEW_GCP_PROJECTS_FAILURE
    ]
  }
});

export default { findNewGcpProjects };
