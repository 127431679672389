import React, { Component } from "react";
import { Table } from "reactstrap";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import { GcpOnboardServices, entityServices } from "../../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import ls from "local-storage";
import Icon from "@material-ui/core/Icon";
import {
  capitalize,
  compact,
  concat,
  flattenDeep,
  uniq,
  filter,
  has,
  includes
} from "lodash";
import { TreeSelect } from "antd";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Container
} from "reactstrap";
import { object } from "prop-types";
const styling = {
  margin: "auto",
  textAlign: "center"
};
const assign = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: "16px"
  },
  color: "#f39c12"
};
const view = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: "16px"
  },
  color: "#1aaa55"
};
const paddingButton = {
  padding: "0",
  verticalAlign: "top"
};
const projects = [
  {
    projectNumber: 123456789,
    projectId: "ABC-123",
    lifecycleState: "ACTIVE",
    name: "ABC-PROJECT",
    createTime: "2019-07-04T10:26:11.802Z",
    owners: [
      "user:firstname.lastname@vodafone.com",
      "user:firstname1.lastname1@vodafone.com"
    ],
    Entityname: "672f5358-b7ff-18d1-1f97-c9f2ff60a6c8"
  },
  {
    projectNumber: 123456790,
    projectId: "ABC-123",
    lifecycleState: "ACTIVE",
    name: "ABC-PROJECT",
    createTime: "2019-07-04T10:26:11.802Z",
    owners: [
      "user:firstname.lastname@vodafone.com",
      "user:firstname1.lastname1@vodafone.com"
    ],
    Entityname: "672f5358-b7ff-18d1-1f97-c9f2ff60a6c8"
  },
  {
    projectNumber: 123456791,
    projectId: "ABC-123",
    lifecycleState: "ACTIVE",
    name: "ABC-PROJECT",
    createTime: "2019-07-04T10:26:11.802Z",
    owners: [
      "user:firstname.lastname@vodafone.com",
      "user:firstname1.lastname1@vodafone.com"
    ],
    Entityname: "672f5358-b7ff-18d1-1f97-c9f2ff60a6c8"
  },
  {
    projectNumber: 123456792,
    projectId: "ABC-123",
    lifecycleState: "ACTIVE",
    name: "ABC-PROJECT",
    createTime: "2019-07-04T10:26:11.802Z",
    owners: [
      "user:firstname.lastname@vodafone.com",
      "user:firstname1.lastname1@vodafone.com"
    ],
    Entityname: "672f5358-b7ff-18d1-1f97-c9f2ff60a6c8"
  },
  {
    projectNumber: 123456760,
    projectId: "ABC-123",
    lifecycleState: "ACTIVE",
    name: "ABC-PROJECT",
    createTime: "2019-07-04T10:26:11.802Z",
    owners: [
      "user:firstname.lastname@vodafone.com",
      "user:firstname1.lastname1@vodafone.com"
    ],
    Entityname: "672f5358-b7ff-18d1-1f97-c9f2ff60a6c8"
  },
  {
    projectNumber: 123456761,
    projectId: "ABC-123",
    lifecycleState: "ACTIVE",
    name: "ABC-PROJECT",
    createTime: "2019-07-04T10:26:11.802Z",
    owners: [
      "user:firstname.lastname@vodafone.com",
      "user:firstname1.lastname1@vodafone.com"
    ],
    Entityname: "672f5358-b7ff-18d1-1f97-c9f2ff60a6c8"
  },
  {
    projectNumber: 123456762,
    projectId: "ABC-123",
    lifecycleState: "ACTIVE",
    name: "ABC-PROJECT",
    createTime: "2019-07-04T10:26:11.802Z",
    owners: [
      "user:firstname.lastname@vodafone.com",
      "user:firstname1.lastname1@vodafone.com"
    ],
    Entityname: "672f5358-b7ff-18d1-1f97-c9f2ff60a6c8"
  },
  {
    projectNumber: 123456763,
    projectId: "ABC-123",
    lifecycleState: "ACTIVE",
    name: "ABC-PROJECT",
    createTime: "2019-07-04T10:26:11.802Z",
    owners: [
      "user:firstname.lastname@vodafone.com",
      "user:firstname1.lastname1@vodafone.com"
    ],
    Entityname: "672f5358-b7ff-18d1-1f97-c9f2ff60a6c8"
  }
];
const dateFunction = date => new Date(date).toLocaleString();
class GcpProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      isLoading: true,
      classes: props,
      dropdownOpen: false,
      projectList: {},
      userid: ls.get("customerId"),
      token: ls.get("idToken"),
      uniEntity: [],
      projectNumber: "",
      projectId: "",
      lifecycleState: "",
      name: "",
      createTime: "",
      owners: [],
      Entityname: "",
      viewModal: false,
      labels: {},
      entityOpen: false
    };
    this.toggle = this.toggle.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.toggleEntityModel = this.toggleEntityModel.bind(this);
    //this.props.actions.projectsList.findGcpOnboardListProjectsData();
    this.closeOnSubmit = this.closeOnSubmit.bind(this);
    //this.filtering = this.filtering.bind(this);
    this.handleAssignSubmit = this.handleAssignSubmit.bind(this);
    this.props.actions.projectsList.findGcpOnboardListProData();
    this.props.actions.entities.findEntities(
      this.state.token,
      this.state.userid
    );
  }
  /*componentWillReceiveProps(nextProps) {
    if (nextProps.projectsList) {
      this.setState({ isLoading: false });
    }
  }*/
  componentDidMount() {
    this.props.actions.projectsList.findGcpOnboardListOrgData(
      this.state.token,
      this.state.userid
    );
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.proList) {
      this.setState({ projectList: nextProps.proList });
    }
    if (nextProps.gcpOnboardEntityData) {
      this.props.actions.projectsList.findGcpOnboardListProData();
    }
    if (nextProps.entities) {
      this.setState({ isLoading: false });
      if (nextProps.proList) {
        var compacting = compact(
          nextProps.proList.map(x =>
            x.Entityname ? x.Entityname.map(a => a) : ""
          )
        );
        var deepening = flattenDeep(compacting);
        const uniEntitiy = uniq(deepening).map(
          a => nextProps.entities.find(ent => ent.Id === a).Name
        );
        this.setState({ uniEntity: uniEntitiy });
      }
    }
  }
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  filtering(item) {
    var itemName = this.props.entities.find(ent => ent.Name === item).Id;
    var itemsList = this.props.proList.filter(
      ent => ent.Entityname && ent.Entityname.find(a => a === itemName)
    );
    this.setState({ projectList: itemsList });
  }

  ViewBudget(item) {
    this.toggleView();
    this.setState({
      projectNumber: item.projectNumber,
      projectId: item.projectId,
      lifecycleState: item.lifecycleState,
      name: item.name,
      createTime: item.createTime,
      owners: item.owners,
      Entityname: item.Entityname,
      labels: item.labels
    });
  }
  toggleView() {
    this.setState(prevState => ({
      viewModal: !prevState.viewModal
    }));
  }

  /* Edit budget and Assign entity start */
  onChange = value => {
    this.setState({ entities: value });
  };
  toggleEntityModel() {
    this.setState({
      entityOpen: !this.state.entityOpen
    });
  }
  closeOnSubmit() {
    this.setState({ entityOpen: false });
  }
  AssignEntity(item) {
    this.toggleEntityModel();
    this.setState({
      projectNumber: item.projectNumber,
      Entityname: item.Entityname
    });
    console.log("entity Item", item);
  }

  handleAssignSubmit() {
    var newvalues = [{ name: "projectNumber" }, { name: "entities" }];
    var detailsMapping;
    var changedDetails = newvalues.map(
      c =>
        (detailsMapping = {
          [c.name]: this.state[c.name]
        })
    );
    this.detailsMapping = detailsMapping;
    console.log("changed details", changedDetails);
    const url = `?org=vodafone.com&pid=${
      changedDetails[0].projectNumber
    }&eid=${JSON.stringify(changedDetails[1].entities)}`;
    const urlobj = {
      org: "vodafone.com",
      pid: this.state.projectNumber,
      eid: this.state.entities
    };
    this.props.actions.projectsList.findGcpUpdateEntitiy(urlobj);
    this.closeOnSubmit();
  }
  /*Edit budget and Assign entity End */
  render() {
    console.log("labels", this.state.labels);
    const { isLoading, classes } = this.state;
    console.log("props", this.props);
    /* if (this.props.entities && this.state.isLoading === false) {
      const uniEntitiy = [
        ...new Set(
          projects.map(
            x => this.props.entities.find(ent => ent.id === x.Entityname).Name
          )
        )
      ];
      console.log("uniEntitiy", uniEntitiy);
    }*/
    //
    if (!this.props.proList) {
      return (
        <GridContainer>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
          <GridItem xs={11} sm={11} md={11}>
            <Card>
              <CardHeader title="List of Projects" />
              <CardBody>
                <div style={styling}>
                  <CircularProgress
                    className={classes.progress}
                    color="secondary"
                  />
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={0.5} sm={0.5} md={0.5} />
        </GridContainer>
      );
    } else {
      return (
        <div>
          {isLoading === true && (
            <GridContainer>
              <GridItem xs={0.5} sm={0.5} md={0.5} />
              <GridItem xs={11} sm={11} md={11}>
                <Card>
                  <CardHeader title="List of Projects" />
                  <CardBody>
                    <div style={styling}>
                      <CircularProgress
                        className={classes.progress}
                        color="secondary"
                      />
                    </div>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem xs={0.5} sm={0.5} md={0.5} />
            </GridContainer>
          )}
          {isLoading === false && (
            <div>
              <GridContainer>
                <GridItem xs={0.5} sm={0.5} md={0.5} />
                <GridItem xs={11} sm={11} md={11}>
                  <Card>
                    <CardHeader title="OnBoarding GCP" />
                    <CardBody>
                      <Row>
                        <Col className="text-right">
                          <ButtonDropdown
                            isOpen={this.state.dropdownOpen}
                            toggle={this.toggle}
                          >
                            <DropdownToggle caret color="primary">
                              Filter By Group Function/Local Market
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  this.setState({
                                    projectList: this.props.proList
                                  })
                                }
                              >
                                All Group Function/Local Market
                              </DropdownItem>
                              {this.state.uniEntity.map(a => (
                                <DropdownItem
                                  key={a}
                                  onClick={() => this.filtering(a)}
                                >
                                  {capitalize(a)}
                                </DropdownItem>
                              ))}
                            </DropdownMenu>
                          </ButtonDropdown>
                        </Col>
                      </Row>
                      <h4 className="text-center bold">List of Projects</h4>
                      <Table size="sm" hover>
                        <thead className="bg-light">
                          <tr>
                            <th>Project Name</th>
                            <th>Project Number</th>
                            <th>Project ID</th>
                            <th>Project State</th>
                            {/*<th>Project Creation Date</th>
                            <th>Owners</th>*/}
                            <th>Group Function/Local Market Name</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/*{this.props.projectsList.map(list => (*/}
                          {this.state.projectList.map(list => (
                            <tr key={list.projectId}>
                              <th scope="row">{list.name}</th>
                              <td>{list.projectNumber}</td>
                              <td>{list.projectId}</td>
                              <td>{list.lifecycleState}</td>
                              {/*<td>{dateFunction(list.createTime)}</td>
                              <td>
                                <ul>
                                  {list.owners.map(a => (
                                    <li key={a}>{a.replace("user:", "")}</li>
                                  ))}
                                </ul>
                                  </td>*/}
                              <td>
                                {list.Entityname
                                  ? list.Entityname.map(name =>
                                      capitalize(
                                        this.props.entities.find(
                                          ent => ent.Id === name
                                        ).Name
                                      )
                                    ).join(", ")
                                  : ""}
                              </td>
                              <td>
                                <Button
                                  style={paddingButton}
                                  color="link"
                                  onClick={() => this.ViewBudget(list)}
                                >
                                  <Icon style={view}>remove_red_eye</Icon>
                                </Button>
                                <Button
                                  style={paddingButton}
                                  color="link"
                                  //onClick={() => this.EditItem(list)}
                                >
                                  <Icon style={styling}>create</Icon>
                                </Button>
                                <Button
                                  style={paddingButton}
                                  color="link"
                                  onClick={() => this.AssignEntity(list)}
                                >
                                  <Icon style={assign}>account_box</Icon>
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Modal
                        isOpen={this.state.viewModal}
                        className={this.props.className}
                        size="lg"
                      >
                        <ModalHeader toggle={this.toggleView}>
                          View Project
                        </ModalHeader>
                        <ModalBody>
                          <Container>
                            <Row>
                              <Col sm="2" />
                              <Col sm="4" className="text-right">
                                <strong>Project number:</strong>
                              </Col>
                              <Col sm="4">
                                {this.state.projectNumber
                                  ? this.state.projectNumber
                                  : "-"}
                              </Col>
                              <Col sm="2" />
                            </Row>
                            <Row>
                              <Col sm="2" />
                              <Col sm="4" className="text-right">
                                <strong>ProjectId:</strong>{" "}
                              </Col>
                              <Col sm="4">
                                {this.state.projectId
                                  ? this.state.projectId
                                  : "-"}
                              </Col>
                              <Col sm="2" />
                            </Row>
                            <Row>
                              <Col sm="2" />
                              <Col sm="4" className="text-right">
                                <strong>LifecycleState:</strong>{" "}
                              </Col>
                              <Col sm="4">
                                {this.state.lifecycleState
                                  ? this.state.lifecycleState
                                  : "-"}
                              </Col>
                              <Col sm="2" />
                            </Row>
                            <Row>
                              <Col sm="2" />
                              <Col sm="4" className="text-right">
                                <strong>Name:</strong>{" "}
                              </Col>
                              <Col sm="4">
                                {this.state.name ? this.state.name : "-"}
                              </Col>
                              <Col sm="2" />
                            </Row>
                            <Row>
                              <Col sm="2" />
                              <Col sm="4" className="text-right">
                                <strong>Creattion time:</strong>{" "}
                              </Col>
                              <Col sm="4">
                                {this.state.createTime
                                  ? new Date(
                                      this.state.createTime
                                    ).toLocaleDateString()
                                  : "-"}
                              </Col>
                              <Col sm="2" />
                            </Row>
                            <Row>
                              <Col sm="2" />
                              <Col sm="4" className="text-right">
                                <strong>Owners:</strong>{" "}
                              </Col>
                              <Col sm="4">
                                {this.state.owners &&
                                  this.state.owners
                                    .map(a => a.replace("user:", ""))
                                    .join(", ")}
                              </Col>
                              <Col sm="2" />
                            </Row>
                            <Row>
                              <Col sm="2" />
                              <Col sm="4" className="text-right">
                                <strong>Group Function/Local Market name:</strong>{" "}
                              </Col>
                              <Col sm="4">
                                {this.state.Entityname
                                  ? this.state.Entityname.map(
                                      name =>
                                        this.props.entities.find(
                                          ent => ent.Id === name
                                        ).Name
                                    )
                                  : "-"}
                              </Col>
                              <Col sm="2" />
                            </Row>
                            {this.state.labels &&
                              Object.keys(this.state.labels).map(key => {
                                return (
                                  <Row key={key}>
                                    <Col sm="2" />
                                    <Col sm="4" className="text-right">
                                      <strong>{capitalize(key)}:</strong>{" "}
                                    </Col>
                                    <Col sm="4">{this.state.labels[key]}</Col>
                                    <Col sm="2" />
                                  </Row>
                                );
                              })}
                          </Container>
                        </ModalBody>
                      </Modal>
                      {/*Entitiy Assignment Start*/}
                      <Modal
                        isOpen={this.state.entityOpen}
                        className={this.props.className}
                        size="lg"
                      >
                        <ModalHeader toggle={this.toggleEntityModel}>
                          Assign Group Function/Local Market
                        </ModalHeader>
                        <ModalBody>
                          {/*{isLoading === true && (
              <div style={stylingProgress}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              </div>
            )}
            {isLoading === false && (*/}
                          <div className="mt-5 mb-5">
                            <form>
                              <div className="row mt-4">
                                <div className="col">
                                  <div className="form-group">
                                    <label className="mr-2">
                                      {" "}
                                      Select Group Function/Local Market
                                    </label>
                                    <TreeSelect
                                      showSearch
                                      style={{ width: 300 }}
                                      defaultValue={
                                        this.state.Entityname
                                          ? this.state.Entityname.map(a => a)
                                          : ""
                                      }
                                      dropdownStyle={{
                                        maxHeight: 150,
                                        overflow: "auto"
                                      }}
                                      placeholder="Please select"
                                      treeData={this.props.entities.map(a => ({
                                        id: a.Id,
                                        pId: a.Parent,
                                        value: a.Id,
                                        title: capitalize(a.Name)
                                      }))}
                                      treeDataSimpleMode={true}
                                      allowClear
                                      multiple
                                      treeDefaultExpandAll
                                      onChange={a =>
                                        this.onChange(a, this.props.entities)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col" />
                                <div className="col">
                                  <Button
                                    color="secondary"
                                    onClick={this.toggleEntityModel}
                                    className="w-100"
                                  >
                                    Cancel
                                  </Button>{" "}
                                </div>
                                <div className="col">
                                  <Button
                                    color="primary"
                                    className="w-100"
                                    onClick={this.handleAssignSubmit}
                                  >
                                    Save
                                  </Button>
                                </div>
                                <div className="col" />
                              </div>
                            </form>
                          </div>
                          {/*})}*/}
                        </ModalBody>
                      </Modal>
                      {/*Entity Assignment End*/}
                    </CardBody>
                  </Card>
                </GridItem>

                <GridItem xs={0.5} sm={0.5} md={0.5} />
              </GridContainer>
            </div>
          )}
        </div>
      );
    }
  }
}
function mapStateToProps(state) {
  return {
    projectsList: state.gcpOnboard.gcpOnboardData,
    proList: state.gcpOnboard.gcpOnboardProData,
    entities: state.entities.entityList,
    gcpOnboardEntityData: state.gcpOnboard.gcpOnboardEntityData
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      projectsList: bindActionCreators(GcpOnboardServices, dispatch),
      entities: bindActionCreators(entityServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GcpProjectList);
