import React, { Component } from "react";
import { Group } from "@vx/group";
import { Tree } from "@vx/hierarchy";
import { LinkHorizontal } from "@vx/shape";
import { hierarchy } from "d3-hierarchy";
import { LinearGradient } from "@vx/gradient";
import Card from "../../components/Card/Card.jsx";
import CardHeader from "../../components/Card/CardHeader.jsx";
import CardBody from "../../components/Card/CardBody.jsx";

const peach = "#fd9b93";
const pink = "#fe6e9e";
const blue = "#03c0dc";
const green = "#26deb0";
const plum = "#71248e";
const lightpurple = "#374469";
const white = "#ffffff";
const bg = "#272b4d";
const divStyle = {
  marginLeft: "300px"
};
const tree = {
  name: "ACME",
  children: [
    { name: "APAC" },
    {
      name: "EU",
      children: [
        {
          name: "UK",
          children: [
            {
              name: "A"
            },
            {
              name: "B"
            },
            {
              name: "C"
            }
          ]
        },
        {
          name: "DE"
        }
      ]
    }
  ]
};

function Node({ node }) {
  const width = 40;
  const height = 20;
  const centerX = -width / 2;
  const centerY = -height / 2;
  const isRoot = node.depth === 0;
  const isParent = !!node.children;

  if (isRoot) return <RootNode node={node} />;
  if (isParent) return <ParentNode node={node} />;

  return (
    <Group top={node.x} left={node.y}>
      <rect
        height={height}
        width={width}
        y={centerY}
        x={centerX}
        fill={bg}
        stroke={green}
        strokeWidth={1}
        strokeDasharray={"2,2"}
        strokeOpacity={0.6}
        rx={10}
        onClick={() => {
          alert(`clicked: ${JSON.stringify(node.data.name)}`);
        }}
      />
      <text
        dy={".33em"}
        fontSize={9}
        fontFamily="Arial"
        textAnchor={"middle"}
        fill={green}
        style={{ pointerEvents: "none" }}
      >
        {node.data.name}
      </text>
    </Group>
  );
}

function RootNode({ node }) {
  return (
    <Group top={node.x} left={node.y}>
      <circle r={12} fill="url('#lg')" />
      <text
        dy={".33em"}
        fontSize={9}
        fontFamily="Arial"
        textAnchor={"middle"}
        style={{ pointerEvents: "none" }}
        fill={plum}
      >
        {node.data.name}
      </text>
    </Group>
  );
}

function ParentNode({ node }) {
  const width = 40;
  const height = 20;
  const centerX = -width / 2;
  const centerY = -height / 2;

  return (
    <Group top={node.x} left={node.y}>
      <rect
        height={height}
        width={width}
        y={centerY}
        x={centerX}
        fill={bg}
        stroke={blue}
        strokeWidth={1}
        onClick={() => {
          alert(`clicked: ${JSON.stringify(node.data.name)}`);
        }}
      />
      <text
        dy={".33em"}
        fontSize={9}
        fontFamily="Arial"
        textAnchor={"middle"}
        style={{ pointerEvents: "none" }}
        fill={white}
      >
        {node.data.name}
      </text>
    </Group>
  );
}

class FlowChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props
    };
  }
  render() {
    let width,
      height,
      margin = {
        top: 10,
        left: 30,
        right: 40,
        bottom: 80
      };
    const data = hierarchy(tree);
    //const yMax = height - margin.top - margin.bottom;
    //const xMax = width - margin.left - margin.right;
    const { classes } = this.state;
    return (
      <Card>
        <CardHeader color="primary">
          <h4 className={classes.cardTitleWhite}>
            User Management Entity Tree
          </h4>
        </CardHeader>
        <CardBody>
          <div style={divStyle}>
            <svg width={800} height={800}>
              <LinearGradient id="lg" from={peach} to={pink} />
              <rect width={width} height={height} rx={14} fill={bg} />
              <Tree root={data} size={[800, 480]}>
                {tree => {
                  return (
                    <Group top={margin.top} left={margin.left}>
                      {tree.links().map((link, i) => {
                        return (
                          <LinkHorizontal
                            key={`link-${i}`}
                            data={link}
                            stroke={lightpurple}
                            strokeWidth="1"
                            fill="none"
                          />
                        );
                      })}
                      {tree.descendants().map((node, i) => {
                        return <Node key={`node-${i}`} node={node} />;
                      })}
                    </Group>
                  );
                }}
              </Tree>
            </svg>
          </div>
        </CardBody>
      </Card>
    );
  }
}
export default FlowChart;
