import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { CostTableActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [CostTableActions.costTableFindRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [CostTableActions.costTableFindResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        costTableData: action.payload
      }),
    [CostTableActions.costTableFindFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
