import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { AzureTableActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [AzureTableActions.azureTableFindRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [AzureTableActions.azureTableFindResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        azureTableData: action.payload
      }),
    [AzureTableActions.azureTableFindFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
