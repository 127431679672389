import { createAction } from "redux-actions";
import { ActionTypes } from "../constants";

export const gcpChartRequest = createAction(ActionTypes.GCP_CHART_REQUEST);
export const gcpChartResponse = createAction(ActionTypes.GCP_CHART_RESPONSE);
export const gcpChartFailure = createAction(ActionTypes.GCP_CHART_FAILURE);
export const gcpTableRequest = createAction(ActionTypes.GCP_TABLE_REQUEST);
export const gcpTableResponse = createAction(ActionTypes.GCP_TABLE_RESPONSE);
export const gcpTableFailure = createAction(ActionTypes.GCP_TABLE_FAILURE);
export default {};
