import { createAction } from "redux-actions";
import { ActionTypes } from "../constants";

export const awsOnboardRequest = createAction(
  ActionTypes.AWS_ONBOARDING_REQUEST
);
export const awsOnboardResponse = createAction(
  ActionTypes.AWS_ONBOARDING_RESPONSE
);
export const awsOnboardFailure = createAction(
  ActionTypes.AWS_ONBOARDING_FAILURE
);
export default {};
