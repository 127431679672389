import { createAction } from "redux-actions";
import { ActionTypes } from "../constants";

export const userProfileFindRequest = createAction(
  ActionTypes.USER_PROFILE_FIND_REQUEST
);
export const userProfileFindResponse = createAction(
  ActionTypes.USER_PROFILE_FIND_RESPONSE
);
export const userProfileFindFailure = createAction(
  ActionTypes.USER_PROFILE_FIND_FAILURE
);
export default {};
