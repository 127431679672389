import React, { Component } from "react";
import { Col, Button, Form, FormGroup, Label, Input, Row } from "reactstrap";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import Icon from "@material-ui/core/Icon";
import { ReactMultiEmail } from "react-multi-email";
import { BudgetAwsServices } from "../../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-multi-email/style.css";
//import data from "./data.json";
const buttonStyle = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: "16px"
  },
  color: "#dc3545"
};

const paddingButton = {
  padding: "0"
};
class ThirdPage extends Component {
  /*constructor(props) {
    super(props);
    //this.state = { accountId: [{ value: null }], emails: [] };
    this.state = { value: [] };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  /*createUI() {
    return this.state.accountId.map((el, i) => (
      <div key={i}>
        <input
          type="text"
          value={el.value || ""}
          onChange={this.handleChange.bind(this, i)}
        />
        <input
          type="button"
          value="remove"
          onClick={this.removeClick.bind(this, i)}
        />
      </div>
    ));
  }
  handleChange(i, event) {
    let values = [...this.state.accountId];
    values[i].value = event.target.value;
    this.setState({ accountId: values });
  }

  addClick() {
    this.setState(prevState => ({
      accountId: [...prevState.accountId, { value: null }]
    }));
  }

  removeClick(i) {
    let values = [...this.state.accountId];
    values.splice(i, 1);
    this.setState({ accountId: values });
  }

  handleSubmit(event) {
    /*alert(
      "A name was submitted: " +
        this.state.accountId.map(a => a.value).join(", ")
    );*/
  //this.props.actions.awsOnboard.findAwsOnBoard(
  //this.state.accountId.map(a => a.value).join(",")
  // );
  /*event.preventDefault();
  }
  handleTextChange(e) {
    //this.state.threshold.text[index] = e.target.value;
    //this.setState({ threshold: [{ text: this.state.threshold.text }] });
    let values;
    values = e;
    this.setState({ emails: values });
  }*/
  constructor(props) {
    super(props);
    this.state = { value: "" };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    alert("A name was submitted: " + this.state.value);
    event.preventDefault();
    this.props.actions.addBudget.findAwsAddBudgetGCPSample(this.state.value);
  }
  /*handleSubmit(urlobj) {
    //this.props.actions.addBudget.findAwsAddBudget(urlobj);
    console.log("url", urlobj);
  }*/
  render() {
    return (
      <div>
        {/* <h4>Provide Access to Retrieve your Billing Information:</h4>
        <p>
          <strong>
            To Retrieve Billing Data of your account, Please add this service
            account{" "}
            <a href="https://console.cloud.google.com/iam-admin/serviceaccounts/project?project=vf-grp-pcs-dev-mca&authuser=1">
              vf-grp-pcs-dev-mca@appspot.gserviceaccount.com
            </a>{" "}
            to your Organisation. Steps below.
          </strong>
        </p>
        <ol>
          <li>
            Login to your organisation GCP Console and go to the GCP Project (
            The project which has your Billing Table on BigQuery ), then go to
            IAM & admin
          </li>
          <li>Click Add Member</li>
          <li>
            Assign <strong>"BigQuery User"</strong> Role to the above Service
            Account and Click Save.
          </li>
        </ol>
        <p>
          Enter one or more members below. Then select a role for these members
          to grant them access to your resources. Multiple roles allowed.{" "}
          <a href="">Learn more</a>
        </p>
        <form onSubmit={this.handleSubmit}>
          <div>
            <Row className="mb-2">
              <Col xs="4">
                <label>New members</label>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <ReactMultiEmail
                  placeholder="Input your Email Address"
                  emails={this.state.emails}
                  onChange={_emails => this.handleTextChange(_emails)}
                  /* onChange={_emails => {
                                this.setState({ emails: _emails });
                              }}*/
        /* getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span
                          data-tag-handle
                          onClick={() => removeEmail(index)}
                        >
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row className="mb-2">
              <Col xs="4">
                <label>Select Role</label>
              </Col>
            </Row>
            {this.state.accountId &&
              this.state.accountId.map((tres, index) => {
                return (
                  <div className="form-group" key={index}>
                    <Row>
                      <Col xs="6">
                        <Input
                          type="select"
                          className="form-control"
                          value={tres.value || ""}
                          onChange={e => this.handleChange(index, e)}
                        >
                          <option value="">Select</option>
                          <option value="role1">Role 1</option>
                          <option value="role2">Role 2</option>
                        </Input>
                      </Col>
                      <Col xs="6">
                        <Button
                          color="link"
                          style={paddingButton}
                          onClick={() => this.removeClick(index)}
                        >
                          <Icon style={buttonStyle}>clear</Icon>
                        </Button>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            <Button color="primary" className="w-20">
              Save
            </Button>{" "}
          </div>
          <Row className="mb-2 mt-5">
            <Col xs="6">
              <Button
                color="success"
                onClick={() => this.addClick()}
                size="sm"
                className="w-50"
              >
                Add Role
              </Button>
            </Col>
            <Col xs="3" />

            <Col xs="3" />
          </Row>
            </form>*/}
        <p> Please enter the input in the json format to on-board a customer</p>
        <form onSubmit={this.handleSubmit}>
          <Row className="mb-2 mt-5">
            <Col xs="3" className="text-right">
              <Label >Customer Input:</Label>
            </Col>
            <Col xs="6">
              <Input
                type="textarea"
                value={this.state.value}
                onChange={this.handleChange}
              />
            </Col>

            <Col xs="3" />
          </Row>

          <Row className="mb-2 mt-5">
            <Col xs="6">
              <Button color="primary" size="sm" className="w-50">
                Submit
              </Button>
            </Col>
            <Col xs="3" />

            <Col xs="3" />
          </Row>
        </form>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    addBudget: state.awsBudget.awsBudgetAddData
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      addBudget: bindActionCreators(BudgetAwsServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThirdPage);
