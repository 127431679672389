import React, { Component } from "react";
//import OnboardingAzureLanding from "./azureOnboardingLanding";
import OnBoardingAzureSignin from "./azureSignin";

class OnBoardingAzure extends Component {
  render() {
    return (
      <div>
       {/* <OnboardingAzureLanding />*/}
        <OnBoardingAzureSignin />
      </div>
    );
  }
}

export default OnBoardingAzure;
