import React, { Component } from "react";
import EntityTree from "./entityLanding";
import { entityServices } from "../../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import GridItem from "../../../components/Grid/GridItem.jsx";
import GridContainer from "../../../components/Grid/GridContainer.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import ls from "local-storage";
import Unassigned from "./unassigneEntitiy";
import AssignedEntitiy from "./assignedEntitiy";
const styling = {
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "40px",
  marginBottom: "40px",
  textAlign: "center"
};
class EntityMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entities: [],
      isLoading: true,
      classes: props,
      userid: ls.get("customerId"),
      token: ls.get("idToken"),
      AssignContent: false,
      AssignId: ""
    };
    this.hello = this.hello.bind(this);
    this.helloOne = this.helloOne.bind(this);
  }
  componentDidMount() {
    this.props.actions.entities.findEntities(
      this.state.token,
      this.state.userid
    );
  }
  componentWillReceiveProps(nextProps) {
    console.log("nextppppp", nextProps);
    if (nextProps.entities) {
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: true });
      if (nextProps.entityDelList) {
        this.props.actions.entities.findEntities(
          this.state.token,
          this.state.userid
        );
      } else if (nextProps.entityAddList) {
        this.props.actions.entities.findEntities(
          this.state.token,
          this.state.userid
        );
      } else if (nextProps.entityEditList) {
        this.props.actions.entities.findEntities(
          this.state.token,
          this.state.userid
        );
      } else if (nextProps.entityChangeList) {
        this.props.actions.entities.findEntities(
          this.state.token,
          this.state.userid
        );
      }
    }
  }
  hello(content, id) {
    console.log("h1", id);
    this.setState({ AssignContent: content });
    this.setState({ AssignId: id });
  }
  helloOne(content) {
    console.log("h1", content);
    this.setState({ AssignContent: content });
  }
  render() {
    const { isLoading, classes } = this.state;
    return (
      <div>
        {isLoading === true && (
          <GridContainer>
            <GridItem xs={0.5} sm={0.5} md={0.5} />
            <GridItem xs={11} sm={11} md={11}>
              <Card>
                <CardHeader title="Groups" />
                <CardBody>
                  <div style={styling}>
                    <CircularProgress
                      className={classes.progress}
                      color="secondary"
                    />
                  </div>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={0.5} sm={0.5} md={0.5} />
          </GridContainer>
        )}
        {isLoading === false && (
          <GridContainer>
            <GridItem xs={0.5} sm={0.5} md={0.5} />
            <GridItem xs={5} sm={5} md={5}>
              <EntityTree
                hello={this.hello}
                entities={this.props.entities}
                treeData={this.props.entities.map((a, i) => ({
                  id: a.Id,
                  name: a.Name,
                  parent: a.Parent || null,
                  expanded: !a.Parent ? true : false
                }))}
              />
            </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              {!this.state.AssignContent && (
                <Card>
                  <CardHeader title="Unassigned" />
                  <CardBody>
                    <div style={{ height: 650, overflow: "scroll" }}>
                      <Unassigned entities={this.props.entities} />
                    </div>
                  </CardBody>
                </Card>
              )}
              {this.state.AssignContent && (
                <Card>
                  <CardHeader title="Assigned" />
                  <CardBody>
                    <div style={{ height: 650, overflow: "scroll" }}>
                      <AssignedEntitiy
                        entities={this.props.entities}
                        helloOne={this.helloOne}
                        AssignId={this.state.AssignId}
                      />
                    </div>
                  </CardBody>
                </Card>
              )}
            </GridItem>

            <GridItem xs={0.5} sm={0.5} md={0.5} />
          </GridContainer>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    entities: state.entities.entityList,
    entityDelList: state.entities.entityDelList,
    entityAddList: state.entities.entityAddList,
    entityEditList: state.entities.entityEditList,
    entityChangeList: state.entities.entityChangeList
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      entities: bindActionCreators(entityServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EntityMain);
