import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { EntityActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [EntityActions.listEntityRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [EntityActions.listEntityResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        entityList: action.payload
      }),
    [EntityActions.listEntityFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [EntityActions.listEntityDeleteRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [EntityActions.listEntityDeleteResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        entityDelList: action.payload
      }),
    [EntityActions.listEntityDeleteFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [EntityActions.listEntityAddRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [EntityActions.listEntityAddResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        entityAddList: action.payload
      }),
    [EntityActions.listEntityAddFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [EntityActions.listEntityEditRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [EntityActions.listEntityEditResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        entityEditList: action.payload
      }),
    [EntityActions.listEntityEditFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [EntityActions.listEntityChangeRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [EntityActions.listEntityChangeResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        entityChangeList: action.payload
      }),
    [EntityActions.listEntityChangeFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
