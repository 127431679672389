import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { capitalize } from "lodash";
class FirstPage extends Component {
  render() {
    return (
      <div>
        <h4>
          Welcome to Multi Cloud Administrator's Google Cloud Platform of
          Vodafone.com
        </h4>
        <p>You are in Customer On-Boarding Page.</p>
        {/*} <p>
          To come On-Board, please fill the form and follow the instructions
          below.
    </p>*/}
        <Row>
          <Col sm="2" className="text-left">
            <strong>Customer Name:</strong>
          </Col>
          <Col sm="4">
            {this.props.userProfile && this.props.userProfile.customerName
              ? capitalize(this.props.userProfile.customerName)
              : "-"}
          </Col>

          <Col sm="4" />
        </Row>
        {/*} <Form>
          <FormGroup row>
            <Label for="customerName" sm={2}>
              Customer Name:
            </Label>
            <Col sm={10}>
              <Input type="text" name="customerName" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="custDesc" sm={2}>
              Customer Description:
            </Label>
            <Col sm={10}>
              <Input type="textarea" name="custDesc" />
            </Col>
          </FormGroup>
        </Form>*/}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userProfile: state.userProfile.userProfile
  };
}
export default connect(
  mapStateToProps,
  ""
)(FirstPage);
