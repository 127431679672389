import React from "react";
import { render } from "react-dom";
import { Router } from "react-router-dom";
//import { hashHistory } from "react-router";
import { Provider } from "react-redux";
import createHistory from "history/createBrowserHistory";
import createStore from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./containers/app";
//import history from "./constants/history";
// Create redux store with history
const history = createHistory();
const store = createStore(window.__INITIAL_STATE__ || {}, history);
const MOUNT_NODE = document.getElementById("root");

render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  MOUNT_NODE
);
