import React, { Component } from "react";
import { Col, Form, FormGroup, Label, Input, Row } from "reactstrap";
import { connect } from "react-redux";
import Card from "../../../components/Card/Card.jsx";
import CardHeader from "@material-ui/core/CardHeader";
import CardBody from "../../../components/Card/CardBody.jsx";
import { ListGroup, ListGroupItem } from "reactstrap";
import data from "./data.js";
import ReactHtmlParser from "react-html-parser";
class ThirdPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Region: "",
      accountType: "",
      Logging_Resourse: "",
      RegionData: ""
    };
  }
  handleChange(event) {
    this.setState({ Logging_Resourse: event.target.value });
  }
  handleAccountChange(e) {
    this.setState({ accountType: e.target.value });
    this.setState({ Logging_Resourse: "" });
  }
  handleRegionChange(e) {
    this.setState({ Region: e.target.value });
    var a = data.filter(pro => pro.region === e.target.value);
    this.setState({ RegionData: a });
    this.setState({ accountType: "", Logging_Resourse: "" });
  }
  render() {
    console.log("filter", this.state.RegionData);
    var AccountDisabled = true;
    var LoggingDisabled = true;

    if (this.state.Region) {
      AccountDisabled = false;
    }
    if (this.state.accountType) {
      LoggingDisabled = false;
    }
    return (
      <div>
        <Form>
          <Row form>
            <Col md={4}>
              <FormGroup>
                <Label for="Region">Region</Label>
                <Input
                  type="select"
                  name="Region"
                  id="Region"
                  value={this.state.Region}
                  onChange={e => this.handleRegionChange(e)}
                >
                  <option value="">Select</option>
                  {data.map(a => (
                    <option value={a.region} key={a.region}>
                      {a.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="accountType">Account Type</Label>
                <Input
                  type="select"
                  name="accountType"
                  id="accountType"
                  disabled={AccountDisabled}
                  value={this.state.accountType}
                  onChange={e => this.handleAccountChange(e)}
                >
                  <option value="">Select</option>
                  <option value="Master">Master</option>
                  <option value="Single">Single</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="Logging_Resourse">Logging Resourse</Label>
                <Input
                  type="select"
                  name="Logging_Resourse"
                  id="Logging_Resourse"
                  disabled={LoggingDisabled}
                  value={this.state.Logging_Resourse}
                  onChange={e => this.handleChange(e)}
                >
                  <option value="">Select</option>
                  <option value="Same">Same</option>
                  <option value="Separate">Separate</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </Form>

        <Card>
          <CardHeader title="Onboard" />
          <CardBody>
            {this.state.RegionData
              ? this.state.RegionData.map(a => (
                  <ListGroup key={a.region}>
                    {this.state.accountType === "Master" && (
                      <div>
                        {this.state.Logging_Resourse === "Same" && (
                          <ListGroupItem>
                            {" "}
                            {ReactHtmlParser(a.Step1)}
                          </ListGroupItem>
                        )}
                        {this.state.Logging_Resourse === "Separate" && (
                          <ListGroupItem>
                            {" "}
                            {ReactHtmlParser(a.Step2)}
                          </ListGroupItem>
                        )}
                      </div>
                    )}
                    {this.state.accountType === "Single" && (
                      <div>
                        {this.state.Logging_Resourse === "Same" && (
                          <ListGroupItem>
                            {" "}
                            {ReactHtmlParser(a.Step3)}
                          </ListGroupItem>
                        )}
                        {this.state.Logging_Resourse === "Separate" && (
                          <ListGroupItem>
                            {" "}
                            {ReactHtmlParser(a.Step4)}
                          </ListGroupItem>
                        )}
                      </div>
                    )}
                  </ListGroup>
                ))
              : ""}
          </CardBody>
        </Card>
      </div>
    );
  }
}
function mapStateToProps(state) {
  console.log("State", state.awsOnboard.awsOnboardAccess);
  return {
    awsOnboard: state.awsOnboard.awsOnboardAccess
  };
}
export default connect(mapStateToProps)(ThirdPage);
