import React, { Component } from "react";

class FirstPage extends Component {
  render() {
    return (
      <div>
        <h4>Both Individual & Master Accounts:</h4>
        <ol>
          <li>
            <strong>Billing - </strong> You should have enabled cost reporting
            (this can take up to 24 hours to take effect) and you should have a
            cost and usage report set up and streaming to a S3 bucket.
            <br />
            Cost and Usage Reports must be saved in PARQUET format (compatible
            with Athena). The billing bucket <strong>MUST</strong> have AES-256
            encryption on the bucket. You can activate this by going to the{" "}
            <u>S3</u> service in the console, clicking on <u>Properties</u> and
            then <u>Default encryption</u>, selecting <u>AES-256</u>{" "}
            (server-side encryption with S3-Managed keys (SSE-S3)), and clicking{" "}
            <u>Save</u>.
          </li>
        </ol>
        <h4>Only Individual Accounts:</h4>
        <ol>
          <li>
            <strong>
              You need to have the permissions to be able to deploy a
              CloudFormation Stack -{" "}
            </strong>{" "}
            You will use a template provided by MCA to on-board your account.
            The template will deploy a stack.
          </li>
        </ol>
        <h4>Only Master Accounts:</h4>
        <ol>
          <li>
            <strong>
              Add the{" "}
              <a href="https://s3.amazonaws.com/cloudformation-stackset-sample-templates-us-east-1/AWSCloudFormationStackSetAdministrationRole.yml">
                AWSCloudFormationStackSetAdministrationRole
              </a>{" "}
              to your master account -
            </strong>{" "}
            Your master account(s) must have the
            'AWSCloudFormationStackSetAdministrationRole' role. This allows your
            master account to deploy StackSet instances to your child accounts.
            This role may already exist in your account if you have previously
            deployed StackSets cross-account. The role must be named exactly as
            specified and have the correct policies. You will need this in order
            to deploy the MCA role to all of your child accounts in an efficient
            manner.
          </li>
          <li>
            <strong>
              Add the{" "}
              <a href="https://s3.amazonaws.com/cloudformation-stackset-sample-templates-us-east-1/AWSCloudFormationStackSetExecutionRole.yml">
                AWSCloudFormationStackSetExecutionRole
              </a>{" "}
              to all of your child accounts -{" "}
            </strong>{" "}
            All of your child accounts must have the
            'AWSCloudFormationStackSetExecutionRole' role and must trust the
            master account. This permits your master account to deploy StackSet
            instances into your child accounts. The role must be named exactly
            as specified and have the correct policies. You will need to provide
            the master account as a parameter.
          </li>
        </ol>
      </div>
    );
  }
}

export default FirstPage;
