import React, { Component } from "react";
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
//import data from "./sampleUser.json";
import { authUsersServices } from "../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ls from "local-storage";
import Icon from "@material-ui/core/Icon";
import { TreeSelect } from "antd";
import { capitalize } from "lodash";
const styling = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    padding: "0",
    marginTop: "10px",
    marginLeft: "15px"
  },
  icon: {
    margin: "16px"
  }
};
const buttonStyle = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: "16px"
  },
  color: "#dc3545"
};
const addbutton = {
  padding: "0",
  marginTop: "10px",
  marginLeft: "15px"
};
const paddingButton = {
  padding: "0"
};
const paddingRight = {
  paddingRight: "20px"
};
class UsersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      userToken: ls.get("idToken"),
      users: props.authUsers,
      email: "",
      group: "",
      entity: "",
      enabled: "",
      Addemail: "",
      Addgroup: "",
      Addentity: "",
      Addenabled: true,
      modal: false,
      addModal: false,
      entities: props.entities
    };
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleAddModal = this.toggleAddModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAddSubmit = this.handleAddSubmit.bind(this);
  }

  navigate(item) {
    console.log("item", item);
    this.props.actions.getDeletedUser(this.state.userToken, item.email);
  }

  /*Edit Functionality Start*/
  EditItem(item) {
    this.toggleModal();
    this.setState({
      email: item.email,
      group: item.group,
      entity: item.entity,
      enabled: item.enabled
    });
  }
  toggleModal() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }
  editTodo(key, event) {
    var change = {
      email: this.state.email,
      group: this.state.group,
      entity: this.state.entity,
      enabled: this.state.enabled
    };
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    change[key] = value;
    this.setState(change);
  }
  handleSubmit() {
    var newvalues = [
      { name: "email" },
      { name: "group" },
      { name: "entities" },
      { name: "enabled" }
    ];
    var detailsMapping;
    var changedDetails = newvalues.map(
      c =>
        (detailsMapping = {
          [c.name]: this.state[c.name]
        })
    );
    this.detailsMapping = detailsMapping;

    const url = `?email=${changedDetails[0].email}&group=${
      changedDetails[1].group
    }&entity=${JSON.stringify(changedDetails[2].entities)}&enable=${changedDetails[3].enabled}`;
    this.props.actions.getUpdatedUser(this.state.userToken, url);
    this.toggleModal();
  }
  /*Edit Functionality End*/
  /*Add Functionality Start*/
  AddItem() {
    var newvalues = [
      { name: "Addemail" },
      { name: "Addgroup" },
      { name: "Addentity" }
    ];
    var changedDetails = newvalues.map(c => this.setState({ [c.name]: "" }));
    this.setState({ Addenabled: true });
    console.log("details initial", changedDetails);
    this.toggleAddModal();
  }
  toggleAddModal() {
    this.setState(prevState => ({
      addModal: !prevState.addModal
    }));
  }
  AddTodo(key, event) {
    var change = {
      Addemail: this.state.Addemail,
      Addgroup: this.state.Addgroup,
      Addentity: this.state.Addentity,
      Addenabled: this.state.Addenabled
    };
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    change[key] = value;
    this.setState(change);
  }
  handleAddSubmit() {
    var newvalues = [
      { name: "Addemail" },
      { name: "Addgroup" },
      { name: "entities" },
      { name: "Addenabled" }
    ];
    var detailsMapping;
    var changedDetails = newvalues.map(
      c =>
        (detailsMapping = {
          [c.name]: this.state[c.name]
        })
    );
    this.detailsMapping = detailsMapping;
    const url = `?email=${changedDetails[0].Addemail}&group=${
      changedDetails[1].Addgroup
    }&entity=${JSON.stringify(changedDetails[2].entities)}&enable=${
      changedDetails[3].Addenabled
    }`;
    this.props.actions.getAddUser(this.state.userToken, url);
    this.toggleAddModal();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.added && nextProps.added.message) {
      this.props.actions.findAuthUsers(this.state.userToken);
    } else if (nextProps.updated && nextProps.updated.message) {
      this.props.actions.findAuthUsers(this.state.userToken);
    } else if (nextProps.deleted && nextProps.deleted.message) {
      this.props.actions.findAuthUsers(this.state.userToken);
    }
    if (nextProps.authUsers) {
      this.setState({ users: nextProps.authUsers });
    }
    if (this.props.entities) {
      this.setState({ isLoading: false });
    }
  }
  onChange = value => {
    this.setState({ entities: value });
  };
  /*Add Functionality End*/
  render() {
    //const classes = useStyles();
    const idTest = this.props.identityProvider !== "COGNITO" ? true : false;
    return (
      <div>
        <Table size="sm" hover>
          <thead className="bg-light">
            <tr>
              <th>Username</th>
              <th>Group</th>
              <th>Entity</th>
              <th>Enabled</th>
              <th>
                {this.props.identityProvider !== "COGNITO" ? (
                  "Action"
                ) : (
                  <Button
                    style={addbutton}
                    color="link"
                    onClick={() => this.AddItem()}
                  >
                    <Icon style={styling}>add_circle</Icon>
                  </Button>
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.users.map((item, i) => (
              <tr key={i}>
                <th scope="row">{item.email}</th>
                <td>{item.group}</td>
                <td>
                  {this.props.entities.find(ent => ent.Id === item.entity).Name}
                </td>
                <td>
                  <input type="checkbox" checked={item.enabled} readOnly />
                </td>
                <td>
                  {" "}
                  <div>
                    <Button
                      style={paddingButton}
                      color="link"
                      onClick={() => this.EditItem(item)}
                    >
                      <Icon style={styling}>create</Icon>
                    </Button>
                    {this.props.identityProvider === "COGNITO" && (
                      <Button
                        style={paddingButton}
                        color="link"
                        onClick={() => this.navigate(item)}
                      >
                        <Icon style={buttonStyle}>clear</Icon>
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Modal isOpen={this.state.modal} className={this.props.className}>
          <ModalHeader toggle={this.toggleModal}>Edit User</ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.email}
                  onChange={e => this.editTodo("email", e)}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>Group</label>
                <select
                  className="form-control"
                  value={this.state.group}
                  onChange={e => this.editTodo("group", e)}
                  disabled={idTest}
                >
                  <option value="">...select...</option>
                  <option value="admin">Admin</option>
                  <option value="standard">Standard</option>
                  <option value="super-admin">Super Admin</option>
                </select>
              </div>
              {this.props.entities && (
                <div className="mt-5 mb-5">
                  <div className="row mt-4">
                    <div className="col">
                      <div className="form-group">
                        <label className="mr-2"> Select Entity</label>
                        <TreeSelect
                          value={this.state.entity}
                          showSearch
                          style={{ width: 300 }}
                          dropdownStyle={{
                            maxHeight: 150,
                            overflow: "auto"
                          }}
                          placeholder="Please select"
                          treeData={this.props.entities.map(a => ({
                            id: a.Id,
                            pId: a.Parent,
                            value: a.Id,
                            title: capitalize(a.Name)
                          }))}
                          treeDataSimpleMode={true}
                          allowClear
                          treeDefaultExpandAll
                          onChange={a => this.onChange(a, this.props.entities)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*<div className="form-group">
                <label>Entity</label>
                <select
                  className="form-control"
                  value={this.state.entity}
                  onChange={e => this.editTodo("entity", e)}
                >
                  <option value="">...select...</option>
                  <option value="acme">ACME</option>
                </select>
                        </div>*/}
              <div className="form-group">
                <label style={paddingRight}>Enabled</label>
                <input
                  type="checkbox"
                  checked={this.state.enabled}
                  onChange={e => this.editTodo("enabled", e)}
                  disabled={idTest}
                />
              </div>
              <div className="row mt-4">
                <div className="col" />
                <div className="col">
                  <Button
                    color="secondary"
                    onClick={this.toggleModal}
                    className="w-100"
                  >
                    Cancel
                  </Button>{" "}
                </div>
                <div className="col">
                  <Button color="primary" className="w-100">
                    Save
                  </Button>
                </div>
                <div className="col" />
              </div>
            </form>
          </ModalBody>
          <ModalFooter />
        </Modal>
        <Modal isOpen={this.state.addModal} className={this.props.className}>
          <ModalHeader toggle={this.toggleAddModal}>Add User</ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleAddSubmit}>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.Addemail}
                  onChange={e => this.AddTodo("Addemail", e)}
                />
              </div>
              <div className="form-group">
                <label>Group</label>
                <select
                  className="form-control"
                  value={this.state.Addgroup}
                  onChange={e => this.AddTodo("Addgroup", e)}
                >
                  <option value="">...select...</option>
                  <option value="admin">Admin</option>
                  <option value="standard">Standard</option>
                  <option value="super-admin">Super Admin</option>
                </select>
              </div>
              {this.props.entities && (
                <div className="mt-5 mb-5">
                  <div className="row mt-4">
                    <div className="col">
                      <div className="form-group">
                        <label className="mr-2"> Select Entity</label>
                        <TreeSelect
                          showSearch
                          style={{ width: 300 }}
                          dropdownStyle={{
                            maxHeight: 150,
                            overflow: "auto"
                          }}
                          placeholder="Please select"
                          treeData={this.props.entities.map(a => ({
                            id: a.Id,
                            pId: a.Parent,
                            value: a.Id,
                            title: capitalize(a.Name)
                          }))}
                          treeDataSimpleMode={true}
                          allowClear
                          treeDefaultExpandAll
                          onChange={a => this.onChange(a, this.props.entities)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*} <select
                  className="form-control"
                  value={this.state.Addentity}
                  onChange={e => this.AddTodo("Addentity", e)}
                >
                  <option value="">...select...</option>
                  <option value="acme">ACME</option>
                  </select>*/}
              <div className="form-group">
                <label style={paddingRight}>Enabled</label>
                <input
                  type="checkbox"
                  checked={this.state.Addenabled}
                  onChange={e => this.AddTodo("Addenabled", e)}
                />
              </div>
              <div className="row mt-4">
                <div className="col" />
                <div className="col">
                  <Button
                    color="secondary"
                    onClick={this.toggleAddModal}
                    className="w-100"
                  >
                    Cancel
                  </Button>{" "}
                </div>
                <div className="col">
                  <Button color="primary" className="w-100">
                    Save
                  </Button>
                </div>
                <div className="col" />
              </div>
            </form>
          </ModalBody>
          <ModalFooter />
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    deleted: state.authUsers.userDeleted,
    updated: state.authUsers.userUpdated,
    added: state.authUsers.userAdded,
    usersList: state.authUsers.authUsers,
    entities: state.entities.entityList
  }; // state
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(authUsersServices, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersTable);
