import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { AuthUsersActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [AuthUsersActions.authUsersFindRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [AuthUsersActions.authUsersFindResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        authUsers: action.payload
      }),
    [AuthUsersActions.authUsersFindFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [AuthUsersActions.userDeleteRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [AuthUsersActions.userDeleteResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        userDeleted: action.payload
      }),
    [AuthUsersActions.userDeleteFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [AuthUsersActions.userEditRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [AuthUsersActions.userEditResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        userUpdated: action.payload
      }),
    [AuthUsersActions.userEditFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [AuthUsersActions.userAddRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [AuthUsersActions.userAddResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        userAdded: action.payload
      }),
    [AuthUsersActions.userAddFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
