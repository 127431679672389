import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { AwsOnboardActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [AwsOnboardActions.awsOnboardRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [AwsOnboardActions.awsOnboardResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        awsOnboardAccess: action.payload
      }),
    [AwsOnboardActions.awsOnboardFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
