/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "../components/Navbars/Navbar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import DashboardPage from "../containers/Dashboard/Dashboard.jsx";
import DashboardAzure from "../containers/Dashboard/DashboardAzure.js";
import dashboardRoutes from "../routes.js";
import helloRoutes from "../routesAzure.js";
import dashboardStyle from "../assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";
import Typography from "../containers/Typography/Typography.jsx";
import TableList from "../containers/TableList/TableList.jsx";
import NotificationsPage from "../containers/Notifications/Notifications.jsx";
import image from "../assets/img/sidebar-2.jpg";
import logo from "../assets/img/mca-logo.png";
import AzureCostReport from "../containers/Azure/CostReport/azureCostReport";
import CostManagementAzure from "../containers/UserProfile/CostManagementAzure.jsx";
import UserManagementTable from "../containers/UserManagement/UserManagementTable.jsx";
import Icons from "../containers/Icons/Icons.jsx";
import UpgradeToPro from "../containers/UpgradeToPro/UpgradeToPro.jsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AzureTableMap from "../containers/Azure/CostReport/AzureTableData";
import EmptyComponent from "../containers/emptyComponent/empty";
import OnBoardingAzure from "../containers/Azure/OnBoarding/onBoardingMain";
import BudgetAws from "../containers/AWS/Budgets/budgetAws";
import ls from "local-storage";
import { bindActionCreators } from "redux";
import { entityServices } from "../services";
import EntityMain from "../containers/AWS/Entities/MainEntity";
var abc;
var url = location.pathname;
if (location.pathname === '/admin/aws') {
    abc = dashboardRoutes
} else if (location.pathname === '/admin/azure') {
    abc = helloRoutes
} else {
    abc = helloRoutes;
}
/*if(location.pathname === "/admin/aws" ){
  abc = dashboardRoutes
}else if(location.pathname === "/admin/azure"){
  abc = helloRoutes
}*/
const divStyle = {
    marginTop: '50px',
};
const divStyleTwo = {
    marginTop: '100px',
};
const switchRoutes = (
    <Switch>
        {abc.map((prop, key) => {
            if (prop.layout === "/admin/aws" || prop.layout === "/admin/azure") {
                return (
                    <Route exact
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
        })}
    </Switch>
);
const paths = [{
        pathname: '#/azure',
        component: AzureTableMap
    },
    {
        pathname: "#/azure/dashboard",
        component: EmptyComponent
      },
      {
        pathname: "#/azure/costManagement",
        component: EmptyComponent
      },
    {
        pathname: "#/azure/assetManagement",
        component: AzureTableMap
      },
      {
        pathname: "#/azure/securityCompilance",
        component: EmptyComponent
      },
      {
        pathname: "#/azure/serviceMonitoring",
        component: EmptyComponent
      },
      {
        pathname: "#/azure/costOptimisation",
        component: EmptyComponent
      },
      {
        pathname: "#/azure/admin",
        component: EmptyComponent
      },
      {
        pathname: "#/azure/costManagement/costData",
        component: AzureTableMap
      },

      {
        pathname: "#/azure/costManagement/budget",
        component: BudgetAws
      },
      {
        pathname: "#/azure/setup/userManagement",
        component: UserManagementTable
      },
      {
        pathname: "#/azure/setup/entities",
        component: EntityMain
      },
      {
        pathname: "#/azure/setup",
        component: EmptyComponent
      },
      {
        pathname: "#/azure/setup/onboarding",
        component: OnBoardingAzure
      }


];
class AdminAzure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: image,
            color: "blue",
            hasImage: true,
            fixedClasses: "dropdown show",
            mobileOpen: false,
            userid: ls.get("customerId"),
            token: ls.get("idToken"),
            isLoading: true 
          };
          this.props.actions.entities.findEntities(
            this.state.token,
            this.state.userid
          );
        };
    handleImageClick = image => {
        this.setState({ image: image });
    };
    handleColorClick = color => {
        this.setState({ color: color });
    };
    handleFixedClick = () => {
        if (this.state.fixedClasses === "dropdown") {
            this.setState({ fixedClasses: "dropdown show" });
        } else {
            this.setState({ fixedClasses: "dropdown" });
        }
    };
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    getRoute() {
        return this.props.location.pathname !== "/admin/maps";
    }
    resizeFunction = () => {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    };
    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            const ps = new PerfectScrollbar(this.refs.mainPanel);
        }
        window.addEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    componentWillReceiveProps(nextProps) {
        console.log("next", nextProps);
        if (nextProps.entities) {
          this.setState({ isLoading: false });
        }
      }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }
    render() {
        const { classes, ...rest } = this.props;

        return (
            <div className={classes.wrapper}>
                                  <Navbar
                        routes={helloRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                {this.state.isLoading === false &&
                <Sidebar
                    routes={helloRoutes}
                    logo={logo}
                    image={this.state.image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color={this.state.color}
                    entities={this.props.entities}
                    {...rest}  
                  />
                  }
                <div className={classes.mainPanel} ref="mainPanel">

                    {paths.map((a, i) => 
                        location.hash === (a.pathname) &&  (<div key={i} style={divStyle}> <a.component /></div>)
                        )}
                </div>

            </div>
        );
    }
}

AdminAzure.propTypes = {
    classes: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
      entities: state.entities.entityList
    };
  }
  function mapDispatchToProps(dispatch) {
    return {
      actions: {
        entities: bindActionCreators(entityServices, dispatch)
      }
    };
  }
  export default withRouter(connect(mapStateToProps,
    mapDispatchToProps)(withStyles(dashboardStyle)(AdminAzure)));
