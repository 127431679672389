import React, { Component } from "react";
import { TreeSelect } from "antd";
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Container,
  Alert,
  FormGroup,
  Label,
  Input,
  FormFeedback
} from "reactstrap";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/style.css";
import Icon from "@material-ui/core/Icon";
import Select from "react-select";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import "antd/dist/antd.css";
//import AssignEntity from "./assignEntity";
import { entityServices, BudgetAwsServices } from "../../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isArray } from "lodash";
import ls from "local-storage";
import validator from "validator";
import SimpleReactValidator from "simple-react-validator";
import { capitalize } from "lodash";
const stylingProgress = {
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "40px",
  marginBottom: "40px",
  textAlign: "center"
};
const styling = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    padding: "0",
    marginTop: "10px",
    marginLeft: "15px"
  },
  icon: {
    margin: "16px"
  }
};
const assign = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: "16px"
  },
  color: "#f39c12"
};
const view = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: "16px"
  },
  color: "#1aaa55"
};

const buttonStyle = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: "16px"
  },
  color: "#dc3545"
};

const paddingButton = {
  padding: "0"
};

const dateStyle = {
  display: "block",
  width: "100%",
  height: "calc(1.5em + .75rem + 2px)",
  padding: ".375rem .75rem",
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.5",
  color: "#495057",
  backgroundColor: "#fff",
  backgroundClip: "padding-box",
  border: "1px solid #ced4da",
  borderRadius: ".25rem"
};

class BudgetAwsLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: props,
      addModal: false,
      viewModal: false,
      Sdate: new Date(),
      Edate: new Date(),
      threshold: [{ type: null, value: null, emails: [] }],
      dropdownOpen: false,
      entityOpen: false,
      budgetList: props.awsBudgetList,
      entities: [],
      isLoading: true,
      userid: ls.get("customerId"),
      token: ls.get("idToken"),
      PO: "",
      budgetName: "",
      budgetValue: "",
      currency: "",
      seq: 0,
      csps: [],
      deficit: "",
      consumption: 0,
      visible: false,
      editModal: false,
      ApiUrlstatus: "APPORVED",
      treeValue: ["acme"]
    };
    this.validator = new SimpleReactValidator();
    this.toggleAddModal = this.toggleAddModal.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleEntityModel = this.toggleEntityModel.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.closeOnSubmit = this.closeOnSubmit.bind(this);
    this.toggleEditModal = this.toggleEditModal.bind(this);
    this.handleAddSubmit = this.handleAddSubmit.bind(this);
    this.handleEditSubmit = this.handleEditSubmit.bind(this);
    this.handleAssignSubmit = this.handleAssignSubmit.bind(this);
    this.budgetListCall = this.budgetListCall.bind(this);
    this.DeleteBudget = this.DeleteBudget.bind(this);
  }
  onChange = value => {
    this.setState({ entities: value });
  };
  onShowAlert = () => {
    this.setState({ visible: true }, () => {
      window.setTimeout(() => {
        this.setState({ visible: false });
      }, 2000);
    });
  };
  /*View Budget methods Start*/
  ViewBudget(item) {
    this.toggleView();
    this.setState({
      PO: item.Id,
      budgetName: item.name,
      budgetValue: item.value,
      currency: item.currency,
      seq: item.seq || 0,
      csps: item.csps,
      Sdate: item.start_date,
      Edate: item.end_date,
      threshold: item.thresholds,
      deficit: item.deficit,
      consumption: item.consumption
    });
  }
  toggleView() {
    this.setState(prevState => ({
      viewModal: !prevState.viewModal
    }));
  }
  /*View Budget methods End*/

  /* Delete Butget Start*/
  DeleteBudget(item) {
    const url = `?budget_id=${item.Id}`;
    this.props.actions.addBudget.findAwsDeleteBudget(
      url,
      this.state.token,
      this.state.userid
    );
    //this.onShowAlert();
  }
  /*Delete Budget End */
  /* Add budget Start*/
  toggleAddModal() {
    this.setState(prevState => ({
      addModal: !prevState.addModal
    }));
  }
  AddItem() {
    var newvalues = [
      { name: "PO" },
      { name: "budgetName" },
      { name: "budgetValue" },
      { name: "currency" },
      { name: "seq" },
      { name: "csps" },
      { name: "Sdate" },
      { name: "Edate" },
      { name: "threshold" }
    ];
    var changedDetails = newvalues.map(c => this.setState({ [c.name]: "" }));
    this.setState({ Addenabled: true });
    console.log("details initial", changedDetails);
    this.validator.hideMessages();
    this.toggleAddModal();
  }
  AddTodo(key, event) {
    var change = {
      PO: this.state.PO,
      budgetName: this.state.budgetName,
      budgetValue: this.state.budgetValue,
      currency: this.state.currency,
      seq: this.state.seq
    };
    const value = event.target.value;
    change[key] = value;
    this.setState(change);
    console.log("change one", change);
    console.log("csps", this.state.csps);
    console.log("tres", this.state.threshold);
  }
  handleAddSubmit() {
    var newvalues = [
      { name: "PO" },
      { name: "budgetName" },
      { name: "budgetValue" },
      { name: "currency" },
      { name: "seq" },
      { name: "csps" },
      { name: "Sdate" },
      { name: "Edate" },
      { name: "threshold" }
    ];
    var detailsMapping;
    var changedDetails = newvalues.map(
      c =>
        (detailsMapping = {
          [c.name]: this.state[c.name]
        })
    );
    this.detailsMapping = detailsMapping;
    console.log("chages", changedDetails);
    console.log("details", detailsMapping);

    /*const url = `?id=${changedDetails[0].PO}&name=${
      changedDetails[1].budgetName
    }&currency=${changedDetails[3].currency}&value=${
      changedDetails[2].budgetValue
    }&thresholds=${JSON.stringify(
      changedDetails[8].threshold
    )}&entities=[]&start_date=${new Date(
      changedDetails[6].Sdate
    ).toLocaleDateString()}&end_date=${new Date(
      changedDetails[7].Edate
    ).toLocaleDateString()}&seq=${changedDetails[4].seq}&csps=${JSON.stringify(
      changedDetails[5].csps.map(a => a.value || a)
    )}`;*/
    const urlobj = {
      Id: this.state.PO,
      name: this.state.budgetName,
      currency: this.state.currency,
      value: this.state.budgetValue,
      thresholds: this.state.threshold,
      entities: [],
      start_date: new Date(this.state.Sdate).toLocaleDateString("en-GB"),
      end_date: new Date(this.state.Edate).toLocaleDateString("en-GB"),
      seq: this.state.seq,
      csps: this.state.csps ? this.state.csps.map(a => a.value || a) : ""
    };
    console.log("urlobj", urlobj);
    /*const url = {
      id: `${changedDetails[0].PO}`,
      name: `${changedDetails[1].budgetName}`,
      currency: `${changedDetails[3].currency}`,
      value: `${changedDetails[2].budgetValue}`,
      thresholds: `${JSON.stringify(changedDetails[8].threshold)}`,
      entities: [],
      start_date: `${new Date(changedDetails[6].Sdate).toLocaleDateString()}`,
      end_date: `${new Date(changedDetails[7].Edate).toLocaleDateString()}`,
      seq: `${changedDetails[4].seq}`,
      csps: `${JSON.stringify(changedDetails[5].csps.map(a => a.value || a))}`
    };*/
    if (this.validator.allValid()) {
      // booya this field is valid!
      this.props.actions.addBudget.findAwsAddBudget(
        urlobj,
        this.state.token,
        this.state.userid
      );
      this.toggleAddModal();
      //alert("You submitted the form and stuff!");
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }

  /*Add budget End */
  /* Edit budget and Assign entity start */
  toggleEntityModel() {
    this.setState({
      entityOpen: !this.state.entityOpen
    });
  }
  toggleEditModal() {
    this.setState(prevState => ({
      editModal: !prevState.editModal
    }));
  }
  closeOnSubmit() {
    this.setState({ editModal: false, entityOpen: false });
  }
  AssignEntity(item) {
    this.toggleEntityModel();
    this.setState({
      PO: item.Id,
      budgetName: item.name,
      budgetValue: item.value,
      currency: item.currency,
      seq: item.seq || 0,
      csps: item.csps,
      Sdate: item.start_date,
      Edate: item.end_date,
      threshold: item.thresholds,
      deficit: item.deficit,
      consumption: item.consumption,
      entities: item.entities
    });
    console.log("entity Item", item);
  }
  EditItem(item) {
    this.toggleEditModal();
    console.log("edit Item", item);
    this.setState({
      PO: item.Id,
      budgetName: item.name,
      budgetValue: item.value,
      currency: item.currency,
      seq: item.seq || 0,
      csps: item.csps,
      Sdate: item.start_date,
      Edate: item.end_date,
      threshold: item.thresholds,
      deficit: item.deficit,
      consumption: item.consumption,
      entities: item.entities
    });
  }
  EditBudget(key, event) {
    var change = {
      PO: this.state.PO,
      budgetName: this.state.budgetName,
      budgetValue: this.state.budgetValue,
      currency: this.state.currency,
      seq: this.state.seq,
      Edate: this.state.Edate,
      Sdate: this.state.Sdate
    };
    const value = event.target.value;
    change[key] = value;
    this.setState(change);
    console.log("change one", change);
    console.log("csps", this.state.csps);
    console.log("tres", this.state.threshold);
    console.log("date", new Date(this.state.Sdate).toLocaleDateString());
    console.log("End date array check one", isArray(this.state.Edate));
  }
  handleEditSubmit() {
    var newvalues = [
      { name: "PO" },
      { name: "budgetName" },
      { name: "budgetValue" },
      { name: "currency" },
      { name: "seq" },
      { name: "csps" },
      { name: "Sdate" },
      { name: "Edate" },
      { name: "threshold" },
      { name: "entities" }
    ];
    var detailsMapping;
    var changedDetails = newvalues.map(
      c =>
        (detailsMapping = {
          [c.name]: this.state[c.name]
        })
    );
    this.detailsMapping = detailsMapping;
    console.log("chages", new Date(this.state.Sdate).toLocaleDateString());
    console.log("End date array check", isArray(this.state.Edate));
    /*const url = `?id=${changedDetails[0].PO}&name=${
      changedDetails[1].budgetName
    }&currency=${changedDetails[3].currency}&value=${
      changedDetails[2].budgetValue
    }&thresholds=${JSON.stringify(
      changedDetails[8].threshold
    )}&entities=${JSON.stringify(
      changedDetails[9].entities
    )}&start_date=${new Date(
      changedDetails[6].Sdate
    ).toLocaleDateString()}&end_date=${new Date(
      changedDetails[7].Edate
    ).toLocaleDateString()}&seq=${changedDetails[4].seq}&csps=${JSON.stringify(
      changedDetails[5].csps.map(a => a.value || a)
    )}&status=UNASSIGNED`;*/
    var endDateArray;

    if (!isArray(this.state.Edate)) {
      endDateArray = this.state.Edate;
    } else {
      endDateArray = new Date(this.state.Edate).toLocaleDateString("en-GB");
    }
    const urlobj = {
      Id: this.state.PO,
      name: this.state.budgetName,
      currency: this.state.currency,
      value: this.state.budgetValue,
      thresholds: this.state.threshold,
      entities: this.state.entities,
      start_date: new Date(this.state.Sdate).toLocaleDateString("en-GB"),
      end_date: endDateArray,
      seq: this.state.seq,
      csps: this.state.csps ? this.state.csps.map(a => a.value || a) : "",
      status: "UNASSIGNED"
    };
    if (this.validator.allValid()) {
      // booya this field is valid!
      this.props.actions.addBudget.findAwsUpdateBudget(
        urlobj,
        this.state.token,
        this.state.userid
      );
      this.closeOnSubmit();
      //alert("You submitted the form and stuff!");
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }
  }
  handleAssignSubmit() {
    var newvalues = [
      { name: "PO" },
      { name: "budgetName" },
      { name: "budgetValue" },
      { name: "currency" },
      { name: "seq" },
      { name: "csps" },
      { name: "Sdate" },
      { name: "Edate" },
      { name: "threshold" },
      { name: "entities" }
    ];
    var detailsMapping;
    var changedDetails = newvalues.map(
      c =>
        (detailsMapping = {
          [c.name]: this.state[c.name]
        })
    );
    this.detailsMapping = detailsMapping;
    console.log(
      "chages",
      `${new Date(changedDetails[6].Sdate).toLocaleDateString()}`
    );

    /*const url = `?id=${changedDetails[0].PO}&name=${
      changedDetails[1].budgetName
    }&currency=${changedDetails[3].currency}&value=${
      changedDetails[2].budgetValue
    }&thresholds=${JSON.stringify(
      changedDetails[8].threshold
    )}&entities=${JSON.stringify(
      changedDetails[9].entities
    )}&start_date=${new Date(
      changedDetails[6].Sdate
    ).toLocaleDateString()}&end_date=${new Date(
      changedDetails[7].Edate
    ).toLocaleDateString()}&seq=${changedDetails[4].seq}&csps=${JSON.stringify(
      changedDetails[5].csps.map(a => a.value || a)
    )}&status=ASSIGNED`;*/

    const urlobj = {
      Id: this.state.PO,
      name: this.state.budgetName,
      currency: this.state.currency,
      value: this.state.budgetValue,
      thresholds: this.state.threshold,
      entities: this.state.entities,
      start_date: this.state.Sdate,
      end_date: this.state.Edate,
      seq: this.state.seq,
      csps: this.state.csps.map(a => a.value || a),
      status: "ASSIGNED"
    };
    this.props.actions.addBudget.findAwsUpdateBudget(
      urlobj,
      this.state.token,
      this.state.userid
    );
    this.closeOnSubmit();
  }
  /*Edit budget and Assign entity End */
  /*Threshold Manage Start */

  addThreshold() {
    //this.setState({ threshold: [...this.state.threshold, ""] });
    //this.setState({ threshold: [...this.state.threshold, ""] });
    this.setState(prevState => ({
      threshold: [
        ...prevState.threshold,
        { type: null, value: null, emails: [] }
      ]
    }));
  }
  handleChange(e, index) {
    let { value, min, max } = e.target;
    //value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    let values = [...this.state.threshold];
    values[index].value = Math.max(
      Number(min),
      Math.min(Number(max), Number(value))
    );
    this.setState({
      threshold: values
    });
    //this.state.threshold.value[index] = e.target.value;
    //this.setState({ threshold: [{ value: this.state.threshold.value }] });
  }
  removeThreshold(index) {
    this.state.threshold.splice(index, 1);
    this.setState({ threshold: this.state.threshold });
  }
  handleTextChange(e, index) {
    //this.state.threshold.text[index] = e.target.value;
    //this.setState({ threshold: [{ text: this.state.threshold.text }] });
    let values = [...this.state.threshold];
    values[index].emails = e;
    this.setState({ threshold: values });
  }
  handleDropdownChange(e, index) {
    let values = [...this.state.threshold];
    values[index].type = e.target.value;
    this.setState({ threshold: values });
  }
  /* Threshold Manage End */

  componentWillReceiveProps(nextProps) {
    /*if (nextProps.entities) {
      this.setState({ isLoading: false });
    }*/
    if (nextProps.deleteBudget) {
      this.props.actions.addBudget.findAwsBudget(
        this.state.token,
        this.state.userid
      );
    } else if (nextProps.updateBudget) {
      this.props.actions.addBudget.findAwsBudget(
        this.state.token,
        this.state.userid
      );
    } else if (nextProps.addBudget) {
      this.props.actions.addBudget.findAwsBudget(
        this.state.token,
        this.state.userid
      );
    }
    if (nextProps.budgetFilterList) {
      this.setState({ budgetList: nextProps.budgetFilterList });
    }
    if (nextProps.awsBudgetList) {
      this.setState({ budgetList: nextProps.awsBudgetList });
    }
  }
  /* Filter the budgets Start */
  toggleDropdown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  budgetListCall(status) {
    this.setState({ ApiUrlstatus: status });
    this.props.actions.addBudget.findAwsfilterBudget(
      status,
      this.state.token,
      this.state.userid
    );
  }
  /* Filter the budgets End */
  render() {
    const { Sdate, Edate, isLoading, classes } = this.state;
    const options = [
      { value: "AWS", label: "AWS" },
      { value: "AZURE", label: "AZURE" },
      { value: "GCP", label: "GCP" }
    ];
    const optionsExist =
      this.state.csps && this.state.csps.map(a => ({ value: a, label: a }));
    // console.log("csps state", this.state.csps && this.state.csps.map(a => a));
    console.log("budget state", isArray(this.state.budgetList));
    //console.log("tree entities", this.state.entities.map(a => a));
    console.log("statessss", this.state);
    //this.props.entities.find(users, { 'age': 1, 'active': true });
    /* if (this.props.entities) {
      var getent = this.props.entities.find(ent => ent.id === "1").Name;
      console.log("ent obj", getent);
    }*/
    return (
      <div>
        <Alert color="info" isOpen={this.state.visible}>
          I am an alert and I will disappear in 2sec.!
        </Alert>
        <Row>
          <Col>
            <Button
              color="primary"
              className="mb-5"
              onClick={() => this.AddItem()}
            >
              Create Budget
            </Button>
          </Col>
          <Col className="text-right">
            <ButtonDropdown
              isOpen={this.state.dropdownOpen}
              toggle={this.toggleDropdown}
            >
              <DropdownToggle caret color="primary">
                Budget Views
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.budgetListCall("UNASSIGNED")}>
                  Unassigned
                </DropdownItem>
                <DropdownItem onClick={() => this.budgetListCall("ASSIGNED")}>
                  Assigned
                </DropdownItem>
                <DropdownItem onClick={() => this.budgetListCall("APPROVED")}>
                  Assigned and Approved
                </DropdownItem>
                <DropdownItem onClick={() => this.budgetListCall("PENDING")}>
                  Pending approval
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </Col>
        </Row>
        <Table size="sm" hover>
          <thead className="bg-light">
            <tr>
              <th>Budget Name</th>
              <th>Currency</th>
              <th>Value</th>
              <th>Deficit</th>
              <th>Consumption</th>
              <th>Assigned CSP's</th>
              <th>Assigned Group Function/Local Market</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {isArray(this.state.budgetList) &&
              this.state.budgetList &&
              this.state.budgetList.map((list, index) => (
                <tr key={index}>
                  <th scope="row">{list.name}</th>
                  <td>{list.currency}</td>
                  <td>{list.value}</td>
                  <td>{list.deficit}</td>
                  <td>{list.consumption}</td>
                  <td>{list.csps && list.csps.join(", ")}</td>
                  <td>
                    {list.entities
                      .map(a =>
                        capitalize(
                          this.props.entities.find(ent => ent.Id === a).Name
                        )
                      )
                      .join(", ")}
                  </td>

                  <td>{list.status}</td>
                  <td>
                    <Button
                      style={paddingButton}
                      color="link"
                      onClick={() => this.ViewBudget(list)}
                    >
                      <Icon style={view}>remove_red_eye</Icon>
                    </Button>
                    <Button
                      style={paddingButton}
                      color="link"
                      onClick={() => this.EditItem(list)}
                    >
                      <Icon style={styling}>create</Icon>
                    </Button>
                    <Button
                      style={paddingButton}
                      color="link"
                      onClick={() => this.DeleteBudget(list)}
                    >
                      <Icon style={buttonStyle}>clear</Icon>
                    </Button>
                    <Button
                      style={paddingButton}
                      color="link"
                      onClick={() => this.AssignEntity(list)}
                    >
                      <Icon style={assign}>account_box</Icon>
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        {/* View Modal Start*/}
        <Modal isOpen={this.state.viewModal} className={this.props.className}>
          <ModalHeader toggle={this.toggleView}>View Budget</ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                <Col sm="2" />
                <Col sm="4" className="text-right">
                  <strong>PO:</strong>
                </Col>
                <Col sm="4">{this.state.PO ? this.state.PO : "-"}</Col>
                <Col sm="2" />
              </Row>
              <Row>
                <Col sm="2" />
                <Col sm="4" className="text-right">
                  <strong>Budget Name:</strong>{" "}
                </Col>
                <Col sm="4">
                  {this.state.budgetName ? this.state.budgetName : "-"}
                </Col>
                <Col sm="2" />
              </Row>
              <Row>
                <Col sm="2" />
                <Col sm="4" className="text-right">
                  <strong>Budget Value:</strong>{" "}
                </Col>
                <Col sm="4">
                  {this.state.budgetValue ? this.state.budgetValue : "-"}
                </Col>
                <Col sm="2" />
              </Row>
              <Row>
                <Col sm="2" />
                <Col sm="4" className="text-right">
                  <strong>Currency:</strong>{" "}
                </Col>
                <Col sm="4">
                  {this.state.currency ? this.state.currency : "-"}
                </Col>
                <Col sm="2" />
              </Row>
              <Row>
                <Col sm="2" />
                <Col sm="4" className="text-right">
                  <strong>Sequence:</strong>{" "}
                </Col>
                <Col sm="4">{this.state.seq ? this.state.seq : "-"}</Col>
                <Col sm="2" />
              </Row>
              <Row>
                <Col sm="2" />
                <Col sm="4" className="text-right">
                  <strong>Associated Csps:</strong>{" "}
                </Col>
                <Col sm="4">
                  {this.state.csps && this.state.csps.join(", ")}
                </Col>
                <Col sm="2" />
              </Row>
              <Row>
                <Col sm="2" />
                <Col sm="4" className="text-right">
                  <strong>Start Date:</strong>{" "}
                </Col>
                <Col sm="4">{this.state.Sdate ? this.state.Sdate : "-"}</Col>
                <Col sm="2" />
              </Row>
              <Row>
                <Col sm="2" />
                <Col sm="4" className="text-right">
                  <strong>End Date:</strong>{" "}
                </Col>
                <Col sm="4">{this.state.Edate ? this.state.Edate : "-"}</Col>
                <Col sm="2" />
              </Row>
              <Row>
                <Col sm="2" />
                <Col sm="4" className="text-right">
                  <strong>Thresholds:</strong>{" "}
                </Col>
                <Col sm="4">
                  {this.state.threshold &&
                    this.state.threshold.map(a => a.tracked_value).join(", ")}
                </Col>
                <Col sm="2" />
              </Row>
              <Row>
                <Col sm="2" />
                <Col sm="4" className="text-right">
                  <strong>Deficit:</strong>{" "}
                </Col>
                <Col sm="4">
                  {this.state.deficit ? this.state.deficit : "-"}
                </Col>
                <Col sm="2" />
              </Row>
              <Row>
                <Col sm="2" />
                <Col sm="4" className="text-right">
                  <strong>Consumption:</strong>{" "}
                </Col>
                <Col sm="4">
                  {this.state.consumption ? this.state.consumption : "-"}
                </Col>
                <Col sm="2" />
              </Row>
            </Container>
          </ModalBody>
        </Modal>
        {/* View Modal End*/}
        <Modal
          isOpen={this.state.addModal}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggleAddModal}>Add Budget</ModalHeader>
          <ModalBody>
            <form>
              <div className="form-group">
                <label>PO</label>
                <input
                  type="text"
                  pattern="[A-Za-z0-9]"
                  className="form-control"
                  value={this.state.PO}
                  onChange={e => this.AddTodo("PO", e)}
                />

                <div className="errorMsg">
                  {this.validator.message(
                    "PO",
                    this.state.PO,
                    "required|alpha_num_dash"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Budget Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.budgetName}
                  onChange={e => this.AddTodo("budgetName", e)}
                />
                {/*<div className="errorMsg">{this.state.errors.budgetName}</div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "budgetName",
                    this.state.budgetName,
                    "required|alpha_num_dash"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Budget Value</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.budgetValue}
                  size="20"
                  onChange={e => this.AddTodo("budgetValue", e)}
                />
                {/*<div className="errorMsg">{this.state.errors.budgetValue}</div>*/}

                <div className="errorMsg">
                  {this.validator.message(
                    "budgetValue",
                    this.state.budgetValue,
                    "required|numeric"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Currency</label>
                <select
                  className="form-control"
                  value={this.state.currency}
                  onChange={e => this.AddTodo("currency", e)}
                >
                  <option value="">...Select...</option>
                  <option value="GBP">GBP</option>
                  <option value="USD">USD</option>
                  <option value="EURO">EURO</option>
                </select>
                {/*<div className="errorMsg">{this.state.errors.currency}</div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "currency",
                    this.state.currency,
                    "required"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Consumption Sequence</label>
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  value={this.state.seq}
                  onChange={e => this.AddTodo("seq", e)}
                />
                {/*<div className="errorMsg">{this.state.errors.seq}</div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "seq",
                    this.state.seq,
                    "required|numeric"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Start Date</label>
                <Flatpickr
                  type="date"
                  style={dateStyle}
                  value={Sdate}
                  onChange={Sdate => {
                    this.setState({ Sdate });
                  }}
                />
                {/*<div className="errorMsg">{this.state.errors.Sdate}</div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "Sdate",
                    this.state.Sdate,
                    "required"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>End Date</label>
                <Flatpickr
                  style={dateStyle}
                  value={Edate}
                  options={{ minDate: "today" }}
                  onChange={Edate => this.setState({ Edate })}
                />
                {/* <div className="errorMsg">{this.state.errors.Edate}</div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "Edate",
                    this.state.Edate,
                    "required"
                  )}
                </div>
              </div>
              <div>
                <Row className="mb-2">
                  <Col xs="4">
                    <label>Thresholds</label>
                  </Col>
                  <Col xs="4">
                    <Button
                      color="primary"
                      onClick={e => this.addThreshold(e)}
                      size="sm"
                    >
                      Add Threshold
                    </Button>
                  </Col>
                  <Col xs="4" />
                </Row>
                <Row>
                  <Col xs="4">Threshold Type</Col>
                  <Col xs="4">Threshold Value</Col>
                  <Col xs="4">Notification Emails</Col>
                </Row>
                {this.state.threshold &&
                  this.state.threshold.map((tres, index) => {
                    return (
                      <div className="form-group" key={index}>
                        <Row>
                          <Col xs="3">
                            <select
                              className="form-control"
                              value={tres.type || ""}
                              onChange={e =>
                                this.handleDropdownChange(e, index)
                              }
                            >
                              <option value="">...Select...</option>
                              <option value="p">Percentage</option>
                              <option value="a">Amount value</option>
                            </select>
                            <div className="errorMsg">
                              {this.validator.message(
                                "type",
                                tres.type,
                                "required"
                              )}
                            </div>
                          </Col>

                          <Col xs="3">
                            <input
                              type="number"
                              className="form-control"
                              value={tres.value}
                              //step="1"
                              min="0"
                              max={
                                tres.type === "p"
                                  ? "100"
                                  : this.state.budgetValue
                              }
                              onChange={e => this.handleChange(e, index)}
                            />
                            <div className="errorMsg">
                              {this.validator.message(
                                "value",
                                tres.value,
                                "required|numeric"
                              )}
                            </div>
                          </Col>
                          <Col xs="5">
                            <ReactMultiEmail
                              placeholder="Input your Email Address"
                              emails={tres.emails}
                              onChange={_emails =>
                                this.handleTextChange(_emails, index)
                              }
                              /* onChange={_emails => {
                                this.setState({ emails: _emails });
                              }}*/
                              disabled={tres.type ? false : true}
                              getLabel={(email, index, removeEmail) => {
                                return (
                                  <div data-tag key={index}>
                                    {email}
                                    <span
                                      data-tag-handle
                                      onClick={() => removeEmail(index)}
                                    >
                                      ×
                                    </span>
                                  </div>
                                );
                              }}
                            />
                            <div className="errorMsg">
                              {this.validator.message(
                                "notificationEmails",
                                tres.emails,
                                "required"
                              )}
                            </div>
                          </Col>
                          <Col xs="1">
                            <Button
                              color="link"
                              style={paddingButton}
                              onClick={() => this.removeThreshold(index)}
                            >
                              <Icon style={buttonStyle}>clear</Icon>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                {/*<div className="errorMsg">{this.state.errors.threshold}</div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "threshold",
                    this.state.threshold,
                    "required"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Associated CSP's</label>
                <Select
                  isMulti
                  name="colors"
                  options={options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  value={this.state.csps}
                  onChange={csps => {
                    this.setState({ csps });
                  }}
                />
                {/*<div className="errorMsg">{this.state.errors.csps}</div>*/}
                <div className="errorMsg">
                  {this.validator.message("csps", this.state.csps, "required")}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col" />
                <div className="col">
                  <Button
                    color="secondary"
                    onClick={this.toggleAddModal}
                    className="w-100"
                  >
                    Cancel
                  </Button>{" "}
                </div>
                <div className="col">
                  <Button
                    color="primary"
                    className="w-100"
                    onClick={this.handleAddSubmit}
                  >
                    Save
                  </Button>
                </div>
                <div className="col" />
              </div>
            </form>
          </ModalBody>
          <ModalFooter />
        </Modal>
        {/*Edit modal start*/}
        <Modal
          isOpen={this.state.editModal}
          className={this.props.className}
          size="lg"
        >
          <ModalHeader toggle={this.toggleEditModal}>Update Budget</ModalHeader>
          <ModalBody>
            <form>
              <div className="form-group">
                <label>PO</label>
                <input
                  type="text"
                  pattern="[A-Za-z0-9]"
                  className="form-control"
                  value={this.state.PO}
                  onChange={e => this.EditBudget("PO", e)}
                />
                {/*<div className="errorMsg">{this.state.editerrors.PO}</div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "PO",
                    this.state.PO,
                    "required|alpha_num_dash"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Budget Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.budgetName}
                  onChange={e => this.EditBudget("budgetName", e)}
                />
                {/*<div className="errorMsg">
                  {this.state.editerrors.budgetName}
                </div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "budgetName",
                    this.state.budgetName,
                    "required|alpha_num_dash"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Budget Value</label>
                <input
                  type="text"
                  className="form-control"
                  value={this.state.budgetValue}
                  onChange={e => this.EditBudget("budgetValue", e)}
                />
                {/*<div className="errorMsg">
                  {this.state.editerrors.budgetValue}
              </div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "budgetValue",
                    this.state.budgetValue,
                    "required|numeric"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Currency</label>
                <select
                  className="form-control"
                  value={this.state.currency}
                  onChange={e => this.EditBudget("currency", e)}
                >
                  <option value="">...Select...</option>
                  <option value="GBP">GBP</option>
                  <option value="USD">USD</option>
                  <option value="EURO">EURO</option>
                </select>
                {/*<div className="errorMsg">{this.state.editerrors.currency}</div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "currency",
                    this.state.currency,
                    "required"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Consumption Sequence</label>
                <input
                  type="number"
                  min="0"
                  className="form-control"
                  value={this.state.seq}
                  onChange={e => this.EditBudget("seq", e)}
                />
                {/*<div className="errorMsg">{this.state.editerrors.seq}</div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "seq",
                    this.state.seq,
                    "required|numeric"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Start Date</label>
                <Flatpickr
                  type="date"
                  style={dateStyle}
                  value={this.state.Sdate}
                  options={{ dateFormat: "d-m-Y" }}
                  onChange={Sdate => {
                    this.setState({ Sdate });
                  }}
                />
                {/*<div className="errorMsg">{this.state.editerrors.Sdate}</div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "Sdate",
                    this.state.Sdate,
                    "required"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>End Date</label>
                <Flatpickr
                  style={dateStyle}
                  value={this.state.Edate}
                  options={{ minDate: "today", dateFormat: "d-m-Y" }}
                  onChange={Edate => {
                    this.setState({ Edate });
                  }}
                />
                {/*<div className="errorMsg">{this.state.editerrors.Edate}</div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "Edate",
                    this.state.Edate,
                    "required"
                  )}
                </div>
              </div>
              <div>
                <Row className="mb-2">
                  <Col xs="4">
                    <label>Thresholds</label>
                  </Col>
                  <Col xs="4">
                    <Button
                      color="primary"
                      onClick={e => this.addThreshold(e)}
                      size="sm"
                    >
                      Add Threshold
                    </Button>
                  </Col>
                  <Col xs="4" />
                </Row>

                <Row>
                  <Col xs="4">Threshold Type</Col>
                  <Col xs="4">Threshold Value</Col>
                  <Col xs="4">Notification Emails</Col>
                </Row>
                {this.state.threshold &&
                  this.state.threshold.map((tres, index) => {
                    return (
                      <div className="form-group" key={index}>
                        <Row>
                          <Col xs="3">
                            <select
                              className="form-control"
                              value={tres.type || ""}
                              onChange={e =>
                                this.handleDropdownChange(e, index)
                              }
                            >
                              <option value="">...Select...</option>
                              <option value="p">Percentage</option>
                              <option value="a">Amount value</option>
                            </select>
                            <div className="errorMsg">
                              {this.validator.message(
                                "type",
                                tres.type,
                                "required"
                              )}
                            </div>
                          </Col>

                          <Col xs="3">
                            <input
                              type="number"
                              className="form-control"
                              value={tres.value}
                              step="1"
                              min="0"
                              max={
                                tres.type === "p"
                                  ? "100"
                                  : this.state.budgetValue
                              }
                              onChange={e => this.handleChange(e, index)}
                            />
                            <div className="errorMsg">
                              {this.validator.message(
                                "value",
                                tres.value,
                                "required|numeric"
                              )}
                            </div>
                          </Col>
                          <Col xs="5">
                            <ReactMultiEmail
                              placeholder="Input your Email Address"
                              emails={tres.emails}
                              onChange={_emails =>
                                this.handleTextChange(_emails, index)
                              }
                              /* onChange={_emails => {
                                this.setState({ emails: _emails });
                              }}*/
                              disabled={tres.type ? false : true}
                              getLabel={(email, index, removeEmail) => {
                                return (
                                  <div data-tag key={index}>
                                    {email}
                                    <span
                                      data-tag-handle
                                      onClick={() => removeEmail(index)}
                                    >
                                      ×
                                    </span>
                                  </div>
                                );
                              }}
                            />
                            <div className="errorMsg">
                              {this.validator.message(
                                "email",
                                tres.emails,
                                "required"
                              )}
                            </div>
                          </Col>
                          <Col xs="1">
                            <Button
                              color="link"
                              style={paddingButton}
                              onClick={() => this.removeThreshold(index)}
                            >
                              <Icon style={buttonStyle}>clear</Icon>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                {/*<div className="errorMsg">
                  {this.state.editerrors.threshold}
                </div>*/}
                <div className="errorMsg">
                  {this.validator.message(
                    "threshold",
                    this.state.threshold,
                    "required"
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Associated CSP's</label>
                <Select
                  isMulti
                  name="colors"
                  options={options}
                  defaultValue={optionsExist && optionsExist.map(a => a)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={csps => {
                    this.setState({ csps });
                  }}
                />
                {/*<div className="errorMsg">{this.state.editerrors.csps}</div>*/}
                <div className="errorMsg">
                  {this.validator.message("csps", this.state.csps, "required")}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col" />
                <div className="col">
                  <Button
                    color="secondary"
                    onClick={this.toggleEditModal}
                    className="w-100"
                  >
                    Cancel
                  </Button>{" "}
                </div>
                <div className="col">
                  <Button
                    color="primary"
                    className="w-100"
                    onClick={this.handleEditSubmit}
                  >
                    Save
                  </Button>
                </div>
                <div className="col" />
              </div>
            </form>
          </ModalBody>
          <ModalFooter />
        </Modal>
        {/*Edit modal End*/}
        <Modal isOpen={this.state.entityOpen} className={this.props.className}>
          <ModalHeader toggle={this.toggleEntityModel}>
            Assign Entity
          </ModalHeader>
          <ModalBody>
            {/*{isLoading === true && (
              <div style={stylingProgress}>
                <CircularProgress
                  className={classes.progress}
                  color="secondary"
                />
              </div>
            )}
            {isLoading === false && (*/}
            <div className="mt-5 mb-5">
              <form>
                <div className="row mt-4">
                  <div className="col">
                    <div className="form-group">
                      <label className="mr-2"> Select Entity</label>
                      <TreeSelect
                        showSearch
                        style={{ width: 300 }}
                        defaultValue={this.state.entities.map(a => a)}
                        dropdownStyle={{ maxHeight: 150, overflow: "auto" }}
                        placeholder="Please select"
                        treeData={this.props.entities.map(a => ({
                          id: a.Id,
                          pId: a.Parent,
                          value: a.Id,
                          title: capitalize(a.Name)
                        }))}
                        treeDataSimpleMode={true}
                        allowClear
                        multiple
                        treeDefaultExpandAll
                        onChange={a => this.onChange(a, this.props.entities)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col" />
                  <div className="col">
                    <Button
                      color="secondary"
                      onClick={this.toggleEntityModel}
                      className="w-100"
                    >
                      Cancel
                    </Button>{" "}
                  </div>
                  <div className="col">
                    <Button
                      color="primary"
                      className="w-100"
                      onClick={this.handleAssignSubmit}
                    >
                      Save
                    </Button>
                  </div>
                  <div className="col" />
                </div>
              </form>
            </div>
            {/*})}*/}
          </ModalBody>
          <ModalFooter />
        </Modal>
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    entities: state.entities.entityList,
    addBudget: state.awsBudget.awsBudgetAddData,
    deleteBudget: state.awsBudget.awsBudgetDeleteData,
    updateBudget: state.awsBudget.awsBudgetUpdateData,
    budgetFilterList: state.awsBudget.awsBudgetFilterData,
    awsBudgetList: state.awsBudget.awsBudgetData
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      entities: bindActionCreators(entityServices, dispatch),
      addBudget: bindActionCreators(BudgetAwsServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetAwsLanding);
