import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { TreeSelect } from "antd";
import { connect } from "react-redux";
import { capitalize } from "lodash";
import ls from "local-storage";
import { NewEntityServices } from "../../../services";
import { bindActionCreators } from "redux";
import { Checkbox } from "antd";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Table
} from "reactstrap";
import { filter } from "lodash";
const styling = {
  marginLeft: "auto",
  marginRight: "auto",
  marginTop: "40px",
  marginBottom: "40px",
  textAlign: "center"
};
class Unassigned extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: undefined,
      entities: undefined,
      classes: props,

      userid: ls.get("customerId"),
      token: ls.get("idToken"),
      csps: ["aws", "gcp", "azure"],
      csp: "all",
      allChecked: false,
      checkList: [],
      list: props.newEntities
        ? props.newEntities.accounts.map(list => ({
            id: list.Name,
            name: list.Name,
            number: list.CloudAccountId,
            isChecked: false
          }))
        : [],
      Loading: true,
      dropdownOpen: false
    };
    console.log("proopopo", props);
    this.handleAddSubmit = this.handleAddSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  onChange = value => {
    this.setState({ entities: value });
  };
  componentWillMount() {
    this.props.actions.newEntities.findListUnassignedEntities(
      this.state.token,
      this.state.userid,
      this.state.csp
    );
  }
  /*onSelect = value => {
    console.log("Select:", getPath(value));
  };*/
  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  handleAddSubmit() {
    var getAccounts = this.state.checkList.map(a =>
      this.props.newEntities.accounts.find(ent => ent.Name === a.name)
    );
    //console.log("getent", getAccounts);
    const urlobj = {
      entityIds: this.state.entities,
      cloudAccounts: getAccounts
    };
    this.props.actions.newEntities.FindUpdatedUnassignedAccounts(
      this.state.token,
      this.state.userid,
      urlobj
    );
    //console.log("urlobj", urlobj);
  }
  handleChange = e => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    console.log("checkec", itemName);
    this.setState(prevState => {
      let { list, allChecked, checkList } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        list = list.map(item => ({ ...item, isChecked: checked }));
        checkList = filter(list, "isChecked");
      } else {
        list = list.map(item =>
          item.name === itemName ? { ...item, isChecked: checked } : item
        );
        allChecked = list.every(item => item.isChecked);
        checkList = filter(list, "isChecked");
      }
      return { list, allChecked, checkList };
    });
  };

  renderList = () => {
    return this.state.list.map(item => (
      <tr key={item.id}>
        <th scope="row">
          {" "}
          <input
            type="checkbox"
            name={item.name}
            value={item.name}
            checked={item.isChecked}
            onChange={this.handleChange}
          />
        </th>
        <td>{item.name}</td>
        <td>{item.number}</td>
      </tr>
    ));
  };
  componentWillReceiveProps(nextProps) {
    //console.log("nextProps", nextProps);
    this.setState({
      list:
        nextProps.newEntities &&
        nextProps.newEntities.accounts.map(list => ({
          id: list.Name,
          name: list.Name,
          number: list.CloudAccountId,
          isChecked: false
        }))
    });
    if (nextProps.updateUnassigned) {
      this.props.actions.newEntities.findListUnassignedEntities(
        this.state.token,
        this.state.userid,
        this.state.csp
      );
      this.setState({ checkList: [] });
    } else if (nextProps.newEntities) {
      this.setState({ entities: [] });
    }
    if (nextProps.newEntities && nextProps.newEntities.accounts) {
      this.setState({ Loading: false });
    }
  }
  filtering(a) {
    this.setState({ csp: a });
    this.props.actions.newEntities.findListUnassignedEntities(
      this.state.token,
      this.state.userid,
      a
    );
  }
  render() {
    //console.log("this.state.checkedList", this.state.checkedList);
    //console.log("this.state.entities", this.state.entities);
    //console.log("this.state.plainoptions", this.state.plainOptions);
    console.log("list", this.state.list);
    return (
      <div>
        {this.state.Loading ? (
          <div style={styling}>
            <CircularProgress className={this.state.classes.progress} color="secondary" />
          </div>
        ) : (
          <Form>
            <Row>
              <Col className="text-right">
                <ButtonDropdown className="mb-4"
                  isOpen={this.state.dropdownOpen}
                  toggle={this.toggle}
                >
                  <DropdownToggle caret color="primary">
                    Filter By Csp
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => this.filtering("all")}>
                      All Csps
                    </DropdownItem>
                    {this.state.csps.map(a => (
                      <DropdownItem key={a} onClick={() => this.filtering(a)}>
                        {capitalize(a)}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </ButtonDropdown>
              </Col>
            </Row>
            {this.state.list && this.state.list.length > 0 ? (
              <div>
                <div className="antCheckboxEntity">
                  <Table size="sm">
                    <thead>
                      <tr>
                        <th className="mw-10">
                          {" "}
                          <input
                            type="checkbox"
                            name="checkAll"
                            checked={this.state.allChecked}
                            onChange={this.handleChange}
                          />
                        </th>
                        <th>Project Id</th>
                        <th>Project Number</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderList()}</tbody>
                  </Table>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <div className="form-group">
                      <label className="mr-2"> Select Entity</label>
                      <TreeSelect
                        showSearch
                        style={{ width: 300 }}
                        defaultValue={
                          this.state.entities
                            ? this.state.entities.map(a => a)
                            : undefined
                        }
                        dropdownStyle={{ maxHeight: 150, overflow: "auto" }}
                        placeholder="Please select"
                        treeData={this.props.entities.map(a => ({
                          id: a.Id,
                          pId: a.Parent,
                          value: a.Id,
                          title: capitalize(a.Name)
                        }))}
                        treeDataSimpleMode={true}
                        allowClear
                        multiple
                        treeDefaultExpandAll
                        onChange={a => this.onChange(a, this.props.entities)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <Button
                      color="primary"
                      className="w-100"
                      onClick={this.handleAddSubmit}
                    >
                      Save
                    </Button>
                  </div>
                  <div className="col">
                    <Button
                      color="secondary"
                      onClick={this.toggleEntityModel}
                      className="w-100"
                    >
                      Cancel
                    </Button>{" "}
                  </div>
                  <div className="col" />
                  <div className="col" />
                </div>{" "}
              </div>
            ) : (
              <div> No accounts found</div>
            )}
          </Form>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    entities: state.entities.entityList,
    newEntities: state.newEntity.newEntity,
    updateUnassigned: state.newEntity.updateUnassigned
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      newEntities: bindActionCreators(NewEntityServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Unassigned);
