import React, { Component } from "react";
import OnboardingGcpLanding from "./gcpOnboardingLanding";
class OnBoardingGcp extends Component {
  render() {
    return (
      <div>
        <OnboardingGcpLanding />
      </div>
    );
  }
}

export default OnBoardingGcp;
