import React, { Component } from "react";
import BudgetGcpLanding from "./gcpBudgetLanding";
class BudgetGcp extends Component {
  render() {
    return (
      <div>
        <BudgetGcpLanding />
      </div>
    );
  }
}

export default BudgetGcp;
