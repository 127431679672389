import { createAction } from "redux-actions";
import { ActionTypes } from "../constants";

export const costTableFindRequest = createAction(
  ActionTypes.COST_TABLE_FIND_REQUEST
);
export const costTableFindResponse = createAction(
  ActionTypes.COST_TABLE_FIND_RESPONSE
);
export const costTableFindFailure = createAction(
  ActionTypes.COST_TABLE_FIND_FAILURE
);
export default {};