export const USER_PROFILE_FIND_REQUEST = "USER_PROFILE_FIND_REQUEST";
export const USER_PROFILE_FIND_RESPONSE = "USER_PROFILE_FIND_RESPONSE";
export const USER_PROFILE_FIND_FAILURE = "USER_PROFILE_FIND_FAILURE";
export const GET_ALL_CONTACTS = "GET_ALL_CONTACTS";
export const CREATE_NEW_CONTACT = "CREATE_NEW_CONTACT";
export const REMOVE_CONTACT = "REMOVE_CONTACT";
export const AUTH_USERS_FIND_REQUEST = "AUTH_USERS_FIND_REQUEST";
export const AUTH_USERS_FIND_RESPONSE = "AUTH_USERS_FIND_RESPONSE";
export const AUTH_USERS_FIND_FAILURE = "AUTH_USERS_FIND_FAILURE";
export const COST_TABLE_FIND_REQUEST = "COST_TABLE_FIND_REQUEST";
export const COST_TABLE_FIND_RESPONSE = "COST_TABLE_FIND_RESPONSE";
export const COST_TABLE_FIND_FAILURE = "COST_TABLE_FIND_FAILURE";
export const AZURE_TABLE_FIND_REQUEST = "AZURE_TABLE_FIND_REQUEST";
export const AZURE_TABLE_FIND_RESPONSE = "AZURE_TABLE_FIND_RESPONSE";
export const AZURE_TABLE_FIND_FAILURE = "AZURE_TABLE_FIND_FAILURE";
export const AZURE_CHART_REQUEST = "AZURE_CHART_REQUEST";
export const AZURE_CHART_RESPONSE = "AZURE_CHART_RESPONSE";
export const AZURE_CHART_FAILURE = "AZURE_CHART_FAILURE";
export const AWS_CHART_REQUEST = "AWS_CHART_REQUEST";
export const AWS_CHART_RESPONSE = "AWS_CHART_RESPONSE";
export const AWS_CHART_FAILURE = "AWS_CHART_FAILURE";
export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_RESPONSE = "USER_DELETE_RESPONSE";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";
export const USER_EDIT_REQUEST = "USER_EDIT_REQUEST";
export const USER_EDIT_RESPONSE = "USER_EDIT_RESPONSE";
export const USER_EDIT_FAILURE = "USER_EDIT_FAILURE";
export const USER_ADD_REQUEST = "USER_ADD_REQUEST";
export const USER_ADD_RESPONSE = "USER_ADD_RESPONSE";
export const USER_ADD_FAILURE = "USER_ADD_FAILURE";
export const GCP_CHART_REQUEST = "GCP_CHART_REQUEST";
export const GCP_CHART_RESPONSE = "GCP_CHART_RESPONSE";
export const GCP_CHART_FAILURE = "GCP_CHART_FAILURE";
export const GCP_TABLE_REQUEST = "GCP_TABLE_REQUEST";
export const GCP_TABLE_RESPONSE = "GCP_TABLE_RESPONSE";
export const GCP_TABLE_FAILURE = "GCP_TABLE_FAILURE";
export const LIST_ENTITY_REQUEST = "LIST_ENTITY_REQUEST";
export const LIST_ENTITY_RESPONSE = "LIST_ENTITY_RESPONSE";
export const LIST_ENTITY_FAILURE = "LIST_ENTITY_FAILURE";
export const LIST_ENTITY_DELETE_REQUEST = "LIST_ENTITY_DELETE_REQUEST";
export const LIST_ENTITY_DELETE_RESPONSE = "LIST_ENTITY_DELETE_RESPONSE";
export const LIST_ENTITY_DELETE_FAILURE = "LIST_ENTITY_DELETE_FAILURE";
export const LIST_ENTITY_ADD_REQUEST = "LIST_ENTITY_ADD_REQUEST";
export const LIST_ENTITY_ADD_RESPONSE = "LIST_ENTITY_ADD_RESPONSE";
export const LIST_ENTITY_ADD_FAILURE = "LIST_ENTITY_ADD_FAILURE";
export const LIST_ENTITY_EDIT_REQUEST = "LIST_ENTITY_EDIT_REQUEST";
export const LIST_ENTITY_EDIT_RESPONSE = "LIST_ENTITY_EDIT_RESPONSE";
export const LIST_ENTITY_EDIT_FAILURE = "LIST_ENTITY_EDIT_FAILURE";
export const LIST_ENTITY_CHANGE_REQUEST = "LIST_ENTITY_CHANGE_REQUEST";
export const LIST_ENTITY_CHANGE_RESPONSE = "LIST_ENTITY_CHANGE_RESPONSE";
export const LIST_ENTITY_CHANGE_FAILURE = "LIST_ENTITY_CHANGE_FAILURE";
export const BUDGET_GET_REQUEST = "BUDGET_GET_REQUEST";
export const BUDGET_GET_RESPONSE = "BUDGET_GET_RESPONSE";
export const BUDGET_GET_FAILURE = "BUDGET_GET_FAILURE";
export const BUDGET_ADD_REQUEST = "BUDGET_ADD_REQUEST";
export const BUDGET_ADD_RESPONSE = "BUDGET_ADD_RESPONSE";
export const BUDGET_ADD_FAILURE = "BUDGET_ADD_FAILURE";
export const BUDGET_DELETE_REQUEST = "BUDGET_DELETE_REQUEST";
export const BUDGET_DELETE_RESPONSE = "BUDGET_DELETE_RESPONSE";
export const BUDGET_DELETE_FAILURE = "BUDGET_DELETE_FAILURE";
export const BUDGET_UPDATE_REQUEST = "BUDGET_UPDATE_REQUEST";
export const BUDGET_UPDATE_RESPONSE = "BUDGET_UPDATE_RESPONSE";
export const BUDGET_UPDATE_FAILURE = "BUDGET_UPDATE_FAILURE";
export const BUDGET_FILTER_REQUEST = "BUDGET_FILTER_REQUEST";
export const BUDGET_FILTER_RESPONSE = "BUDGET_FILTER_RESPONSE";
export const BUDGET_FILTER_FAILURE = "BUDGET_FILTER_FAILURE";
export const GCP_ONBOARD_LIST_PROJECTS_REQUEST =
  "GCP_ONBOARD_LIST_PROJECTS_REQUEST";
export const GCP_ONBOARD_LIST_PROJECTS_RESPONSE =
  "GCP_ONBOARD_LIST_PROJECTS_RESPONSE";
export const GCP_ONBOARD_LIST_PROJECTS_FAILURE =
  "GCP_ONBOARD_LIST_PROJECTS_FAILURE";
export const AWS_ONBOARDING_REQUEST = "AWS_ONBOARDING_REQUEST";
export const AWS_ONBOARDING_RESPONSE = "AWS_ONBOARDING_RESPONSE";
export const AWS_ONBOARDING_FAILURE = "AWS_ONBOARDING_FAILURE";
export const GCP_ONBOARD_LIST_PRO_REQUEST = "GCP_ONBOARD_LIST_PRO_REQUEST";
export const GCP_ONBOARD_LIST_PRO_RESPONSE = "GCP_ONBOARD_LIST_PRO_RESPONSE";
export const GCP_ONBOARD_LIST_PRO_FAILURE = "GCP_ONBOARD_LIST_PRO_FAILURE";
export const GCP_ONBOARD_LIST_ORG_REQUEST = "GCP_ONBOARD_LIST_ORG_REQUEST";
export const GCP_ONBOARD_LIST_ORG_RESPONSE = "GCP_ONBOARD_LIST_ORG_RESPONSE";
export const GCP_ONBOARD_LIST_ORG_FAILURE = "GCP_ONBOARD_LIST_ORG_FAILURE";
export const GCP_ONBOARD_UPDATE_ENTITY_REQUEST =
  "GCP_ONBOARD_UPDATE_ENTITY_REQUEST";
export const GCP_ONBOARD_UPDATE_ENTITY_RESPONSE =
  "GCP_ONBOARD_UPDATE_ENTITY_RESPONSE";
export const GCP_ONBOARD_UPDATE_ENTITY_FAILURE =
  "GCP_ONBOARD_UPDATE_ENTITY_FAILURE";

/*Entitiy Calls NEW*/

export const LIST_UNASSIGNED_ACCOUNTS_REQUEST =
  "LIST_UNASSIGNED_ACCOUNTS_REQUEST";
export const LIST_UNASSIGNED_ACCOUNTS_RESPONSE =
  "LIST_UNASSIGNED_ACCOUNTS_RESPONSE";
export const LIST_UNASSIGNED_ACCOUNTS_FAILURE =
  "LIST_UNASSIGNED_ACCOUNTS_FAILURE";
export const GET_ENTITY_CLOUD_ACCOUNTS_REQUEST =
  "GET_ENTITY_CLOUD_ACCOUNTS_REQUEST";
export const GET_ENTITY_CLOUD_ACCOUNTS_RESPONSE =
  "GET_ENTITY_CLOUD_ACCOUNTS_RESPONSE";
export const GET_ENTITY_CLOUD_ACCOUNTS_FAILURE =
  "GET_ENTITY_CLOUD_ACCOUNTS_FAILURE";
export const UPDATE_UNASSIGNED_ACCOUNTS_REQUEST =
  "UPDATE_UNASSIGNED_ACCOUNTS_REQUEST";
export const UPDATE_UNASSIGNED_ACCOUNTS_RESPONSE =
  "UPDATE_UNASSIGNED_ACCOUNTS_RESPONSE";
export const UPDATE_UNASSIGNED_ACCOUNTS_FAILURE =
  "UPDATE_UNASSIGNED_ACCOUNTS_FAILURE";
export const UPDATE_ASSIGNED_ACCOUNTS_REQUEST =
  "UPDATE_ASSIGNED_ACCOUNTS_REQUEST";
export const UPDATE_ASSIGNED_ACCOUNTS_RESPONSE =
  "UPDATE_ASSIGNED_ACCOUNTS_RESPONSE";
export const UPDATE_ASSIGNED_ACCOUNTS_FAILURE =
  "UPDATE_ASSIGNED_ACCOUNTS_FAILURE";
/*Project scheduler */
export const GET_NEW_GCP_PROJECTS_REQUEST = "GET_NEW_GCP_PROJECTS_REQUEST";
export const GET_NEW_GCP_PROJECTS_RESPONSE = "GET_NEW_GCP_PROJECTS_RESPONSE";
export const GET_NEW_GCP_PROJECTS_FAILURE = "GET_NEW_GCP_PROJECTS_FAILURE";
