import { handleActions } from "redux-actions";
import assignIn from "lodash/assignIn";

import { BudgetGetActions } from "../actions";

const initialState = {};
export default handleActions(
  {
    [BudgetGetActions.BudgetGetRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [BudgetGetActions.BudgetGetResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        awsBudgetData: action.payload
      }),
    [BudgetGetActions.BudgetGetFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [BudgetGetActions.BudgetAddRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [BudgetGetActions.BudgetAddResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        awsBudgetAddData: action.payload
      }),
    [BudgetGetActions.BudgetAddFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [BudgetGetActions.BudgetDeleteRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [BudgetGetActions.BudgetDeleteResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        awsBudgetDeleteData: action.payload
      }),
    [BudgetGetActions.BudgetDeleteFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [BudgetGetActions.BudgetUpdateRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [BudgetGetActions.BudgetUpdateResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        awsBudgetUpdateData: action.payload
      }),
    [BudgetGetActions.BudgetUpdateFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      }),
    [BudgetGetActions.BudgetFilterRequest]: (state, action) => {
      assignIn({}, state, {
        isFetching: !action.error
      });
    },
    [BudgetGetActions.BudgetFilterResponse]: (state, action) =>
      assignIn({}, state, {
        isFetching: false,
        awsBudgetFilterData: action.payload
      }),
    [BudgetGetActions.BudgetFilterFailure]: state =>
      assignIn({}, state, {
        isFetching: false
      })
  },
  initialState
);
