import { createAction } from "redux-actions";
import { ActionTypes } from "../constants";

export const azureTableFindRequest = createAction(
  ActionTypes.AZURE_TABLE_FIND_REQUEST
);
export const azureTableFindResponse = createAction(
  ActionTypes.AZURE_TABLE_FIND_RESPONSE
);
export const azureTableFindFailure = createAction(
  ActionTypes.AZURE_TABLE_FIND_FAILURE
);
export default {};
