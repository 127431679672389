import React, { Component } from "react";
import { gcpCostReportServices, entityServices } from "../../services";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GCPCostReport from "./gcpCostReport";
import ls from "local-storage";
class GCPTableMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isLoading: true,
      userid: ls.get("customerId"),
      costToken: ls.get("idToken"),
      entity: !ls.get("org")
        ? `[${JSON.stringify(ls.get("InitEntityId"))}]`
        : `[${JSON.stringify(ls.get("org"))}]`
    };
    this.props.actions.gcpData.findGcpChartData(
      this.state.costToken,
      this.state.userid,
      this.state.entity
    );
    this.props.actions.gcpData.findGcpTableData(
      this.state.costToken,
      this.state.userid,
      this.state.entity
    );
  }
  render() {
    return (
      <div>
        <GCPCostReport
          gcpTableData={this.props.gcpTableData}
          gcpChartData={this.props.gcpChartData}
          entities={this.props.entities}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    entities: state.entities.entityList,
    gcpTableData: state.gcpCostReportData.gcpTableData,
    gcpChartData: state.gcpCostReportData.gcpChartData
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      entities: bindActionCreators(entityServices, dispatch),
      gcpData: bindActionCreators(gcpCostReportServices, dispatch)
    }
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GCPTableMap);
